<div class="content-container">
    <div class="flex-row-space-between content-header margin-10">
        <h2>{{'Device type filter' | translate}}</h2>
        <div
          class="circle-btn i-checkmark active"
          (click)="confirmed()">
        </div>
    </div>
    <div class="main-container">
        <div *ngIf="devicesExist('listOfLights')" class="device-type flex-column-center">
            <div class="grid-item-icon i-lightbulb-gray icon-margin" (click)="changeLights()" [ngClass]="showLights ? 'active' : 'inactive'"></div>
            <div class="text-align-center">Lights</div>
        </div>
        <div *ngIf="devicesExist('listOfBlinds')" class="device-type flex-column-center">
            <div class="grid-item-icon i-blinds-gray icon-margin" (click)="changeBlinds()" [ngClass]="showBlinds ? 'active' : 'inactive'"></div>
            <div class="text-align-center">Blinds</div>
        </div>
        <div *ngIf="devicesExist('listOfHvacs')" class="device-type flex-column-center">
            <div class="grid-item-icon i-hvac-gray icon-margin" (click)="changeHvac()" [ngClass]="showHvac ? 'active' : 'inactive'"></div>
            <div class="text-align-center">Hvac</div>
        </div>
        <div *ngIf="devicesExist('listOfSecurityEquipment')" class="device-type flex-column-center">
            <div class="grid-item-icon i-security-equipment-gray icon-margin" (click)="changeSecurity()" [ngClass]="showSecurity ? 'active' : 'inactive'"></div>
            <div class="text-align-center">Security</div>
        </div>
        <div *ngIf="devicesExist('listOfSafetyEquipment')" class="device-type flex-column-center">
            <div class="grid-item-icon i-safety-equipment-gray icon-margin" (click)="changeSafety()" [ngClass]="showSafety ? 'active' : 'inactive'"></div>
            <div class="text-align-center">Safety</div>
        </div>
        <div *ngIf="devicesExist('listOfGeneralEquipment')" class="device-type flex-column-center">
            <div class="grid-item-icon i-general-equipment-gray icon-margin" (click)="changeGeneral()" [ngClass]="showGeneral ? 'active' : 'inactive'"></div>
            <div class="text-align-center">General equipment</div>
        </div>
    </div>
    <!-- <div class="flex-row-center">
        <ion-button (click)="confirmed()">{{'Close' | translate}}</ion-button>
    </div> -->
</div>
