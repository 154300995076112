<div *ngIf="mode === 'live' && !data.showSpecialHTML && data.isDimmableLight">
  <div class="flex-column-center margin-top50">
    <div class="dimm-prop" (mousedown)="propertyClicked($event)"
                            (mouseup)="stopSendingValue($event)"
                            (touchstart)="propertyClicked($event)"
                            (touchend)="stopSendingValue($event)" [ngClass]="buttonClicked ? 'no-box-shadow' : ''">
      <div>
        {{equipmentProperty.name}}
      </div>
      <div class="i-dimm margin-top5 margin-bottom5"></div>
        <div>{{ 'short_press_activate' | translate }}</div>
        <div>{{ 'long_press_dimming' | translate }}</div>
    </div>
  </div>
</div>

<div *ngIf="mode === 'live' && !data.showSpecialHTML && !data.isDimmableLight"
  class="prop-btn width-full flex-row no-wrap"
  [ngClass]="{ 'display-none': loadingElement(parentDevice.id + equipmentProperty.id.toString()) }"
  (mousedown)="propertyClicked($event)"
  (mouseup)="stopSendingValue($event)"
  (touchstart)="propertyClicked($event)"
  (touchend)="stopSendingValue($event)">
  <div class="prop-btn-item-text">
    {{equipmentProperty.name}}
  </div>
  <label
  *ngIf="!loadingElement(parentDevice.id + equipmentProperty.id.toString())"
  class="switch margin-top20 margin-right20">
  <input
    type="checkbox"
    [checked]="+equipmentProperty.value === 1"/>
  <span class="slider round"></span>
</label>
<div *ngIf="loadingElement(parentDevice.id + equipmentProperty.id.toString())" class="lds-spinner-small margin-top15 margin-right20"><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div><div></div></div>
</div>

<div *ngIf="mode === 'action'" class="flex-column" >
  <!-- <div class="flex-column" >
    <div class="label margin-bottom20">{{ equipmentProperty.name }}</div>
  </div> -->
      <ion-radio-group [value]="action.parameters[1]">
          <ion-item lines="none">
            <ion-label>{{ data.nameForTrue | genComTranslate }}</ion-label>
            <ion-radio slot="start" class="color-radio margin-right40" value="1" (click)="handleActionChange($event)"></ion-radio>
          </ion-item>

          <ion-item lines="none">
            <ion-label>{{ data.nameForFalse | genComTranslate }}</ion-label>
            <ion-radio slot="start" class="color-radio margin-right40" value="0" (click)="handleActionChange($event)"></ion-radio>
          </ion-item>
        </ion-radio-group>
    </div>



<div *ngIf="mode === 'condition'" class="flex-column" >
   <!-- <div class="flex-column" >
      <div class="label margin-bottom20">{{ equipmentProperty.name }}</div>
    </div> -->
      <ion-radio-group [value]="condition.parameter2">
        <ion-item lines="none">
          <ion-label>{{ data.nameForTrue | genComTranslate}}</ion-label>
          <ion-radio slot="start" class="color-radio margin-right40" value="1" (click)="handleConditionChange($event)"></ion-radio>
        </ion-item>

        <ion-item lines="none">
          <ion-label>{{ data.nameForFalse | genComTranslate }}</ion-label>
          <ion-radio slot="start" class="color-radio margin-right40" value="0" (click)="handleConditionChange($event)"></ion-radio>
        </ion-item>
      </ion-radio-group>
</div>

<div *ngIf="mode === 'settings'" class="equip-prop-container" [ngClass]="data?.icon" (click)="toggleSettings()">
  <div>
    {{equipmentProperty.name}}
  </div>

  <div class="flex-row status-row" >
    <div>
      {{ 'current' | translate }}:
      <span *ngIf="+equipmentProperty.value === 0">{{data.nameForFalse | genComTranslate}}</span>
      <span *ngIf="+equipmentProperty.value === 1">{{data.nameForTrue | genComTranslate}}</span>
    </div>
    <div class="blue-text width-125 text-align-right" >
      {{ 'is_set' | translate }}:<span *ngIf="(settingValue$ | async) as settingValue">{{ (+settingValue === 0 ? data.nameForFalse : data.nameForTrue) | genComTranslate }}</span>
    </div>
</div>
</div>

