import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { RouterModule } from '@angular/router';
import { registerLocaleData } from '@angular/common';
import localeHr from '@angular/common/locales/hr';
import localeHrExtra from '@angular/common/locales/extra/hr';
import { ReactiveFormsModule, FormsModule } from '@angular/forms';

import { HeaderComponent } from './components/header/header.component';
import { FooterComponent } from './components/footer/footer.component';
import { SidebarComponent } from './components/sidebar/sidebar.component';
import { MessageBoxComponent } from './components/message-box/message-box.component';
import { ModalConfirmationComponent } from './components/modal-confirmation/modal-confirmation.component';
import { FilterComponent } from 'src/app/shared/components/filter/filter.component';
import { PaginatorComponent } from './components/paginator/paginator.component';
import { UsersFilterPipe } from './pipes/users-filter.pipe';
import { UsersWithEmailPipe } from './pipes/users-with-email.pipe';
import { TextSizeFilterPipe } from './pipes/text-size-filter.pipe';
import { TextSizeFilterWhitespacePipe } from './pipes/text-size-filter-whitespace.pipe';
import { AfterValueChangedDirective } from './directives/after-value-changed.directive';
import { OwlDateTimeModule, OwlNativeDateTimeModule, OWL_DATE_TIME_LOCALE } from '@danielmoncada/angular-datetime-picker';
import { ModalProjectComponent } from './components/modal-project/modal-project.component';
import { NotFoundComponent } from './components/equipment/not-found/not-found.component';
import { EquipmentBinaryComponent } from './components/equipment/equipment-binary/equipment-binary.component';


import { EquipmentNumberComponent } from './components/equipment/equipment-number/equipment-number.component';
import { EquipmentBinaryMutexComponent } from './components/equipment/equipment-binary-mutex/equipment-binary-mutex.component';
import { DelayComponent } from './components/equipment/delay/delay.component';
import { SetupDeviceActionComponent } from './components/setup-device-action/setup-device-action.component';
import { ChooseRoomsComponent } from '../shared/components/choose-rooms/choose-rooms.component';
import { ChooseDevicesComponent } from './components/choose-devices/choose-devices.component';
import { SetupDeviceConditionComponent } from './components/setup-device-condition/setup-device-condition.component';
import { DeviceFilterPipe } from './pipes/device-filter.pipe';
import { FilterDaliBlindsFromScene } from './pipes/filter-dali-blinds-from-scene.pipe';
import { UserSetupDeviceComponent } from './components/user-setup-device/user-setup-device.component';

import { AptRoomFilterPipe } from './pipes/apt-room-filter.pipe';
import { RoomActuatorDeviceFilterPipe } from './pipes/room-actuator-device-filter.pipe';

import { SceneMutexSortPipe } from './pipes/scene-mutex-sort.pipe';
import { SortByPosition } from './pipes/sort-by-position.pipe';
import { SceneAptFilterPipe } from './pipes/scene-apt-filter.pipe';
import { PublicScenesFilterPipe } from './pipes/public-scenes-filter.pipe';
import { AutomationAptFilterPipe } from './pipes/automation-apt-filter.pipe';
import { CheckRoomConnectionPipe } from './pipes/check-room-connection.pipe';
import { ModeAptFilterPipe } from './pipes/mode-apt-filter.pipe';
import { ModeActivationTypeFilterrPipe } from './pipes/mode-activation-type-filter.pipe';
import { StatusesComponent } from './components/statuses/statuses.component';
import { EquipmentNumberLiveComponent } from './components/equipment/equipment-number-live/equipment-number-live.component';
import { EquipmentNumberSettingsComponent } from './components/equipment/equipment-number-settings/equipment-number-settings.component';
import { EquipmentBinaryMutexLiveComponent } from './components/equipment/equipment-binary-mutex-live/equipment-binary-mutex-live.component';
import { IonicModule } from '@ionic/angular';
import { ModalPinComponent } from './components/modal-pin/modal-pin.component';

import { NgxChartsModule } from '@swimlane/ngx-charts';
import { DateTimeFromToComponent } from '../modules/logs/components/date-time-from-to/date-time-from-to.component';
import { FilterRoomsByFloorPipe } from './pipes/filter-rooms-by-floor.pipe';
import { SingleRoomComponent } from './components/single-room/single-room.component';
import { GenComTranslatePipe } from './pipes/gen-com-translate.pipe';

import { TranslateModule } from '@ngx-translate/core';
import { DaliDeviceComponent } from './components/dali/dali-device/dali-device.component';
import { RgbwLightComponent } from './components/dali/rgbw-light/rgbw-light.component';
import { DimmableDaliLightComponent } from './components/dali/dimmable-light/dimmable-dali-light.component';
import { DimmableLightGroupComponent } from './components/dali/dimmable-light-group/dimmable-light-group.component';
import { ColorPickerModule } from 'ngx-color-picker';

import { ModalDaliProjectComponent } from './components/modal-dali-project/modal-dali-project.component';
import { UserRoleFilterPipe } from './pipes/user-role-filter.pipe';
import { DaliBlindComponent } from './components/dali/dali-blind/dali-blind.component';
import { OutputModeComponent } from './components/equipment/output-mode/output-mode.component';
import { FilterDeviceByVisibleToUserPipe } from './pipes/filter-device-by-visible-to-user.pipe';
import { ReverseListIfBlindsPipe } from './pipes/reverse-list-if-blinds.pipe';
import { AdminPropsFilterPipe } from './pipes/admin-props-filter.pipe';
import { DeviceCategoryComponent } from './components/device-category/device-category.component';



registerLocaleData(localeHr, 'hr-HR', localeHrExtra);

@NgModule({
  declarations: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    MessageBoxComponent,
    ModalConfirmationComponent,
    FilterComponent,
    PaginatorComponent,
    UsersFilterPipe,
    TextSizeFilterPipe,
    AfterValueChangedDirective,
    TextSizeFilterWhitespacePipe,
    UsersWithEmailPipe,
    ModalProjectComponent,
    NotFoundComponent,
    EquipmentBinaryComponent,
    EquipmentNumberComponent,
    EquipmentBinaryMutexComponent,
    DelayComponent,
    SetupDeviceActionComponent,
    ChooseRoomsComponent,
    ChooseDevicesComponent,
    SetupDeviceConditionComponent,
    DeviceFilterPipe,
    UserSetupDeviceComponent,
    AptRoomFilterPipe,
    RoomActuatorDeviceFilterPipe,
    SceneMutexSortPipe,
    SceneAptFilterPipe,
    PublicScenesFilterPipe,
    AutomationAptFilterPipe,
    ModeAptFilterPipe,
    ModeActivationTypeFilterrPipe,
    StatusesComponent,
    SortByPosition,
    EquipmentNumberLiveComponent,
    EquipmentNumberSettingsComponent,
    EquipmentBinaryMutexLiveComponent,
    ModalPinComponent,
    DateTimeFromToComponent,
    FilterRoomsByFloorPipe,
    SingleRoomComponent,
    GenComTranslatePipe,
    DaliDeviceComponent,
    RgbwLightComponent,
    DimmableDaliLightComponent,
    DimmableLightGroupComponent,
    ModalDaliProjectComponent,
    UserRoleFilterPipe,
    DaliBlindComponent,
    OutputModeComponent,
    FilterDaliBlindsFromScene,
    FilterDeviceByVisibleToUserPipe,
    ReverseListIfBlindsPipe,
    DeviceCategoryComponent,
    CheckRoomConnectionPipe,
    AdminPropsFilterPipe
     ],
  imports: [
    CommonModule,
    RouterModule,
    ReactiveFormsModule,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    FormsModule,
    IonicModule,
    NgxChartsModule,
    TranslateModule.forChild(),
    ColorPickerModule
    ],
  exports: [
    HeaderComponent,
    FooterComponent,
    SidebarComponent,
    MessageBoxComponent,
    ModalConfirmationComponent,
    FilterComponent,
    PaginatorComponent,
    UsersFilterPipe,
    TextSizeFilterPipe,
    AfterValueChangedDirective,
    TextSizeFilterWhitespacePipe,
    UsersWithEmailPipe,
    ModalProjectComponent,
    DelayComponent,
    SetupDeviceActionComponent,
    ChooseRoomsComponent,
    ChooseDevicesComponent,
    SetupDeviceConditionComponent,
    UserSetupDeviceComponent,
    DeviceFilterPipe,
    AptRoomFilterPipe,
    RoomActuatorDeviceFilterPipe,
    SceneMutexSortPipe,
    SceneAptFilterPipe,
    PublicScenesFilterPipe,
    AutomationAptFilterPipe,
    OwlDateTimeModule,
    OwlNativeDateTimeModule,
    ModeAptFilterPipe,
    ModeActivationTypeFilterrPipe,
    SortByPosition,
    ModalPinComponent,
    IonicModule,
    DateTimeFromToComponent,
    FilterRoomsByFloorPipe,
    SingleRoomComponent,
    GenComTranslatePipe,
    DimmableDaliLightComponent,
    DimmableLightGroupComponent,
    RgbwLightComponent,
    UserRoleFilterPipe,
    FilterDaliBlindsFromScene,
    FilterDeviceByVisibleToUserPipe,
    DeviceCategoryComponent,
    ModalDaliProjectComponent,
    CheckRoomConnectionPipe,
    AdminPropsFilterPipe
    ],
  providers: [
    {provide: OWL_DATE_TIME_LOCALE, useValue: 'hr-HR'},
    GenComTranslatePipe,
    SceneAptFilterPipe,
    AptRoomFilterPipe,
    DeviceFilterPipe,
    FilterDeviceByVisibleToUserPipe
  ]
})
export class SharedModule { }
