import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';
import { HttpParams, HttpClient } from '@angular/common/http';
import { map } from 'rxjs/operators';
import { Card } from 'src/app/core/models/card/card.model';
import { API_BASE_URL } from 'src/environments/environment';


@Injectable({
  providedIn: 'root'
})
export class CardOnHolderService {

  cardOnHolder: boolean;
  cardOnHolderChanged = new Subject<boolean>();
  cardUid: string;

  constructor(private http: HttpClient) { }

  getCardOnHolder() {
    return this.cardOnHolder;
  }

  setCardOnHolder(cardOnHolder: boolean) {
    this.cardOnHolder = cardOnHolder;
    this.cardOnHolderChanged.next(this.cardOnHolder);
  }

  getCardUid() {
    return this.cardUid;
  
  }
  setCardUid(uid: string) {
    this.cardUid = uid;
  }

  // cardByUidExists(cardUid: string) {
  //   const params = new HttpParams().set('uid', cardUid);
  //   return this.http.get<Card>(API_BASE_URL + '/cards/byUId', { params }).pipe(
  //     map((card) => {
  //       if (card === null) {
  //         return false;
  //       } else {
  //         return true;
  //       }
  //     })
  //   );
  // }
}
