export class User {
    _id: string;
    firstName: string;
    lastName: string;
    fullName: string;
    email: string;
    password: string;
    roleLevel: number;
    apartmentIds: string[];
    accessControlIds?: string[];
    pin?: string;
    cards?: any[]

    constructor() {}

    public static isAdmin(u: User): boolean{
      return u.roleLevel === 1;
    }

    public static isOwner(u: User): boolean{
      return u.roleLevel === 2;
    }

    public static isGuest(u: User): boolean{
      return u.roleLevel === 3;
    }

    havePermission?(permissionId: number) {
        return true;

    }

    haveRole?(_id: number) {
        return true;
    }


}
