import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { Subscription } from 'rxjs';
import { DaliProject } from 'src/app/core/models/project/dali-project.model';
import { Device } from 'src/app/core/models/project/devices/device.model';
import { Room } from 'src/app/core/models/project/room.model';
import { User } from 'src/app/core/models/user/user.model';
import { UserService } from 'src/app/core/services/user.service';
import { DaliProjectService } from 'src/app/modules/project/services/dali-project.service';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { USE_DALI, USE_DEVICE_VISIBLE_TO_USER } from 'src/environments/environment';
import { DeviceFilterPipe } from '../../pipes/device-filter.pipe';
import { FilterDeviceByVisibleToUserPipe } from '../../pipes/filter-device-by-visible-to-user.pipe';
import { FastSceneAndAutoCreate } from '../../services/fastSceneAndAutoCreate.service';


@Component({
  selector: 'app-choose-devices',
  templateUrl: './choose-devices.component.html',
  styleUrls: ['./choose-devices.component.scss']
})
export class ChooseDevicesComponent implements OnInit, OnDestroy {
  @Input () chosenRooms: Room[];
  @Input () mode: 'action' | 'condition' | 'admin';
  @Output () deviceClicked: EventEmitter<Device>  = new EventEmitter();

  daliProject: DaliProject;
  useDali: boolean = USE_DALI;

  user: User;
  USE_DEVICE_VISIBLE_TO_USER = USE_DEVICE_VISIBLE_TO_USER;

  showBlinds: boolean;
  showLights: boolean;
  showHvac: boolean;
  showSecurity: boolean;
  showSafety: boolean;
  showGeneral: boolean;

  selectedDevices: Device[] = [];
  selectedDaliDevices: Device[] = [];


  showBlindsSubscription: Subscription;
  showLightsSubscription: Subscription;
  showHvacSubscription: Subscription;
  showSecuritySubscription: Subscription;
  showSafetySubscription: Subscription;
  showGeneralSubscription: Subscription;

  selectedDevicesSubscription: Subscription;
  selectedDaliDevicesSubscription: Subscription;

  constructor(
    private projectService: ProjectService,
    private userService: UserService,
    private daliProjectService: DaliProjectService,
    private fastSceneAndAutoCreate: FastSceneAndAutoCreate,
    private deviceFilterPipe: DeviceFilterPipe,
    private filterDeviceByVisibleToUserPipe: FilterDeviceByVisibleToUserPipe) { }

  ngOnInit(): void {
    this.checkIfUserIsAdmin();
    if (USE_DALI) {
      this.daliProject = this.daliProjectService.getProject();
    }
      this.showBlindsSubscription = this.fastSceneAndAutoCreate.getShowBlinds().subscribe( value => {
        this.showBlinds = value
      });

      this.showHvacSubscription =  this.fastSceneAndAutoCreate.getShowHvac().subscribe( value => {
        this.showHvac = value
      });

      this.showLightsSubscription = this.fastSceneAndAutoCreate.getShowLights().subscribe( value => {
        this.showLights = value
      });

      this.showGeneralSubscription = this.fastSceneAndAutoCreate.getShowGeneral().subscribe( value => {
        this.showGeneral = value
      });

      this.showSafetySubscription = this.fastSceneAndAutoCreate.getShowSafety().subscribe( value => {
        this.showSafety = value
      });

      this.showSecuritySubscription = this.fastSceneAndAutoCreate.getShowSecurity().subscribe( value => {
        this.showSecurity = value
      });

      this.selectedDevicesSubscription = this.fastSceneAndAutoCreate.getSelectedDevices().subscribe( value => {
        this.selectedDevices = value;
      })

      this.selectedDaliDevicesSubscription = this.fastSceneAndAutoCreate.getSelectedDaliDevices().subscribe( value => {
        this.selectedDaliDevices = value;
      })
}


  checkIfUserIsAdmin() {
    this.user = this.userService.getUser();
    if (User.isAdmin(this.user)){
      this.mode = 'admin';
    }
  }

  onDeviceClicked(clickedDevice: any) {
    this.deviceClicked.emit(clickedDevice);
  }

  getDaliLightsForRoom(roomId) {
    if (this.showLights) {
      return this.daliProjectService.getDaliLightsForRoom(roomId);
    } else {
      return []
    }
  }

  getDaliRgbLightsForRoom(roomId) {
    if (this.showLights) {
      return this.daliProjectService.getDaliRgbLightsForRoom(roomId);
    }else {
      return []
    }
  }

  getDaliLightsGroupForRoom(roomId) {
    if (this.showLights) {
      return this.daliProjectService.getDaliLightsGroupForRoom(roomId);
    }else {
      return []
    }
  }

  getDaliBlindsForRoom(roomId) {
    if (this.showBlinds) {
      return this.daliProjectService.getDaliBlindsForRoom(roomId);
    }else {
      return []
    }
  }

  getLightsForRoom(roomId) {
    if (this.showLights) {
      return this.projectService.getLightsForRoom(roomId);
    } else {
      return []
    }
  }

  getBlindsForRoom(roomId) {
    if (this.showBlinds) {
      return this.projectService.getBlindsForRoom(roomId);
    } else {
      return []
    }
  }

  getHvacForRoom(roomId) {
    if (this.showHvac) {
      return this.projectService.getHvacForRoom(roomId);
    } else {
      return []
    }

  }

  getSecurityEquipmentForRoom(roomId) {
    if (this.showSecurity) {
      return this.projectService.getSecurityEquipmentForRoom(roomId);
    } else {
      return []
    }
  }

  getSafetyEquipmentForRoom(roomId) {
    if (this.showSafety) {
      return this.projectService.getSafetyEquipmentForRoom(roomId);
    } else {
      return []
    }
  }

  getGeneralEquipmentForRoom(roomId) {
    if (this.showGeneral) {
      return this.projectService.getGeneralEquipmentForRoom(roomId);
    } else {
      return []
    }
  }

  checkIfUserSeeDali() {
    let deviceArray = [];
    this.chosenRooms.forEach( room => {
      let devices = this.deviceFilterPipe.transform(this.getDaliLightsForRoom(room.id), this.mode)
      let moreDevices = this.filterDeviceByVisibleToUserPipe.transform(devices, USE_DEVICE_VISIBLE_TO_USER, this.user)
      deviceArray = [...deviceArray, ...moreDevices]

      devices = this.deviceFilterPipe.transform(this.getDaliRgbLightsForRoom(room.id), this.mode)
      moreDevices = this.filterDeviceByVisibleToUserPipe.transform(devices, USE_DEVICE_VISIBLE_TO_USER, this.user)
      deviceArray = [...deviceArray, ...moreDevices]

      devices = this.deviceFilterPipe.transform(this.getDaliLightsGroupForRoom(room.id), this.mode)
      moreDevices = this.filterDeviceByVisibleToUserPipe.transform(devices, USE_DEVICE_VISIBLE_TO_USER, this.user)
      deviceArray = [...deviceArray, ...moreDevices]

      devices = this.deviceFilterPipe.transform(this.getDaliBlindsForRoom(room.id), this.mode)
      moreDevices = this.filterDeviceByVisibleToUserPipe.transform(devices, USE_DEVICE_VISIBLE_TO_USER, this.user)
      deviceArray = [...deviceArray, ...moreDevices]
    })
    if(deviceArray.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  checkFilters(roomId: string) {
    let deviceArray = [];
    let showFiltersInUse = false

    if (this.canAnyDeviceBeSelected(roomId)) {
      deviceArray = [...this.getLightsForRoom(roomId), ...this.getBlindsForRoom(roomId), ...this.getHvacForRoom(roomId), ...this.getSecurityEquipmentForRoom(roomId),
                    ...this.getSafetyEquipmentForRoom(roomId), ...this.getGeneralEquipmentForRoom(roomId)]
      if (deviceArray.length === 0) {
        showFiltersInUse = true;
      }
    }

    return showFiltersInUse;
  }

  canAnyDeviceBeSelected(roomId: string) {
    let lights = this.deviceFilterPipe.transform(this.projectService.getLightsForRoom(roomId), this.mode)
    let blinds = this.deviceFilterPipe.transform(this.projectService.getBlindsForRoom(roomId),this.mode)
    let hvac = this.deviceFilterPipe.transform(this.projectService.getHvacForRoom(roomId),this.mode)
    let security = this.deviceFilterPipe.transform(this.projectService.getSecurityEquipmentForRoom(roomId),this.mode)
    let safety = this.deviceFilterPipe.transform(this.projectService.getSafetyEquipmentForRoom(roomId),this.mode)
    let general = this.deviceFilterPipe.transform(this.projectService.getBlindsForRoom(roomId),this.mode)
    let daliLight = this.deviceFilterPipe.transform(this.daliProjectService.getDaliLightsForRoom(roomId), this.mode)
    let dalirgbLight = this.deviceFilterPipe.transform(this.daliProjectService.getDaliRgbLightsForRoom(roomId), this.mode)
    let dalirgbGroupLights = this.deviceFilterPipe.transform(this.daliProjectService.getDaliLightsGroupForRoom(roomId), this.mode)
    let daliBlinds = this.deviceFilterPipe.transform(this.daliProjectService.getDaliBlindsForRoom(roomId), this.mode)

    let devices = [...lights, ...blinds, ...hvac, ...security, ...safety, ...general, ...daliLight, ...dalirgbLight, ...dalirgbGroupLights, ...daliBlinds]
    if (devices.length > 0) {
      return true;
    } else {
      return false;
    }
  }

  deviceInAutomatization(device: Device) {
    const found = this.selectedDevices.find(dev => dev.id === device.id)
    if (found) {
      return true;
    } else {
      return false;
    }
  }

  deviceInDaliAutomatization(device: Device) {
    const found = this.selectedDaliDevices.find(dev => dev.id === device.id)
    if (found) {
      return true;
    } else {
      return false;
    }
  }


  ngOnDestroy(){
    if (this.showBlindsSubscription) {
      this.showBlindsSubscription.unsubscribe()
    }
    if (this.showLightsSubscription) {
      this.showLightsSubscription.unsubscribe()
    }
    if (this.showHvacSubscription) {
      this.showHvacSubscription.unsubscribe()
    }
    if (this.showSecuritySubscription) {
      this.showSecuritySubscription.unsubscribe()
    }
    if (this.showSafetySubscription) {
      this.showSafetySubscription.unsubscribe()
    }
    if (this.showGeneralSubscription) {
      this.showGeneralSubscription.unsubscribe()
    }
    if (this.selectedDevicesSubscription) {
      this.selectedDevicesSubscription.unsubscribe()
    }
    if (this.selectedDaliDevicesSubscription) {
      this.selectedDaliDevicesSubscription.unsubscribe()
    }
  }

}
