import { Component, OnInit, EventEmitter, Output, Input } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Action } from 'src/app/core/models/automation/action.model';


@Component({
  selector: 'app-delay',
  templateUrl: './delay.component.html',
  styleUrls: ['./delay.component.scss']
})
export class DelayComponent implements OnInit {
  delayForm: UntypedFormControl;
  @Input() delayInput: Action;
  @Output() delay$ = new EventEmitter();
  submitted = false;

  constructor() { }

  ngOnInit(): void {
    if (this.delayInput) {
      this.delayForm = new UntypedFormControl(
        this.delayInput.parameters[0],
        [Validators.required, Validators.min(1)]);
    } else {
      this.delayForm = new UntypedFormControl(
        null,
        [Validators.required, Validators.min(1)]);
    }
  }

  createDelay(delayInSeconds: number) {
    const delay: Action = new Action();
    delay.id = `tempDelayId1`; // = this.automationThenId + '|' + this.selectedAction.id; aa0001|ca0001",
    delay.name = `tempDelayName1`;
    delay.command = 'GeneralCustomActions.DelaySeconds';
    delay.description = 'Delay action with delay time supplied in seconds as the first parameter in the array';
    delay.parameters = [delayInSeconds];
    delay.builtIn = false;
    return delay;
  }

  onCancel() {
    this.delay$.emit();
  }

  onApply() {
    this.submitted = true;
    if (this.delayForm.valid) {
      const newDelay: Action = this.createDelay(this.delayForm.value);
      this.delay$.emit(newDelay);
    }
  }
}
