import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Automation } from '../models/automation/automation.model';
import { Mode } from '../models/modes/mode.model';
import { DaliProject } from '../models/project/dali-project.model';
import { Project } from '../models/project/project.model';
import { Scene } from '../models/scenes/scene.model';

@Injectable({
  providedIn: 'root'
})
export class DemoService {

    private readonly DEMO_PROJECT: string = 'assets/demo-mode/demo-project.json';
    private readonly DEMO_SCENES: string = 'assets/demo-mode/demo-scenes.json';
    private readonly DEMO_MODES: string = 'assets/demo-mode/demo-modes.json';
    private readonly DEMO_AUTOMATIONS: string = 'assets/demo-mode/demo-automations.json';
    private readonly DEMO_DALI: string = 'assets/demo-mode/demo-dali-proj.json';

    private demo = new BehaviorSubject<boolean>(false);
    automations: Automation[] = undefined;
    modes: Mode[] = undefined;
    project: Project;

    constructor(private http: HttpClient) { }

    getDemoProject() {
      return this.http.get<Project>(this.DEMO_PROJECT);
    }

    getDemoDaliProject() {
      return this.http.get<DaliProject>(this.DEMO_DALI);
    }

    getDemoModes() {
      return this.http.get<Mode[]>(this.DEMO_MODES);
    }

    getDemoScenes() {
      return this.http.get<Scene[]>(this.DEMO_SCENES);
    }

    getDemoAutomations() {
      return this.http.get<Automation[]>(this.DEMO_AUTOMATIONS);
    }

    setDemo(value: boolean){
      this.demo.next(value);
    }

    isDemo() {
      return this.demo.getValue();
    }

    getDemo$() {
      return this.demo.asObservable();
    }

    setAutomationsForDemo(automations: Automation[]) {
      if (this.automations === undefined) {
        this.automations = automations;
      }
    }

    getAutomationsForDemo() {
      return this.automations;
    }


    handleAutomationActiveStatus(id: string, value: string) {
      const target = this.automations.find( element => element._id === id);
      if (+value === 1) {
        target.isActive = true;
      } else {
        target.isActive = false;
      }
    }

    setModesForDemo(modes: Mode[]) {
      if (this.modes === undefined) {
        this.modes = modes;
      }
    }

    getModesForDemo() {
      return this.modes;
    }

    handleModeActiveStatus(id: string, value: string) {
      const target = this.modes.find( element => element._id === id);
      if (+value === 1) {
        target.isActive = true;
      } else {
        target.isActive = false;
      }
    }

}
