import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sortByPosition'
})
export class SortByPosition implements PipeTransform {
  transform(allElements: any[]): any[] {
    if (!allElements || allElements.length === 0) {
      return [];
    }

    return allElements.sort((a, b) => a.position - b.position);
  }
}
