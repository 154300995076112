import { Injectable } from '@angular/core';
import { BehaviorSubject, Subject } from 'rxjs';
import { Device } from 'src/app/core/models/project/devices/device.model';
import { Room } from 'src/app/core/models/project/room.model';



@Injectable({
  providedIn: 'root'
})
export class FastSceneAndAutoCreate {

  scrollwatch$ = new Subject<boolean>();
  saveScrollNow$ = new BehaviorSubject<boolean>(false);
  selectedRooms = new BehaviorSubject<Room[]>([]);
  showBlinds = new BehaviorSubject<boolean>(true);
  showLights = new BehaviorSubject<boolean>(true);
  showHvac = new BehaviorSubject<boolean>(true);
  showSecurity = new BehaviorSubject<boolean>(true);
  showSafety = new BehaviorSubject<boolean>(true);
  showGeneral = new BehaviorSubject<boolean>(true);

  selectedDevices = new BehaviorSubject<Device[]>([]);
  selectedDaliDevices = new BehaviorSubject<Device[]>([]);

 

  constructor() { }


  isAnyFilterInUse() {
    if (!this.showBlinds.value || !this.showLights.value || !this.showHvac.value || !this.showSecurity.value || !this.showSafety.value || !this.showGeneral.value) {
      return true
    } else {
      return false;
    }
  }
  addToSelectedDevices(device: Device) {
    if (device !== undefined) {
      this.selectedDevices.getValue().push(device) 
    }
  }

  addToDaliSelectedDevices(device: Device) {
    if (device !== undefined) {
      this.selectedDaliDevices.getValue().push(device) 
    }
  }

  removeDevice(device: Device) {
    let newArray = this.selectedDevices.getValue()
    const index = newArray.findIndex( element => (element.id === device.id))
    if (index !== -1) {
      newArray.splice(index, 1)
      this.selectedDevices.next(newArray)
    }
  }

  removeDaliDevice(device: Device) {
    let newArray = this.selectedDaliDevices.getValue()
    const index = newArray.findIndex( element => (element.id === device.id))
    if (index !== -1) {
      newArray.splice(index, 1)
      this.selectedDaliDevices.next(newArray)
    }
  }

  resetSelectedDevices() {
    this.selectedDevices.next([])
    this.selectedDaliDevices.next([])
  }

  getSelectedDevices() {
    return this.selectedDevices.asObservable()
  }

  getSelectedDaliDevices() {
    return this.selectedDaliDevices.asObservable()
  }


  getSelectedRooms() {
    return this.selectedRooms.asObservable();
  }

  addRoom(room: Room) {
    this.selectedRooms.getValue().push(room);
  }

  removeRoom(room: Room) {
  let newArray = this.selectedRooms.getValue()
  newArray = newArray.filter( element => !(element.apartmentId === room.apartmentId && element.id === room.id))
  this.selectedRooms.next(newArray)
  }

  getShowBlinds() {
    return this.showBlinds.asObservable();
  }

  selectAllRooms(rooms: Room[]) {
    this.selectedRooms.next(rooms)
  }

  clearRooms() {
    this.selectedRooms.next([])
  }

  getShowHvac() {
    return this.showHvac.asObservable();
  }

  getShowLights() {
    return this.showLights.asObservable()
  }

  getShowGeneral() {
    return this.showGeneral.asObservable()
  }

  getShowSecurity() {
    return this.showSecurity.asObservable()
  }

  getShowSafety() {
    return this.showSafety.asObservable()
  }

  changeBlinds(value) {
    this.showBlinds.next(value);
  }

  changeHvac(value) {
    this.showHvac.next(value);
  }

  changeLights(value) {
    this.showLights.next(value);
  }

  changeSecurity(value) {
    this.showSecurity.next(value);
  }

  changeGeneral(value) {
    this.showGeneral.next(value);
  }

  changeSafety(value) {
    this.showSafety.next(value);
  }


}
