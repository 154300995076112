import { Pipe, PipeTransform } from '@angular/core';
import { Room } from 'src/app/core/models/project/room.model';


@Pipe({
  name: 'checkRoomConnection',
})
export class CheckRoomConnectionPipe implements PipeTransform {

  transform(room: Room, commErrorRooms: Array<any>): boolean {
    let check = false;
    if (room) {
        return commErrorRooms.some( roomTopic => {
          const splitID = roomTopic[0].split('.');
          const roomID = splitID[0] + '.' + splitID[1] + '.' + splitID[2];
          if (room.id === roomID && roomTopic[1] !== 0) {
            check = true;
          }
          return check;
        });
      }
    return check;
  }
}

