import { Component, OnInit, OnDestroy,  Input } from '@angular/core';
import { Subscription } from 'rxjs';
import { Project } from 'src/app/core/models/project/project.model';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { ApiProjectService } from 'src/app/modules/project/services/http/api-project.service';
import { Router } from '@angular/router';
import { Device } from 'src/app/core/models/project/devices/device.model';
import { EquipmentComponentGenService } from 'src/app/shared/services/equipment-component-gen.service';
import { EquipmentPropertyTypesService } from 'src/app/shared/services/equipment-property-types.service';
import { Property } from 'src/app/core/models/project/property.model';
import { Room } from 'src/app/core/models/project/room.model';
import { ApiRoomsService } from 'src/app/modules/rooms/services/http/api-rooms.service';
import { PinService } from 'src/app/shared/services/pin.service';
import { ModalController, Platform } from '@ionic/angular';
import { ModalPinComponent } from 'src/app/shared/components/modal-pin/modal-pin.component';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { User } from 'src/app/core/models/user/user.model';
import { UserService } from 'src/app/core/services/user.service';
import { DemoService } from 'src/app/core/services/demo.service';
import { Hvac } from 'src/app/core/models/project/devices/hvac.model';
import { RoomViewService } from 'src/app/modules/rooms/services/room-view.service';
import { DaliProjectService } from 'src/app/modules/project/services/dali-project.service';
import { DaliProject } from 'src/app/core/models/project/dali-project.model';
import { ApiDaliProjectService } from 'src/app/modules/project/services/http/api-dali-project.service';
import { Light } from 'src/app/core/models/project/devices/light.model';
import { RgbLight } from 'src/app/core/models/project/devices/rgbLight.model';
import { LightsGroup } from 'src/app/core/models/project/groups/lights-group';
import { DaliGroup } from 'src/app/core/models/project/devices/daliGroup.model';
import { USE_DALI, USE_DEVICE_VISIBLE_TO_USER } from 'src/environments/environment';
import { Blind } from 'src/app/core/models/project/devices/blind.model';
import { OfflineControllersService } from '../../services/offline-controllers.service';

export interface PositionedElement {
  position: number;
  id: string;
}

@Component({
  selector: 'app-single-room',
  templateUrl: './single-room.component.html',
  styleUrls: ['./single-room.component.scss']
})
export class SingleRoomComponent implements OnInit, OnDestroy {

  @Input() activeRoomId: string;
  @Input() roomViewType: 'allDevices' | 'rooms' | 'activeDevices';
  commErrorRooms: Array<any> = [];
  project: Project = this.projectService.getProject();
  daliProject: DaliProject;

  projectSubscription: Subscription;
  daliProjectSubscription: Subscription;

  room: Room;

  timer = 0;
  requestSent = false;
  showPosition = false;
  maxOrder = 0;
  minOrder = 999999;

  loadingSubscription: Subscription;
  loadingElements = [];

  user: User;
  demo: boolean;
  backButtonSubscription: Subscription;
  offlineControllerSubscription: Subscription;

  USE_DEVICE_VISIBLE_TO_USER = USE_DEVICE_VISIBLE_TO_USER;

  constructor(private projectService: ProjectService,
              private apiProjectService: ApiProjectService,
              private equipmentPropertyTypesService: EquipmentPropertyTypesService,
              private router: Router,
              private equipmentComponentGen: EquipmentComponentGenService,
              private apiRoomService: ApiRoomsService,
              private pinService: PinService,
              private modalController: ModalController,
              private loadingService: LoadingService,
              private userService: UserService,
              private demoService: DemoService,
              private platform: Platform,
              private roomViewService: RoomViewService,
              private daliProjectService: DaliProjectService,
              private apiDaliProjectService: ApiDaliProjectService,
              private offlineControllersService: OfflineControllersService) { }

  ngOnInit() {
    this.backButtonSubscription = this.platform.backButton.subscribeWithPriority(10, () => {
      this.goBack();
    });
    this.user = this.userService.getUser();
    this.getOfflineControllers();
    this.demo = this.demoService.isDemo();
    if (USE_DALI) {
      this.getProjects();
    } else {
      this.getProject();
    }
    this.loadingSub();
  }

  getOfflineControllers() {
    this.commErrorRooms = this.offlineControllersService.getOfflineControllers();
    this.offlineControllerSubscription = this.offlineControllersService.commChanged.subscribe( () => {
      this.commErrorRooms = this.offlineControllersService.getOfflineControllers();
    })
  }

  projectAvailable() {
    return USE_DALI ? this.project && this.daliProject : this.project;
  }

  getProject() {
    this.project = this.projectService.getProject();
    this.checkPositioning();
    this.projectSubscription = this.projectService.projectChanged.subscribe(() => {
      this.project = this.projectService.getProject();
      this.room = this.project.listOfRooms.find( room => room.id === this.activeRoomId);
      if (!this.demoService.isDemo()) {
        this.checkPositioning();
      }

      if (this.roomViewType === 'rooms') {
        const devices = this.getAllDevicesInRoom();
        if (devices.length === 1) {
          if (this.deviceHasOnlyStatusProps(devices[0])) {
            return;
            }
          this.router.navigate([`/rooms/${this.activeRoomId}/${devices[0].id}`]);
        }
      }
    });
    this.projectService.projectChanged.next(this.project);
  }


  getProjects(){
    this.project = this.projectService.getProject();
    this.daliProject = this.daliProjectService.getProject();
    this.projectSubscription = this.projectService.projectChanged.subscribe(() => {
      this.project = this.projectService.getProject();
      this.room = this.project.listOfRooms.find( room => room.id === this.activeRoomId);
      if (!this.demoService.isDemo() && this.daliProject) {
        this.checkPositioning();
      }

      // const devicesAndDaliLights = this.getAllPositionedElementsInRoom();
      // if (devicesAndDaliLights.length === 1) {
      //   if (this.deviceHasOnlyStatusProps(devicesAndDaliLights[0])) {
      //     return;
      //     }
      //   this.router.navigate([`/rooms/${this.activeRoomId}/${devicesAndDaliLights[0].id}`]);
      // }
      if (this.daliProject && this.roomViewType === 'rooms') {
        const devices = this.getAllDevicesInRoom();
        const daliElements = this.getAllDaliElementsInRoom();
        if (devices.length === 1 && daliElements.length === 0) {
          if (this.deviceHasOnlyStatusProps(devices[0])) {
            return;
            }
          this.router.navigate([`/rooms/${this.activeRoomId}/${devices[0].id}`]);
        }
      }
    });
    this.daliProjectSubscription = this.daliProjectService.daliProjectChanged.subscribe(() => {
      this.daliProject = this.daliProjectService.getProject();
      if (!this.demoService.isDemo() && this.project) {
        this.checkPositioning();
      }
    });
    this.projectService.projectChanged.next(this.project);
  }


  loadingSub() {
    this.loadingElements = this.loadingService.getLoadingElements();
    this.loadingSubscription = this.loadingService.loadingChanged.subscribe( response => {
      this.loadingElements = this.loadingService.getLoadingElements();
    });
  }

  loadingElement(id: string) {
    return this.loadingElements.includes(id);
  }


  getPrimaryProperty(device: Device): Property {
    const primaryProperty = device.equipmentProperties.find((prop: Property) => {
      const propData = this.equipmentComponentGen.getTextualRepresentationOfValue(prop);
      return propData?.primaryProperty === true;
    });
    return primaryProperty;
  }

  propIsAlone(device: Device) {
    if (device.equipmentProperties.length === 1) {
      return device.equipmentProperties[0];
    } else {
      return undefined;
    }
  }

  getCurrentTemp(hvac: Hvac): number {
    const currentTempProp: Property = hvac.equipmentProperties.find((prop: Property) => {
      return prop.type >= 6200 && prop.type <= 6249;
    });
    return currentTempProp.value;
  }

  getTargetTemp(hvac: Hvac): number {
    const targetTempProp: Property = hvac.equipmentProperties.find((prop: Property) => {
      return prop.type >= 6250 && prop.type <= 6299;
    });
    return targetTempProp.value;
  }

  isHvacActive(hvac: Hvac) {
    return hvac.equipmentProperties.some((prop: Property) => {
      return Property.isHvacControl(prop) && Number(prop.value) === 1;
    });
  }

  getAllActiveDevices(): Device[] { // function works only when roomViewType === activeDevices
    let allActiveDevices: any[] = [];
    const activeLighsInRoom = this.getLightsForRoom(this.activeRoomId);
    const activeHvacsInRoom = this.getHvacForRoom(this.activeRoomId);
    if (USE_DALI) {
      const activeDaliLightsInRoom = this.getDaliLightsForRoom(this.activeRoomId);
      const activeDaliRgbLightsInRoom = this.getRgbLightsForRoom(this.activeRoomId);
      allActiveDevices = [...activeLighsInRoom, ...activeHvacsInRoom, ...activeDaliLightsInRoom, ...activeDaliRgbLightsInRoom];
    }
    else {
      allActiveDevices = [...activeLighsInRoom, ...activeHvacsInRoom];
    }
    return allActiveDevices;
  }

  getLightsForRoom(roomId: string): Device[] {
    if (this.project) {
      let lightsInRoom: Device[] = this.projectService.getLightsForRoom(roomId);
      lightsInRoom = this.filterDeviceWithoutAdminProp(lightsInRoom);
      lightsInRoom.sort((a: Device, b: Device) => {
        if (this.isMasterLight(a)
           ) {
          return -1;
        } else {
          return 1;
        }
      });
      if (this.roomViewType === 'activeDevices') {
        lightsInRoom = lightsInRoom.filter((light: Device) => {
          const primaryProperty: Property = this.getPrimaryProperty(light);
          return (Number (primaryProperty?.value) === 1);
        });
      }
      return lightsInRoom;
    }
  }

  isMasterLight(light: Device) {
    return light.equipmentProperties[0].inComm.address.split('/')[light.equipmentProperties[0].inComm.address.split('/').length - 1] === 'master'
    || light.equipmentProperties[0].outComm.address.split('/')[light.equipmentProperties[0].inComm.address.split('/').length - 1] === 'master';
  }
  getBlindsForRoom(roomId: string): Device[] {
    if (this.roomViewType === 'activeDevices') {
      return [];
    }

    if (this.project) {
      const devices = this.projectService.getBlindsForRoom(roomId);
      return this.filterDeviceWithoutAdminProp(devices);
    }
  }

  getHvacForRoom(roomId: string): Device[] {
    if (this.project) {
      let  hvacsInRoom: Device[] = this.projectService.getHvacForRoom(roomId);
      hvacsInRoom =  this.filterDeviceWithoutAdminProp(hvacsInRoom);
      if (this.roomViewType === 'activeDevices') {
        hvacsInRoom = hvacsInRoom.filter((hvac: Hvac) => {
          return this.isHvacActive(hvac);
        });
      }
      return hvacsInRoom;
    }
  }

  getSecurityEquipmentForRoom(roomId: string): Device[] {
    if (this.roomViewType === 'activeDevices') {
      return [];
    }
    if (this.project) {
      const devices = this.projectService.getSecurityEquipmentForRoom(roomId);
      return this.filterDeviceWithoutAdminProp(devices);
    }
  }

  getAlarmedSecurityProperties(securtiyEquipment: Device): Property[] {
    const alarmedSecurityProperties = securtiyEquipment.equipmentProperties.filter((prop) => {
      if (Property.isRoomArmed(prop)) {
        return false;
      }
      if (!this.equipmentPropertyTypesService.getEquipmentPropertyTypeForStatus(prop.type)) {
        return false;
      }
      return Property.propertyIsActive(prop);
    });
    if (!alarmedSecurityProperties) {
      return [];
    }
    return alarmedSecurityProperties;
  }

  getSafetyEquipmentForRoom(roomId: string): Device[] {
    if (this.roomViewType === 'activeDevices') {
      return [];
    }
    if (this.project) {
      const devices = this.projectService.getSafetyEquipmentForRoom(roomId);
      return this.filterDeviceWithoutAdminProp(devices);
    }
  }

  getAlarmedSafetyProperties(safetyEquipment: Device): Property[] {
    const alarmedSafetyProperties = safetyEquipment.equipmentProperties.filter((prop) => {
      if (!this.equipmentPropertyTypesService.getEquipmentPropertyTypeForStatus(prop.type)) {
        return false;
      }
      return Property.propertyIsActive(prop);
    });
    if (!alarmedSafetyProperties) {
      return [];
    }
    return alarmedSafetyProperties;
  }

  getGeneralEquipmentForRoom(roomId: string): Device[] {
    if (this.roomViewType === 'activeDevices') {
      return [];
    }
    if (this.project) {
      let devices = this.projectService.getGeneralEquipmentForRoom(roomId);
      // remove devices with single outputmode property;
      devices = devices.filter((device: Device) => {
        return !(device.equipmentProperties.length === 1 && Property.isOutputMode(device.equipmentProperties[0]));
      });

      return this.filterDeviceWithoutAdminProp(devices);
    }
  }

  getDaliLightsForRoom(roomId: string) {
    if (this.daliProject) {
      // let daliLights = this.daliProject?.listOfLights;
      let daliLightsInRoom = this.daliProject?.listOfLights.filter((el: Light) => {
          return el.listOfLocationIds.includes(roomId);
        });
      if (this.roomViewType === 'activeDevices') {
        daliLightsInRoom = daliLightsInRoom?.filter((light: Light) => {
           return Number(light.equipmentProperties[0].value) > 0;
         });
      }
      return daliLightsInRoom;
    } else {
      return [];
    }
  }

  getDaliBlindsForRoom(roomId: string) {
    if (this.roomViewType === 'activeDevices') {
      return [];
    }
    if (this.daliProject) {
      // let daliLights = this.daliProject?.listOfLights;
      const daliBlindsInRoom = this.daliProject?.listOfBlinds.filter((el: Blind) => {
          return el.listOfLocationIds.includes(roomId);
        });
      return daliBlindsInRoom;
    } else {
      return [];
    }
  }


  getRgbLightsForRoom(roomId: string) {
    if (this.daliProject) {
      // let rgbLights = this.daliProject?.listOfRgbs;
      let rgbLightsInRoom = this.daliProject?.listOfRgbs?.filter((el: RgbLight) => {
        return el.listOfLocationIds.includes(roomId);
      });
      if (this.roomViewType === 'activeDevices') {
        rgbLightsInRoom = rgbLightsInRoom?.filter((rgbLight: RgbLight) => {
           return !this.daliRgbIsOff(rgbLight);
         });
      }

      return rgbLightsInRoom;
    } else {
      return [];
    }
  }

  getDaliGroupsForRoom(roomId: string) {
    if (this.roomViewType === 'activeDevices') {
      return [];
    }
    return this.daliProject?.listOfGroups.filter((el: DaliGroup) => {
      return el.listOfLocationIds.includes(roomId);
    });
    // return this.daliProject?.listOfGroups;
  }




  filterDeviceWithoutAdminProp(devices: Device[]): Device[] {
    return devices.filter( device => {
      let found = false;
      device.equipmentProperties.forEach( prop => {
        if (this.equipmentPropertyTypesService.getEquipmentPropertyType(prop.type) || this.equipmentPropertyTypesService.getEquipmentPropertyTypeForStatus(prop.type)) {
          found = true;
        }
      });
      return found;
    });
  }

  getDataForProperty(equipmentProperty: Property) {
    // if prop is window we are using 2 css icons and we need to check whitch one

    return this.equipmentComponentGen.getTextualRepresentationOfValue(equipmentProperty);
  }

  changePrimaryPropertyValue(device: Device, property: Property, event: MouseEvent) {
    event.stopPropagation();
    if (this.demoService.isDemo()) {
      this.loadingService.addToLoading(device.id + property.id.toString());
      if (Number(property.value) === 0) {
        this.projectService.changePropertyValueForDemo(device.id, property.type, 1);
      } else if (Number(property.value) === 1) {
        this.projectService.changePropertyValueForDemo(device.id, property.type, 0);
      }
      this.loadingService.removeFromLoading(device.id + property.id.toString());
    } else {
      if (property.security.requirePinToActivate && !this.pinService.pinIsSet()) { // TU PROMJENI !
        this.openPinModal(device, property);
      } else if (property.security.requirePinToActivate) {
        this.loadingService.addToLoading(device.id + property.id.toString());
        if (Number(property.value) === 0) {
          this.apiProjectService.changeProperty(String(device.id), property.type, '1', this.pinService.getPin()).subscribe( () => {
          });
        } else if (Number(property.value) === 1) {
          this.apiProjectService.changeProperty(String(device.id), property.type, '0', this.pinService.getPin()).subscribe( () => {
          });
        }
      }  else {
        this.loadingService.addToLoading(device.id + property.id.toString());
        if (this.isMasterLight(device)) {
          this.loadingService.removeFromLoading(device.id + property.id.toString());
        }
        if (Number(property.value) === 0) {
          this.apiProjectService.changeProperty(String(device.id), property.type, '1').subscribe(() => {
          });
        } else if (Number(property.value) === 1) {
          this.apiProjectService.changeProperty(String(device.id), property.type, '0').subscribe( () => {
          });
        }
      }
    }
  }

  async openPinModal(device: Device, property: Property) {
    const modal = await this.modalController.create({
      component: ModalPinComponent,
      cssClass: 'pin-modal-container',
      backdropDismiss: true,
      showBackdrop: true
    });
    modal.onDidDismiss()
    .then((resp) => {
      if (resp?.data?.choice === true) {
        this.pinService.setPin(resp?.data?.pin).subscribe( response => {
          if (response === true) {
            this.loadingService.addToLoading(device.id + property.id.toString());
            if (Number(property.value) === 0) {
              this.apiProjectService.changeProperty(String(device.id), property.type, '1', this.pinService.getPin()).subscribe( () => {
              });
            } else if (Number(property.value) === 1) {
              this.apiProjectService.changeProperty(String(device.id), property.type, '0', this.pinService.getPin()).subscribe( () => {
              });
            }
          }
        });
      }
  });
    return await modal.present();
  }


  onClickDeviceCard(device: Device) {
    if (this.showPosition) {
      return;
    } else {
      if (this.deviceHasOnlyStatusProps(device)) {
        return;
        }
      if (this.roomViewType === 'activeDevices') {
        this.roomViewService.setDeviceRouteOrigin('activeDevices');
      } else if (this.roomViewType === 'allDevices') {
        this.roomViewService.setDeviceRouteOrigin('allDevices');
      } else if (this.roomViewType === 'rooms') {
        this.roomViewService.setDeviceRouteOrigin('rooms');
      }
      this.router.navigate([`/rooms/${this.activeRoomId}/${device.id}`]);
    }
  }

  deviceHasOnlyStatusProps(device: Device): boolean {
    if (device.equipmentProperties.length === 1
      && this.equipmentPropertyTypesService.getEquipmentPropertyTypeForStatus(device.equipmentProperties[0].type)){
        return true;
    } else {
      return false;
    }
  }

  getAllPositionedElementsInRoom(): PositionedElement[] {
    /* let allDevicesInRoom = [];
    allDevicesInRoom.push(...this.projectService.getLightsForRoom(this.activeRoomId));
    allDevicesInRoom.push(...this.projectService.getHvacForRoom(this.activeRoomId));
    allDevicesInRoom.push(...this.projectService.getBlindsForRoom(this.activeRoomId));
    allDevicesInRoom.push(...this.projectService.getGeneralEquipmentForRoom(this.activeRoomId));
    allDevicesInRoom.push(...this.projectService.getSafetyEquipmentForRoom(this.activeRoomId));
    allDevicesInRoom.push(...this.projectService.getSecurityEquipmentForRoom(this.activeRoomId));
    allDevicesInRoom.push(...this.projectService.getSecurityEquipmentForRoom(this.activeRoomId));
    allDevicesInRoom = this.filterDeviceWithoutAdminProp(allDevicesInRoom); */
    const allDevicesInRoom = this.getAllDevicesInRoom();

    if (USE_DALI) {
      const allDaliElements = this.getAllDaliElementsInRoom();
      const allPositionedElementsInRoom: PositionedElement[] = [
        ...allDevicesInRoom,
        ...allDaliElements
      ];
      return allPositionedElementsInRoom;
    } else {
      return allDevicesInRoom;
    }
  }

  getAllDaliElementsInRoom() {
    const allDaliElementsInRoom = [];
    allDaliElementsInRoom.push(...this.daliProjectService.getDaliLightsForRoom(this.activeRoomId));
    allDaliElementsInRoom.push(...this.daliProjectService.getDaliRgbLightsForRoom(this.activeRoomId));
    allDaliElementsInRoom.push(...this.daliProjectService.getDaliLightsGroupForRoom(this.activeRoomId));
    allDaliElementsInRoom.push(...this.daliProjectService.getDaliBlindsForRoom(this.activeRoomId));

    return allDaliElementsInRoom;
    // return [...this.daliProjectService.getDaliLightsForRoom(this.activeRoomId),
    //   ...this.daliProjectService.getDaliRgbLightsForRoom(this.activeRoomId),
    //   ...this.daliProjectService.getDaliLightsGroupForRoom(this.activeRoomId)];
  }

  getAllDevicesInRoom() {
    let allDevicesInRoom: Device[] = [];
    allDevicesInRoom.push(...this.projectService.getLightsForRoom(this.activeRoomId));
    allDevicesInRoom.push(...this.projectService.getHvacForRoom(this.activeRoomId));
    allDevicesInRoom.push(...this.projectService.getBlindsForRoom(this.activeRoomId));
    allDevicesInRoom.push(...this.projectService.getGeneralEquipmentForRoom(this.activeRoomId));
    allDevicesInRoom.push(...this.projectService.getSafetyEquipmentForRoom(this.activeRoomId));
    allDevicesInRoom.push(...this.projectService.getSecurityEquipmentForRoom(this.activeRoomId));
    allDevicesInRoom.push(...this.projectService.getSecurityEquipmentForRoom(this.activeRoomId));
    allDevicesInRoom = this.filterDeviceWithoutAdminProp(allDevicesInRoom);
    return allDevicesInRoom;
  }

  positionSetup() {
    if (this.showPosition) {
      const allPositionedElementsInRoom = this.getAllPositionedElementsInRoom();
      this.setDevicePositions(allPositionedElementsInRoom);
      this.showPosition = false;
    } else {
      this.showPosition = true;
    }
  }

  setDevicePositions(allPositionedElementsInRoom: PositionedElement[]){
    const dict: { [key: string]: number } = {};
    const daliDict: { [key: string]: number } = {};
    allPositionedElementsInRoom.forEach(device => {
      const deviceType = device?.id?.split('-')[0];
      if (deviceType === 'DL' || deviceType === 'DRGB' || deviceType === 'DG') {
        daliDict[device.id] = device.position;
      } else {
        dict[device.id] = device.position;
      }
    });
    this.apiRoomService.setRoomDevicesPosition(dict).subscribe();
    this.apiRoomService.setRoomDaliDevicesPosition(daliDict).subscribe();
  }



  moveDeviceUp(positionedElement: PositionedElement) {
    const temp = positionedElement.position;
    const activePositionedElement = this.projectService.findDeviceById(positionedElement.id)
    || this.daliProjectService.getDaliLight(positionedElement.id)
    || this.daliProjectService.getDaliRgbLight(positionedElement.id)
    || this.daliProjectService.getDaliLightsGroup(positionedElement.id)
    || this.daliProjectService.getDaliBlinds(positionedElement.id);
    const allPositionedElementsInRoom = this.getAllPositionedElementsInRoom();

    if (activePositionedElement.position === 0) {
      allPositionedElementsInRoom.forEach( dev => {
        dev.position = dev.position + 1;
      });
    }

    const otherDevice = this.getOtherDevice(positionedElement, allPositionedElementsInRoom, 'up');

    activePositionedElement.position = otherDevice.position;
    otherDevice.position = temp;
  }

  moveDeviceDown(positionedElement: PositionedElement) {
    const temp = positionedElement.position;
    const activePositionedElement =
    this.projectService.findDeviceById(positionedElement.id)
    || this.daliProjectService.getDaliLight(positionedElement.id)
    || this.daliProjectService.getDaliRgbLight(positionedElement.id)
    || this.daliProjectService.getDaliLightsGroup(positionedElement.id)
    || this.daliProjectService.getDaliBlinds(positionedElement.id);
    const allPositionedElementsInRoom = this.getAllPositionedElementsInRoom();

    const otherDevice = this.getOtherDevice(positionedElement, allPositionedElementsInRoom, 'down');

    activePositionedElement.position = otherDevice.position;
    otherDevice.position = temp;
  }

  getOtherDevice(positionedElement: PositionedElement, allPositionedElements: PositionedElement[], mode: string) {
    if (mode === 'down') {
      let position = positionedElement.position;
      let foundDevice;
      while (foundDevice === undefined) {
        position = position + 1;
        foundDevice = allPositionedElements.find( dev => {
          if (dev.position === position) {
            return true;
          }
        });
      }
      return foundDevice;
    }else if (mode === 'up') {
      let position = positionedElement.position;
      let foundDevice;
      while (foundDevice === undefined) {
        position = position - 1;
        foundDevice = allPositionedElements.find( dev => {
          if (dev.position === position) {
            return true;
          }
        });
      }
      return foundDevice;
    }
  }

  checkPositioning() {
    if (!this.demoService.isDemo()) {
      let somethingChanged = false;
      const allPositionedElementsInRoom = this.getAllPositionedElementsInRoom();
      allPositionedElementsInRoom.forEach( device => {
        allPositionedElementsInRoom.forEach ( otherDevice => {
          if (device.id !== otherDevice.id && device.position === otherDevice.position ) {
            somethingChanged = true;
            otherDevice.position = otherDevice.position + 1;
          }
        });
      });

      if (somethingChanged) {
        this.setDevicePositions(allPositionedElementsInRoom);
      }

      allPositionedElementsInRoom.forEach( device => {
        if (device.position > this.maxOrder) {
          this.maxOrder = device.position;
        }
        if (device.position < this.minOrder) {
          this.minOrder = device.position;
        }
      });
    }
  }

  setPosition(position: number) {
    return {order: position};
  }

  daliLightGroupOnStatus(lightGroup: LightsGroup) {
    if ((lightGroup.value > 0 && lightGroup.value <= 100) || lightGroup.value == 200) {
      return 'background-orange'
    }
  }

  daliLightGroupOffStatus(lightGroup: LightsGroup) {
    if (lightGroup.value == 0 || lightGroup.value == 200) {
      return 'background-orange'
    }
  }

  showUpPositionArrow(position: number) {
    if (position > this.minOrder) {
      return true;
    } else {
      return false;
    }
  }

  showDownPositionArrow(position: number) {
    if (position < this.maxOrder) {
      return true;
    } else {
      return false;
    }
  }

  userIsAdmin(user: User) {
    return User.isAdmin(user);
  }

  userIsOwner(user: User) {
    return User.isOwner(user);
  }

  goBack() {
    if (this.roomViewType === 'rooms') {
      this.apiRoomService.setRoomId(this.room.id);
      this.router.navigate(['/rooms']);
    } else {
      this.router.navigate(['/home']);
    }
  }

  onClickDaliLightCard(daliLight: Light) {
    if (this.showPosition) {
      return;
    }
    if (!Light.isDimmableDaliLight(daliLight)) {
      return;
    }
    if (this.roomViewType === 'activeDevices') {
      this.roomViewService.setDeviceRouteOrigin('activeDevices');
  } else if (this.roomViewType === 'allDevices') {
      this.roomViewService.setDeviceRouteOrigin('allDevices');
    } else if (this.roomViewType === 'rooms') {
      this.roomViewService.setDeviceRouteOrigin('rooms');
    }

    this.router.navigate([`/rooms/${this.activeRoomId}/dali/${daliLight.id}`]);
  }

  onClickDaliRgbCard(daliLight: Light) {
    if (this.showPosition) {
      return;
    }
    if (this.roomViewType === 'activeDevices') {
      this.roomViewService.setDeviceRouteOrigin('activeDevices');
  } else if (this.roomViewType === 'allDevices') {
      this.roomViewService.setDeviceRouteOrigin('allDevices');
    } else if (this.roomViewType === 'rooms') {
      this.roomViewService.setDeviceRouteOrigin('rooms');
    }
    this.router.navigate([`/rooms/${this.activeRoomId}/dali/${daliLight.id}`]);
  }

  onClickDaliLightsGroupCard(daliLightsGroup: LightsGroup) {
    if (this.showPosition) {
      return;
    }

    if (this.roomViewType === 'activeDevices') {
      this.roomViewService.setDeviceRouteOrigin('activeDevices');
  } else if (this.roomViewType === 'allDevices') {
      this.roomViewService.setDeviceRouteOrigin('allDevices');
    } else if (this.roomViewType === 'rooms') {
      this.roomViewService.setDeviceRouteOrigin('rooms');
    }

    this.router.navigate([`/rooms/${this.activeRoomId}/dali/${daliLightsGroup.id}`]);
  }

  onClickDaliBlindCard(daliBlind: Blind) {
    if (this.showPosition) {
      return;
    }
    if (this.roomViewType === 'allDevices') {
      this.roomViewService.setDeviceRouteOrigin('allDevices');
    } else if (this.roomViewType === 'rooms') {
      this.roomViewService.setDeviceRouteOrigin('rooms');
    }
    this.router.navigate([`/rooms/${this.activeRoomId}/dali/${daliBlind.id}`]);
  }

  daliLightIsDimmable(daliLight: Light) {
    return Light.isDimmableDaliLight(daliLight);
  }


  toggleDaliLight(daliLight: Light, event) {
    event.stopPropagation();
    if (this.demoService.isDemo()) {
      this.loadingService.addToLoading(daliLight.id);
      if (daliLight.equipmentProperties[0].value == 0) {
        this.daliProjectService.changePropertyValueForDemo(daliLight.id, '1');
      } else {
        this.daliProjectService.changePropertyValueForDemo(daliLight.id, '0');
      }
      this.loadingService.removeFromLoading(daliLight.id);
    } else {
      if (Number(daliLight.equipmentProperties[0].value) === 0) {
        this.apiDaliProjectService.activateDaliLight(daliLight.id, 100).subscribe( responce => {
        });
      } else if (Number(daliLight.equipmentProperties[0].value) > 0) {
        this.apiDaliProjectService.activateDaliLight(daliLight.id, 0).subscribe();
      }
    }
  }


  daliLightIsRgb(daliLight: Light) {
    return (daliLight.id.split('-')[0] === 'DRGB');
  }

  daliRgbLightIsHasW(rgbLight: RgbLight) {
    return rgbLight.whiteConnected;
  }

  rgbLightIsOn(rgbLight: RgbLight) {
    return (rgbLight.value !== '#000000' && rgbLight.value !== '#000000|0');
  }

  daliRgbIsOff(rgbLight: RgbLight) {
    return rgbLight.value === '#000000' || rgbLight.value === '#000000|0';
  }

  toggleRgbLight(rgbLight: RgbLight, event) {
    // this.loadingService.addToLoading(rgbLight.id);
    event.stopPropagation();
    if (this.demoService.isDemo()) {
      this.loadingService.addToLoading(rgbLight.id);
      if (rgbLight.value === '#000000|0') {
        this.daliProjectService.changePropertyValueForDemo(rgbLight.id, '#FFFFFF|100');
      } else {
        this.daliProjectService.changePropertyValueForDemo(rgbLight.id, '#000000|0');
      }
      this.loadingService.removeFromLoading(rgbLight.id);
    } else {
      if (this.daliRgbIsOff(rgbLight)) {
        this.apiDaliProjectService.activateRgbLight(rgbLight.id, '#FFFFFF', 100).subscribe();
      } else {
        this.apiDaliProjectService.activateRgbLight(rgbLight.id, '#000000',  0).subscribe();
      }
    }
  }

  toggleDaliLightGroupOn(daliLightsGroup: LightsGroup, event) {
    this.loadingService.addToLoading(daliLightsGroup.id + 'on');
    this.loadingService.removeFromLoading(daliLightsGroup.id + 'on');
    event.stopPropagation();
    this.apiDaliProjectService.activateDaliLightsGroup(daliLightsGroup.id, 100).subscribe();
  }

  toggleDaliLightGroupOff(daliLightsGroup: LightsGroup, event) {
    this.loadingService.addToLoading(daliLightsGroup.id + 'off');
    this.loadingService.removeFromLoading(daliLightsGroup.id + 'off');
    event.stopPropagation();
    this.apiDaliProjectService.activateDaliLightsGroup(daliLightsGroup.id, 0).subscribe();
  }

  ngOnDestroy(): void {
    if (this.daliProjectSubscription) {
      this.daliProjectSubscription.unsubscribe();
    }
    if (this.projectSubscription) {
      this.projectSubscription.unsubscribe();
    }
    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }
    if (this.backButtonSubscription) {
      this.backButtonSubscription.unsubscribe();
    }
    if (this.offlineControllerSubscription) {
      this.offlineControllerSubscription.unsubscribe();
    }
    this.pinService.removePin();
  }
}
