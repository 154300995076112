<div *ngIf="mode === 'live'">
  <div class="flex-column flex-center">
    <span
      #ignored
      [(cpToggle)]="pickerOpened"
      [cpAlphaChannel]="'disabled'"
      [colorPicker]="selectedColorHex"
      (colorPickerChange)="onNewRgbValue($event)"
      [cpWidth]="'300px'"
      [cpOutputFormat]="'hex'"
      [cpIgnoredElements]="[ignored]"
      [cpPosition]="'bottom'"
      (click)="closeColorPicker()"
      class="width300"
      ><div>
        <div class="flex-row flex-center">
          <div
            (click)="toggleDaliRgbLight()"
            class="main-icon flex-row flex-center"
            [ngStyle]="{ backgroundColor: selectedColorHex }"
          >
            <div
              *ngIf="!loadingElement(daliRgbLight.id)"
              class="i-lightbulb-large-black"
            ></div>
            <div
              *ngIf="loadingElement(daliRgbLight.id)"
              class="lds-spinner-md margin-bottom4"
            >
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
              <div></div>
            </div>
          </div>
        </div></div
    ></span>

    <ion-button
      color="ocean-blue"
      (click)="toggleColorPicker($event)"
      class="margin-top20"
      >Custom color</ion-button
    >
  </div>

  <div class="flex-row margin-top20">
    <div
      *ngFor="let colorPreset of colorPresets"
      [ngStyle]="{ backgroundColor: colorPreset }"
      class="color-option"
      (click)="onNewRgbValue(colorPreset)"
    ></div>
  </div>

  <div *ngIf="daliRgbLight.whiteConnected">
    <ion-range
      #rangeValue
      min="{{ sliderOptions.floor }}"
      max="{{ sliderOptions.ceil }}"
      pin="true"
      value="{{ whiteValue }}"
      color="primary"
      (ionChange)="onSliderInputChanged(rangeValue.value)"
      class="margin-top30"
    >
      <ion-icon
        slot="start"
        size="large"
        [style.color]="'#6ba0c4'"
        name="remove-circle"
        (click)="minusClicked()"
        class="clickable margin-right20"
      ></ion-icon>
      <ion-icon
        slot="end"
        size="large"
        [style.color]="'#6ba0c4'"
        name="add-circle"
        (click)="plusClicked()"
        class="clickable margin-left20"
      ></ion-icon>
    </ion-range>
    <div class="text-align-center">{{ daliRgbLight.name }}</div>
  </div>
</div>

<div *ngIf="mode === 'action'">
  <div class="flex-column flex-center">
    <span
      #ignored
      [(cpToggle)]="pickerOpened"
      [cpAlphaChannel]="'disabled'"
      [colorPicker]="selectedColorHex"
      (colorPickerChange)="onNewRgbValue($event)"
      [cpWidth]="'300px'"
      [cpOutputFormat]="'hex'"
      [cpIgnoredElements]="[ignored]"
      [cpPosition]="'bottom'"
      (click)="closeColorPicker()"
      class="width300"
      ><div>
        <div class="flex-row flex-center">
          <div
            class="main-icon flex-row flex-center"
            [ngStyle]="{ backgroundColor: selectedColorHex }">
            <div class="i-lightbulb-large-black"></div>
          </div>
        </div></div
    ></span>

    <ion-button
      color="ocean-blue"
      (click)="toggleColorPicker($event)"
      class="margin-top20"
      >Custom color</ion-button
    >
  </div>

  <div class="flex-row margin-top20">
    <div
      *ngFor="let colorPreset of colorPresets"
      [ngStyle]="{ backgroundColor: colorPreset }"
      class="color-option"
      (click)="onNewRgbValue(colorPreset)"
    ></div>
  </div>

  <div *ngIf="daliRgbLight.whiteConnected">
    <ion-range
      #rangeValue
      min="{{ sliderOptions.floor }}"
      max="{{ sliderOptions.ceil }}"
      pin="true"
      value="{{ whiteValue }}"
      color="primary"
      (ionChange)="onSliderInputChanged(rangeValue.value)"
      class="margin-top30">
      <ion-icon
        slot="start"
        size="large"
        [style.color]="'#6ba0c4'"
        name="remove-circle"
        (click)="minusClicked()"
        class="clickable margin-right20"
      ></ion-icon>
      <ion-icon
        slot="end"
        size="large"
        [style.color]="'#6ba0c4'"
        name="add-circle"
        (click)="plusClicked()"
        class="clickable margin-left20"
      ></ion-icon>
    </ion-range>
    <!-- <div class="text-align-center">{{ daliRgbLight.name }}</div> -->
  </div>
</div>

<div *ngIf="mode === 'condition'">
  <div class="flex-column" >
        <ion-radio-group [(ngModel)]="selectedRadio">
            <ion-item lines="none">
              <ion-label>{{ 'On' | genComTranslate }}</ion-label>
              <ion-radio slot="start" class="color-radio margin-right40" value='1' (click)="handleBinaryChange($event)"></ion-radio>
            </ion-item>

            <ion-item lines="none">
              <ion-label>{{ 'Off' | genComTranslate }}</ion-label>
              <ion-radio slot="start" class="color-radio margin-right40" value='0'(click)="handleBinaryChange($event)"></ion-radio>
            </ion-item>
          </ion-radio-group>
      </div>
</div>

<div *ngIf="mode === 'editScene'">
  <div class="flex-column flex-center">
    <span
      #ignored
      [(cpToggle)]="pickerOpened"
      [cpAlphaChannel]="'disabled'"
      [colorPicker]="selectedColorHex"
      (colorPickerChange)="onNewRgbValue($event)"
      [cpWidth]="'300px'"
      [cpOutputFormat]="'hex'"
      [cpIgnoredElements]="[ignored]"
      [cpPosition]="'bottom'"
      (click)="closeColorPicker()"
      class="width300"
      ><div>
        <div class="flex-row flex-center">
          <div
            class="main-icon flex-row flex-center"
            [ngStyle]="{ backgroundColor: selectedColorHex }">
            <div class="i-lightbulb-large-black"></div>
          </div>
        </div></div
    ></span>

    <ion-button
      color="ocean-blue"
      (click)="toggleColorPicker($event)"
      class="margin-top20"
      >Custom color</ion-button
    >
  </div>

  <div class="flex-row margin-top20">
    <div
      *ngFor="let colorPreset of colorPresets"
      [ngStyle]="{ backgroundColor: colorPreset }"
      class="color-option"
      (click)="onNewRgbValue(colorPreset)"
    ></div>
  </div>
  <div *ngIf="daliRgbLight.whiteConnected">
    <ion-range
      #rangeValue
      min="{{ sliderOptions.floor }}"
      max="{{ sliderOptions.ceil }}"
      pin="true"
      value="{{ whiteValue }}"
      color="primary"
      (ionChange)="onSliderInputChanged(rangeValue.value)"
      class="margin-top30"
    >
      <ion-icon
        slot="start"
        size="large"
        [style.color]="'#6ba0c4'"
        name="remove-circle"
        (click)="minusClicked()"
        class="clickable margin-right20"
      ></ion-icon>
      <ion-icon
        slot="end"
        size="large"
        [style.color]="'#6ba0c4'"
        name="add-circle"
        (click)="plusClicked()"
        class="clickable margin-left20"
      ></ion-icon>
    </ion-range>
    <!-- <div class="text-align-center">{{ daliRgbLight.name }}</div> -->
  </div>
  <div class="flex-row flex-center margin-top20">
    <ion-button (click)="onApply()">Apply</ion-button>
  </div>
</div>
