import { Component, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { Observable, Subscription } from 'rxjs';
import { Device } from 'src/app/core/models/project/devices/device.model';
import { Hvac } from 'src/app/core/models/project/devices/hvac.model';
import { Floor } from 'src/app/core/models/project/floor.model';
import { Project } from 'src/app/core/models/project/project.model';
import { Property } from 'src/app/core/models/project/property.model';
import { DaliProjectService } from 'src/app/modules/project/services/dali-project.service';
import { ApiDaliProjectService } from 'src/app/modules/project/services/http/api-dali-project.service';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { USE_DALI } from 'src/environments/environment';
import { ApartmentService } from '../../services/apartment.service';
import { EquipmentComponentGenService } from '../../services/equipment-component-gen.service';
import { EquipmentPropertyTypesService } from '../../services/equipment-property-types.service';

@Component({
  selector: 'app-active-devices',
  templateUrl: './active-devices.component.html',
  styleUrls: ['./active-devices.component.scss']
})
export class ActiveDevicesComponent implements OnInit, OnDestroy {

  project: Project =  this.projectService.getProject();
  selectedFloor = null;
  backButtonSubscription: Subscription;
  selectedApartmentId$: Observable<string> = this.apartmentService.getApartmentId();

  constructor(private projectService: ProjectService,
              private router: Router,
              private equipmentComponentGen: EquipmentComponentGenService,
              private equipmentPropertyTypesService: EquipmentPropertyTypesService,
              private platform: Platform,
              private daliProjectService: DaliProjectService,
              private apiDaliProjectService: ApiDaliProjectService,
              private apartmentService: ApartmentService
              ) { }


  ngOnInit(): void {
    this.backButtonSubscription = this.platform.backButton.subscribeWithPriority(10, () => {
      this.goBack();
    });
  }

  selectFloor(selectedFl: Floor) {
    this.selectedFloor = selectedFl;
  }

  isThereActiveDeviceOnFloor(floor: Floor): boolean {
    return floor.listOfRooms.some((roomId) => {
      if (USE_DALI) {
        return (this.getActiveHvacForRoom(roomId).length > 0)
        || (this.getActiveLightsForRoom(roomId).length > 0)
        || (this.daliProjectService.getActiveDaliLightsForRoom(roomId).length > 0)
        || (this.daliProjectService.getActiveDaliRgbLightsForRoom(roomId).length > 0)
        ;
      } else {
        return (this.getActiveHvacForRoom(roomId).length > 0) || (this.getActiveLightsForRoom(roomId).length > 0);
      }

    });
  }

  getFloorsWithActiveDevices(floors: Floor[]) {
    return floors.filter((fl) => this.isThereActiveDeviceOnFloor(fl));
  }

  getActiveHvacForRoom(roomId: string): Device[] {
    if (this.project) {
      let  hvacsInRoom: Device[] = this.projectService.getHvacForRoom(roomId);
      hvacsInRoom =  this.filterDeviceWithoutAdminProp(hvacsInRoom);
      return hvacsInRoom.filter((hvac: Hvac) => {
        return this.isHvacActive(hvac);
         });
    }
  }

  isHvacActive(hvac: Hvac) {
    return hvac.equipmentProperties.some((prop: Property) => {
      return Property.isHvacControl(prop) && Number(prop.value) === 1;
    });
  }

  getActiveLightsForRoom(roomId: string): Device[] {
    if (this.project) {
      let lightsInRoom: Device[] = this.projectService.getLightsForRoom(roomId);
      lightsInRoom = this.filterDeviceWithoutAdminProp(lightsInRoom);
      lightsInRoom = lightsInRoom.filter((light: Device) => {
        const primaryProperty: Property = this.getPrimaryProperty(light);
        return (Number (primaryProperty?.value) === 1);
      });
      return lightsInRoom;
    }
  }

  filterDeviceWithoutAdminProp(devices: Device[]): Device[] {
    return devices.filter( device => {
      return device.equipmentProperties.some( prop => {
        return this.equipmentPropertyTypesService.getEquipmentPropertyType(prop.type)
        || this.equipmentPropertyTypesService.getEquipmentPropertyTypeForStatus(prop.type);
      });
    });
  }

  getPrimaryProperty(device: Device): Property {
    return  device.equipmentProperties.find((prop: Property) => {
      const propData = this.equipmentComponentGen.getTextualRepresentationOfValue(prop);
      return propData.primaryProperty === true;
    });
  }

  goBack() {
    this.router.navigate([`/home`]);
  }

  ngOnDestroy(): void {
    if (this.backButtonSubscription) {
      this.backButtonSubscription.unsubscribe();
    }
  }
}
