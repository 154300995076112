import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';

import { API_BASE_URL } from 'src/environments/environment';


@Injectable({
    providedIn: 'root'
})
export class ApiService {
    constructor(private http: HttpClient) {}

    getBaseMqttSubscription() {
        return this.http.get(API_BASE_URL + '/Status/mqttoutbase', {responseType: 'text'});
      }
}
