import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';
import { throwError, BehaviorSubject } from 'rxjs';
import { catchError, filter, take, switchMap } from 'rxjs/operators';

import { AuthenticationService } from '../authentication/authentication.service';
import { MessagingService } from '../services/messaging.service';
import { UserService } from '../services/user.service';
import { ILoginResponse } from '../models/login-response.interface';

@Injectable()
export class ErrorInterceptor implements HttpInterceptor {
    private refreshTokenSubject: BehaviorSubject<any> = new BehaviorSubject<any>(
        null
    );

    constructor(private authService: AuthenticationService, private userService: UserService, private messagingService: MessagingService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler) {
        return next.handle(request).pipe(catchError(err => {
            const errorMessage = err.statusText;
            if (err.status === 401) {
                if (request.url.includes('refresh') || request.url.includes('login')) {
                    // We do another check to see if refresh token failed
                    // In this case we want to logout user and to redirect it to login page
                    if (request.url.includes('refresh')) {
                        this.authService.logout(true);
                        this.messagingService.setMessage('Your session has expired. Please login again to continue');
                    } else {
                        this.messagingService.setMessage('Wrong username or password');
                    }

                    return throwError(errorMessage);
                }

                if (this.authService.refreshTokenInProgress) {
                    // If refreshTokenInProgress is true, we will wait until refreshTokenSubject has a non-null value
                    // – which means the new token is ready and we can retry the request again
                    return this.refreshTokenSubject.pipe(
                        filter(result => result !== null),
                        take(1),
                        switchMap(() => {
                           return next.handle(this.addAuthenticationToken(request))
                        })
                    )
                } else {
                    this.authService.setRefreshTokenInProgress(true);

                    // Set the refreshTokenSubject to null so that subsequent API calls will wait until the new token has been retrieved
                    this.refreshTokenSubject.next(null);

                    if (!this.authService.getAccessToken() && !this.authService.getRefreshToken()) {
                        this.authService.logout(true);
                        return throwError(errorMessage);
                    }

                    // Call auth.refreshAccessToken(this is an Observable that will be returned)
                    return this.authService
                        .refreshAccessToken().pipe(
                            switchMap((response: ILoginResponse) => {
                                // When the call to refreshToken completes we reset the refreshTokenInProgress to false
                                // for the next time the token needs to be refreshed
                                this.userService.setUser(response.user);
                                this.authService.setAccessToken(response.tokens.accessToken);
                                this.authService.setRefreshToken(response.tokens.refreshToken);
                                this.authService.setRefreshTokenInProgress(false);
                                this.refreshTokenSubject.next(response);
                                return next.handle(this.addAuthenticationToken(request));
                            }),
                            catchError((error: any) => {
                                this.authService.setRefreshTokenInProgress(false);
                                this.authService.logout(true);
                                return throwError(error.statusText);
                            })
                            );
                        }
            } else if (err.status === 403) {
                this.messagingService.setMessage('Pin you entered is wrong!');
                return throwError(errorMessage);
            } else {
                if (!request.url.includes('9986') && !request.url.includes('status')) {
                    if (err.status === 0) {
                        this.messagingService.setMessage('No connection');
                    } else if (err.status === 422 &&
                      ( err.error === 'Card with that uid already exists!' || err.error === 'Cards not created')) {
                      this.messagingService.setMessage('Access pin reserved. Choose another pin!');
                    }
                    else if (err.status === 422 && err.error) {
                        this.messagingService.setMessage(err.error);
                    }
                    else if (err.status === 422) {
                        this.messagingService.setMessage('Invalid data');
                    }
                }
                return throwError(errorMessage);
            }
        }));
    }

    addAuthenticationToken(request) {
        const accessToken = this.authService.getAccessToken();
        if (!accessToken) {
            return request;
        }
        return request.clone({
            setHeaders: {
                Authorization: 'Bearer ' + this.authService.getAccessToken()
            }
        });
    }
}
