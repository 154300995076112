import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { Blind } from 'src/app/core/models/project/devices/blind.model';
import { ApiDaliProjectService } from 'src/app/modules/project/services/http/api-dali-project.service';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { Action } from 'src/app/core/models/automation/action.model';


@Component({
  selector: 'app-dali-blind',
  templateUrl: './dali-blind.component.html',
  styleUrls: ['./dali-blind.component.scss']
})
export class DaliBlindComponent implements OnInit {
  @Input() mode: 'live' | 'action' ;
  @Input() daliBlind: Blind;
  @Output() deviceAction$: EventEmitter<Action> = new EventEmitter();
  edit = false;
  action: Action = new Action();

  @Input() set daliAction(action: Action) {
    if (action) {
      this.edit = true;
      this.action = action;
      this.deviceAction$.emit(this.action);
    }
  }

  constructor(
    private apiDaliProjectService: ApiDaliProjectService,
    private loadingService: LoadingService
    ) { }

  ngOnInit(): void {
    if (this.mode === 'action' && !this.edit) {
      this.action.id = 'actionIdDaliBlind1';
      this.action.name = 'Activate DALI Blind';
      this.action.description = 'Action for activating Dali blinds';
      this.action.command = 'DaliActions.ActivateBlinds';
      this.action.parameters = [`${this.daliBlind.equipmentProperties[0].id}`, `1`];
      this.action.builtIn = true;
      this.deviceAction$.emit(this.action);
    }
  }

  onClickBlindsUp() {
    this.loadingService.addToLoading(this.daliBlind.equipmentProperties[0].id);
    this.apiDaliProjectService.activateDaliBlinds(this.daliBlind.equipmentProperties[0].id, 1).subscribe();
    this.loadingService.removeFromLoading(this.daliBlind.equipmentProperties[0].id);
  }

  onClickBlindsDown() {
    this.loadingService.addToLoading(this.daliBlind.equipmentProperties[1].id);
    this.apiDaliProjectService.activateDaliBlinds(this.daliBlind.equipmentProperties[1].id, 1).subscribe();
    this.loadingService.removeFromLoading(this.daliBlind.equipmentProperties[1].id);
  }


  loadingElement(elementId: string) {
    return this.loadingService.getLoadingElements().includes(elementId);
  }

  handleActionChange(daliBlindsSceneId) {
    /* this.action.parameters[0] = daliBlindsSceneId;
    this.deviceAction$.emit(this.action); */
    const newAction = JSON.parse(JSON.stringify(this.action));
    this.action.parameters[0] = Number(daliBlindsSceneId);
    newAction.parameters[0] = daliBlindsSceneId;
    this.deviceAction$.emit(newAction);
  }

}
