<div class="pagination" *ngIf="this.paginationData?.TotalCount!==0">
  <div class="pagination">
    <a
      [ngClass]="{
        'not-clickable' : this.paginationData?.CurrentPage===1,
        'clickable' : this.paginationData?.CurrentPage!==1}"
      class="first-page"
      (click)="onNavigate(1)">
    </a>
    <a
      class="clickable"
      (click)="onNavigate(this.paginationData.CurrentPage-2)"
      *ngIf="this.paginationData?.CurrentPage===this.paginationData?.TotalPages && this.paginationData?.TotalPages>2">
      {{ this.paginationData.CurrentPage -2 }}
    </a>

    <a
      class="clickable"
      (click)="onNavigate(this.paginationData.CurrentPage - 1)"
      *ngIf="this.paginationData?.HasPrevious===true">
      {{ this.paginationData.CurrentPage - 1 }}
    </a>

    <a
      class="active">
      {{ this.paginationData?.CurrentPage }}
    </a>

    <a
      class="clickable"
      (click)="onNavigate(this.paginationData.CurrentPage + 1)"
      *ngIf="this.paginationData?.HasNext">
      {{ this.paginationData.CurrentPage + 1 }}
    </a>

    <a
      class="clickable"
      (click)="onNavigate(this.paginationData.CurrentPage + 2)"
      *ngIf="this.paginationData?.CurrentPage===1 && this.paginationData?.TotalPages>2">
      {{ this.paginationData.CurrentPage + 2 }}
    </a>

    <a
     [ngClass]="{
       'not-clickable' : this.paginationData?.CurrentPage===this.paginationData?.TotalPages,
      'clickable' : this.paginationData?.CurrentPage!==this.paginationData?.TotalPages}"
      class="last-page"
      (click)="onNavigate(this.paginationData.TotalPages)">
    </a>
  </div>
