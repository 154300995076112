import { Property } from '../property.model';

export class Device {
  id: string;
  name: string;
  equipmentProperties: Property[];
  listOfLocationIds: string[];
  visibleToUser: boolean;
  isCondition: boolean;
  isActuator: boolean;
  position: number;
  roomMax?: number;
  roomMin?: number;
  bathroomMax?: number;
  bathroomMin?: number;

  public static getRoomId(device: Device): string {
    return `${device.listOfLocationIds[0]?.split('.')[0]}.${device.listOfLocationIds[0]?.split('.')[1]}.${device.listOfLocationIds[0]?.split('.')[2]}`;
  }
}
