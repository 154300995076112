import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'sceneAptFilter'
})
export class SceneAptFilterPipe implements PipeTransform {

  transform(scenes: any[], apartmentId: string): any[] {
    if (!scenes) {
      return [];
    }
    if (!apartmentId) {
      return scenes;
    }

    return scenes.filter((scene) => {
      return scene.apartmentId === apartmentId;
    });
  }

}


