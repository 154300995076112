<ion-app class="app" (click)="onClickInApp()" [ngClass]="{'sleep-overlay': sleepMode$ | async}">
<ion-split-pane when="(min-width: 1300px)" contentId="main">
  <ion-menu contentId="main" [disabled]="!showSidebar">
    <app-sidebar class="sidebar" ></app-sidebar>
  </ion-menu>
  <div id="main" class="width-full">
    <ion-header *ngIf="showHeader">
      <app-header></app-header>
    </ion-header>
    <app-message-box></app-message-box>
    <ion-content [ngClass]="{'content-footer': showFooter}" [scrollEvents]="true" (ionScroll)="onScroll($event)">
      <router-outlet></router-outlet>
    </ion-content>
    <ion-footer>
      <app-footer *ngIf="showFooter"></app-footer>
    </ion-footer>
  </div>
</ion-split-pane>
</ion-app>
