import { Component, OnInit, Input, EventEmitter, Output, OnDestroy} from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { Device } from 'src/app/core/models/project/devices/device.model';
import { Hvac } from 'src/app/core/models/project/devices/hvac.model';
import { Light } from 'src/app/core/models/project/devices/light.model';
import { Project } from 'src/app/core/models/project/project.model';
import { Property } from 'src/app/core/models/project/property.model';
import { Room } from 'src/app/core/models/project/room.model';
import { User } from 'src/app/core/models/user/user.model';
import { DemoService } from 'src/app/core/services/demo.service';
import { UserService } from 'src/app/core/services/user.service';
import { DaliProjectService } from 'src/app/modules/project/services/dali-project.service';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { USE_DALI, USE_DEVICE_VISIBLE_TO_USER } from 'src/environments/environment';
import { ApartmentService } from '../../services/apartment.service';
import { EquipmentPropertyTypesService } from '../../services/equipment-property-types.service';
import { FastSceneAndAutoCreate } from 'src/app/shared/services/fastSceneAndAutoCreate.service';
import { AptRoomFilterPipe } from '../../pipes/apt-room-filter.pipe';
import { ModalController } from '@ionic/angular';


@Component({
  selector: 'app-choose-rooms',
  templateUrl: './choose-rooms.component.html',
  styleUrls: ['./choose-rooms.component.scss']
})
export class ChooseRoomsComponent implements OnInit, OnDestroy {

  @Input () liveView = false;
  @Input () showStatusIcons: boolean;
  @Input () readonly listOfRooms: Room[];
  @Input () commErrorRooms: Array<any> = [];
  @Output () roomClicked: EventEmitter<Room>  = new EventEmitter();
  apartmentId$: Observable<string>;
  project: Project = this.projectService.getProject();
  commErrorSubscription = new Subscription();
  user: User;

  selectedRooms: Room[] = [];
  apartmentId: string;

  constructor(
    private apartmentService: ApartmentService,
    private projectService: ProjectService,
    private equipmentPropertyTypesService: EquipmentPropertyTypesService,
    private daliProjectService: DaliProjectService,
    private userService: UserService,
    private fastSceneAndAutoCreate: FastSceneAndAutoCreate,
    private aptRoomFilterPipe: AptRoomFilterPipe,
    private modalController: ModalController
    ) {}

  ngOnInit(): void {
    this.apartmentId$ = this.apartmentService.getApartmentId();
    this.apartmentId$.subscribe( value => {
      this.apartmentId = value;
    })

    this.user = this.userService.getUser();

    this.fastSceneAndAutoCreate.getSelectedRooms().subscribe( value => {
      this.selectedRooms = value;
    })
  }


  isHvacActiveInRoom(room: Room): boolean {
    const hvacsInRoom: Hvac[] = this.projectService.getHvacForRoom(room.id);
    return hvacsInRoom.some((hvac: Hvac) => {
      return hvac.equipmentProperties.some((prop: Property) => {
        return Property.isHvacControl(prop) && Number(prop.value) === 1;
      });
    });
  }

  getCurrentTemp(room: Room) {
    const hvacsInRoom: Hvac[] = this.projectService.getHvacForRoom(room.id);
    if (hvacsInRoom.length >= 1) {
      let currentTempProp: Property;
      let currentBathroomTempProp: Property;
      hvacsInRoom.forEach((hvacDevice: Hvac) => {
        hvacDevice.equipmentProperties.forEach((prop: Property) => {
          if (Property.isRoomTemperatureCurrent(prop) && !currentTempProp) {
            currentTempProp = prop;
          }
          if (Property.isBathroomTemperatureCurrent(prop) && !currentBathroomTempProp) {
            currentBathroomTempProp = prop;
          }
        });
      });

      if (currentTempProp?.value !== null && currentTempProp?.value !== undefined) {
        return currentTempProp.value;
      } else if (currentBathroomTempProp?.value !== null && currentBathroomTempProp?.value !== undefined) {
        return currentBathroomTempProp.value;
      } else {
        return '?';
      }
    } else {
      return '?';
    }
  }

  isLightActiveInRoom(room: Room): boolean {
    const lightsInRoom: Light[] = this.projectService.getLightsForRoom(room.id);
    return lightsInRoom.some((light: Light) => {
      return light.equipmentProperties.some((prop: Property) => {
        return ((prop.type >= 10650 && prop.type <= 10699) // WelcomeLight
        || (prop.type >= 10700 && prop.type <= 10749))  // Light
        && Number(prop.value) === 1;
      });
    });
  }

  isDoorOpenInRoom(room: Room): boolean {
    const generalEquipmentInRoom: Device[] = this.projectService.getGeneralEquipmentForRoom(room.id);
    return generalEquipmentInRoom.some((device: Device) => {
      return device.equipmentProperties.some((prop: Property) => {
        return (Property.isDoorOrBalconyDoor(prop) && Number(prop.value) === 1); // 1 === open 0 = closed door
      });
    });
  }

  isWindowOpenInRoom(room: Room): boolean {
    const generalEquipmentInRoom: Device[] = this.projectService.getGeneralEquipmentForRoom(room.id);
    return generalEquipmentInRoom.some((device: Device) => {
      return device.equipmentProperties.some((prop: Property) => {
        return (Property.isWindow(prop) && Number(prop.value) === 1); // 1 === open 0 = closed door
      });
    });
  }

  isSecurityAlarmActiveInRoom(room: Room): boolean {
    const securityEquipmentInRoom: Device[] = this.projectService.getSecurityEquipmentForRoom(room.id);
    return securityEquipmentInRoom.some((device: Device) => {
      return device.equipmentProperties.some((prop: Property) => {
        if (Property.isRoomArmed(prop)) {
          return false;
        }
        if (!this.equipmentPropertyTypesService.getEquipmentPropertyTypeForStatus(prop.type)) {
          return false;
        }
        return Property.propertyIsActive(prop);
      });
    });
  }


  isSafetyAlarmActiveInRoom(room: Room): boolean {
    const safetyEquipmentInRoom: Device[] = this.projectService.getSafetyEquipmentForRoom(room.id);
    return safetyEquipmentInRoom.some((device: Device) => {
      return device.equipmentProperties.some((prop: Property) => {
        if (!this.equipmentPropertyTypesService.getEquipmentPropertyTypeForStatus(prop.type)) {
          return false;
        }
        return Property.propertyIsActive(prop);
      });
    });
  }



  roomHasDevicesToShow(room: Room): boolean {
    if (!USE_DEVICE_VISIBLE_TO_USER || User.isAdmin(this.user)) {
      return true;
    }
    let devices;
    if (USE_DALI) {
      devices = [
        ...this.getDaliLightsForRoom(room.id),
        ...this.getDaliRgbLightsForRoom(room.id),
        ...this.getDaliLightsGroupForRoom(room.id),
        ...this.getDaliBlindsForRoom(room.id),

        ...this.getLightsForRoom(room.id),
        ...this.getBlindsForRoom(room.id),
        ...this.getHvacForRoom(room.id),
        ...this.getSecurityEquipmentForRoom(room.id),
        ...this.getSafetyEquipmentForRoom(room.id),
        ...this.getGeneralEquipmentForRoom(room.id)
      ];
    } else {
      devices = [
        ...this.getLightsForRoom(room.id),
        ...this.getBlindsForRoom(room.id),
        ...this.getHvacForRoom(room.id),
        ...this.getSecurityEquipmentForRoom(room.id),
        ...this.getSafetyEquipmentForRoom(room.id),
        ...this.getGeneralEquipmentForRoom(room.id)
      ];
    }
    const visibleDevices = devices.filter((dev) => {
      return dev?.visibleToUser;
    });
    return visibleDevices.length > 0;
  }

  getDaliLightsForRoom(roomId) {
    return this.daliProjectService.getDaliLightsForRoom(roomId);
  }

  getDaliRgbLightsForRoom(roomId) {
    return this.daliProjectService.getDaliRgbLightsForRoom(roomId);
  }

  getDaliLightsGroupForRoom(roomId) {
    return this.daliProjectService.getDaliLightsGroupForRoom(roomId);
  }

  getDaliBlindsForRoom(roomId) {
    return this.daliProjectService.getDaliBlindsForRoom(roomId);
  }

  getLightsForRoom(roomId) {
    return this.projectService.getLightsForRoom(roomId);
  }

  getBlindsForRoom(roomId) {
    return this.projectService.getBlindsForRoom(roomId);
  }

  getHvacForRoom(roomId) {
    return this.projectService.getHvacForRoom(roomId);
  }

  getSecurityEquipmentForRoom(roomId) {
    return this.projectService.getSecurityEquipmentForRoom(roomId);
  }

  getSafetyEquipmentForRoom(roomId) {
    return this.projectService.getSafetyEquipmentForRoom(roomId);
  }

  getGeneralEquipmentForRoom(roomId) {
    return this.projectService.getGeneralEquipmentForRoom(roomId);
  }

  roomIsSelected(room: Room) {
    return this.selectedRooms.some( element => element.apartmentId === room.apartmentId && element.id === room.id)
  }

  addOrRemoveRoom(room: Room) {
    if (this.roomIsSelected(room)) {
      this.fastSceneAndAutoCreate.removeRoom(room)
    } else {
      this.fastSceneAndAutoCreate.addRoom(room)
    }
  }

  selectAllRooms() {
    const allRooms = this.aptRoomFilterPipe.transform(this.listOfRooms, this.apartmentId);
    this.fastSceneAndAutoCreate.selectAllRooms(allRooms);
  }

  onRoomClicked(clickedRoom: Room) {
    this.roomClicked.emit(clickedRoom);
  }

  clearAllRooms() {
    this.fastSceneAndAutoCreate.clearRooms();
  }

  confirmed() {
    this.modalController.dismiss();
  }

  ngOnDestroy(): void {
    if (this.commErrorSubscription) {
      this.commErrorSubscription.unsubscribe();
    }
  }

}
