import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Observable } from 'rxjs';
import { AdminSettingsService } from 'src/app/modules/admin-settings/admin-settings.service';
import { Mode } from 'src/app/core/models/modes/mode.model';
import { ModesService } from 'src/app/modules/modes/services/modes.service';
import { ApiModesService } from 'src/app/modules/modes/services/http/api-modes.service';
import { ApartmentService } from 'src/app/shared/services/apartment.service';
import { Subscription } from 'rxjs/internal/Subscription';
import { DemoService } from 'src/app/core/services/demo.service';

@Component({
  selector: 'app-equipment-number-settings',
  templateUrl: './equipment-number-settings.component.html',
  styleUrls: ['./equipment-number-settings.component.scss']
})
export class EquipmentNumberSettingsComponent implements OnInit, OnDestroy {

  settingValue$: Observable<any>;
  sliderButtonClicked = false;
  @Input() propIdentifier: string; // example 'BL-2/6500'
  @Input() unitOfMeasurement;

  apartmentIdSubscription: Subscription;
  modesSubscription: Subscription;
  modes: Mode[];
  apartmentId: string;
  heatingActive: boolean;
  coolingActive: boolean;

  // SLIDER SETTINGS
  options = {
    floor: 0,
    ceil: 50,
  };

  constructor(private adminSettingsService: AdminSettingsService,
              private apiModesService: ApiModesService,
              private modesService: ModesService,
              private apartmentService: ApartmentService,
              private demoService: DemoService) { }

  ngOnInit(): void {
    this.settingValue$ = this.adminSettingsService.getSetting(this.propIdentifier);

    this.getModes();
    this.apartmentIdSubscription = this.apartmentService.getApartmentId().subscribe((aptId: string) => {
      this.apartmentId = aptId;
      if (this.modes) {
        this.updateStatuses();
      }
    });
  }

  getModes() {
    this.modes = this.modesService.getModes();
    this.apiModesService.getModes().subscribe();
    this.modesSubscription = this.modesService.modesChanged.subscribe(() => {
      this.modes = this.modesService.getModes();
      if (this.demoService.isDemo()) {
        this.demoService.setModesForDemo(this.modes);
        this.modes = this.demoService.getModesForDemo();
      }
      this.updateStatuses();
    });
  }


  onInputChanged(inputNumber) {
    if (!this.sliderButtonClicked) {
      this.adminSettingsService.inputPropValue(this.propIdentifier, inputNumber);
    }
    this.sliderButtonClicked = false;
  }


  plusClicked() {
    this.sliderButtonClicked = true;
    this.adminSettingsService.incrementPropValue(this.propIdentifier);
  }

  minusClicked() {
    this.sliderButtonClicked = true;
    this.adminSettingsService.decrementPropValue(this.propIdentifier);
  }

  updateStatuses() {
    this.modes?.forEach((mode: Mode) => {
      if (Mode.isCooling(mode) && (mode.apartmentId === this.apartmentId)){
        this.coolingActive = mode.isActive;
      } else if (Mode.isHeating(mode) && (mode.apartmentId === this.apartmentId)){
        this.heatingActive = mode.isActive;
      } 
    });
  }

  getSliderColor() {
    if (this.coolingActive) {
      return 'primary';
    } else if(this.heatingActive) {
      return 'danger';
    }
  }

  ngOnDestroy() {
    if (this.modesSubscription) {
      this.modesSubscription.unsubscribe();
    }
    if (this.apartmentIdSubscription) {
      this.apartmentIdSubscription.unsubscribe();
    }
  }

}
