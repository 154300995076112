<div class="center-slider">
  <ion-item lines="none">
    <ion-range
      #rangeValue
      min="{{ options.floor }}"
      max="{{ options.ceil }}"
      step="1"
      value="{{ equipmentProperty.value }}"
      snaps
      [color]="getSliderColor()"
      (mousedown)="sliderClicked()"
      (touchstart)="sliderClicked()"
      (ionChange)="onInputChanged(rangeValue.value)"
    >
      <ion-icon
        slot="start"
        size="large"
        [style.color]="'#0462F8'"
        name="remove-circle"
        (click)="minusClicked(targetNumber)"
        class="clickable margin-right20 margin-left20"
      ></ion-icon>
      <ion-icon
        slot="end"
        size="large"
        [style.color]="'#0462F8'"
        name="add-circle"
        (click)="plusClicked(targetNumber)"
        class="clickable margin-right20 margin-left20"
      ></ion-icon>
    </ion-range>
  </ion-item>
  <div class="num-label" [ngClass]="heatingActive ? 'red-text': ''">{{equipmentProperty.value}} {{ unitOfMeasurement }}</div>
</div>
