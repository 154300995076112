import { Component, OnInit, Input, Output, EventEmitter, HostListener, OnDestroy} from '@angular/core';
import { Property } from 'src/app/core/models/project/property.model';
import { Action } from 'src/app/core/models/automation/action.model';
import { Device } from 'src/app/core/models/project/devices/device.model';
import { Condition } from 'src/app/core/models/automation/condition.model';
import { ComponentMode } from 'src/app/core/models/equipment-property-component-to-load.model';
import { ApiProjectService } from 'src/app/modules/project/services/http/api-project.service';
import { Subscription } from 'rxjs';
import { backgroundFade, slideFromBottom } from 'src/app/shared/animations/animations';
import { ModalController } from '@ionic/angular';
import { EquipmentBinaryMutexLiveComponent } from '../equipment-binary-mutex-live/equipment-binary-mutex-live.component';
import { PinService } from 'src/app/shared/services/pin.service';
import { ModalPinComponent } from '../../modal-pin/modal-pin.component';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { DemoService } from 'src/app/core/services/demo.service';

@Component({
  selector: 'app-equipment-binary-mutex',
  templateUrl: './equipment-binary-mutex.component.html',
  animations: [slideFromBottom, backgroundFade],
  styleUrls: ['./equipment-binary-mutex.component.scss']
})
export class EquipmentBinaryMutexComponent implements OnInit, OnDestroy {
  @Input() parentDevice: Device;
  @Input() propertyArray: Property[];
  @Input() title: string;
  @Input() mode: ComponentMode;

  @Input() inputAction: Action;
  @Output() actionChanged$: EventEmitter<Action> = new EventEmitter();
  action: Action;

  @Input() inputCondition: Condition;
  @Output() conditionChanged$: EventEmitter<Condition> = new EventEmitter();
  condition: Condition;
  activeProperty: Property;
  projectSubscription: Subscription;

  propIsBlinds = false;
  propIsHvacControl = false;

  loadingSubscription: Subscription;
  primaryPropertySubscription: Subscription;
  loadingElements = [];

  constructor(private apiProjectService: ApiProjectService,
              private projectService: ProjectService,
              private modalController: ModalController,
              private pinService: PinService,
              private loadingService: LoadingService,
              private demoService: DemoService) {}


  ngOnInit(): void {
    if (this.mode === 'action') {
      if (this.inputAction) {
        this.action = this.inputAction;
      }
    } else if (this.mode === 'condition') {
      if (this.inputCondition) {
        this.condition = JSON.parse(JSON.stringify(this.inputCondition));
        }
      }
      else if (this.mode === 'live') {
        this.setupActiveProperty();
        this.loadingSub();
        this.primaryPropSub();
      }
    }

    loadingSub() {
      this.loadingElements = this.loadingService.getLoadingElements();
      this.loadingSubscription = this.loadingService.loadingChanged.subscribe( response => {
        this.loadingElements = this.loadingService.getLoadingElements();
      });
    }

    primaryPropSub() {
      this.primaryPropertySubscription = this.projectService.mutexComponentPrimaryPropChange.subscribe( () => {
        this.setupActiveProperty();
      });
    }

    loadingElement(id: string) {
      if (this.loadingElements.includes(id)) {
        return true;
      } else {
        return false;
      }
    }

    setupActiveProperty() {
      this.activeProperty = this.propertyArray.find( prop => {
        this.checkIfBlinds(prop);
        this.checkIfHvacControl(prop);
        if (+prop.value === 1) {
          return true;
        }
      });
    }

    checkIfBlinds(prop: Property) {
      if (prop.type >=  10500 && prop.type <= 10549) {
        this.propIsBlinds = true;
      }
    }

    checkIfHvacControl(prop: Property) {
      if (Property.isHvacMutex(prop)) {
        this.propIsHvacControl = true;
      }
    }


  createNewCondition(equipmentProperty) {
    const condition = new Condition();
    condition.automationConditionType = 'simple';
    condition.type = 0; // on off (binary) component always generates equals condition type (0)
    condition.parameter1 = `ValRef:${this.parentDevice.id}/${equipmentProperty.type}`;
    condition.parameter2 = 1;
    condition.parameter3 = 0; // is ignored in on off (binary) component
    condition.editable = false;
    condition.parameter1Editable = false;
    condition.parameter2Editable = false;
    condition.parameter3Editable = false;
    return condition;
  }

  handleConditionChange(property: Property) {
    const condition = this.createNewCondition(property);
    this.conditionChanged$.next(condition);
  }

  handleLiveChange(property: Property) {
    if (this.demoService.isDemo()) {
      this.loadingService.addToLoading(this.parentDevice.id + property.id.toString());
      if (this.propIsHvacControl) {
        this.hvacDemoModeOnChangeSetup(property);
      } else if (this.propIsBlinds) {
        this.blindsDemoModeOnChangeSetup(property);
      }
    } else {
      this.loadingService.addToLoading(this.parentDevice.id + property.id.toString());
      if (this.pinService.pinIsSet() && property.security.requirePinToActivate) {
        if (property.type >= 6500 && property.type <= 6549 ) {
          this.apiProjectService.changeProperty(this.parentDevice.id, property.type, '0', this.pinService.getPin()).subscribe( (response) => {
          });
        }
        else if (property.type >= 6550 && property.type <= 6599 ) {
          this.apiProjectService.changeProperty(this.parentDevice.id, property.type, '2', this.pinService.getPin()).subscribe( (response) => {
          });
        } else {
          this.apiProjectService.changeProperty(this.parentDevice.id, property.type, '1', this.pinService.getPin()).subscribe( (response) => {
          });
        }
      } else {
        if (property.type >= 6500 && property.type <= 6549 ) {
          this.apiProjectService.changeProperty(this.parentDevice.id, property.type, '0').subscribe( (response) => {
          });
        }
        else if (property.type >= 6550 && property.type <= 6599 ) {
          this.apiProjectService.changeProperty(this.parentDevice.id, property.type, '2').subscribe( (response) => {
          });
        } else {
          this.apiProjectService.changeProperty(this.parentDevice.id, property.type, '1').subscribe();
        }
      }
    }
  }

  hvacDemoModeOnChangeSetup(property: Property) {
    let hvacOff: Property, hvacLow: Property, hvacAuto: Property, hvacStatus: Property, fanCoilV1: Property, fanCoilV2: Property, fanCoilV3: Property;
    this.parentDevice.equipmentProperties.forEach( element => {
      if (Property.isHvacOff(element)) { hvacOff = element; }
      if (Property.isHvacLow(element)) { hvacLow = element; }
      if (Property.isHvacAuto(element)) { hvacAuto = element; }
      if (Property.isHvacStatus(element)) { hvacStatus = element; }
      if (Property.isFanCoilV1(element)) { fanCoilV1 = element; }
      if (Property.isFanCoilV2(element)) { fanCoilV2 = element; }
      if (Property.isFanCoilV3(element)) { fanCoilV3 = element; }
    });
    if (Property.isHvacOff(property)) {
      this.projectService.changePropertyValueForDemo(this.parentDevice.id, hvacOff.type, 1);
      this.projectService.changePropertyValueForDemo(this.parentDevice.id, hvacLow.type, 0);
      this.projectService.changePropertyValueForDemo(this.parentDevice.id, hvacAuto.type, 0);
      this.projectService.changePropertyValueForDemo(this.parentDevice.id, hvacStatus.type, 0);
      this.projectService.changePropertyValueForDemo(this.parentDevice.id, fanCoilV1.type, 0);
      this.projectService.changePropertyValueForDemo(this.parentDevice.id, fanCoilV2.type, 0);
      this.projectService.changePropertyValueForDemo(this.parentDevice.id, fanCoilV3.type, 0);
    }else if (Property.isHvacLow(property)) {
      this.projectService.changePropertyValueForDemo(this.parentDevice.id, hvacOff.type, 0);
      this.projectService.changePropertyValueForDemo(this.parentDevice.id, hvacLow.type, 1);
      this.projectService.changePropertyValueForDemo(this.parentDevice.id, hvacAuto.type, 0);
      this.projectService.changePropertyValueForDemo(this.parentDevice.id, hvacStatus.type, 1);
      this.projectService.changePropertyValueForDemo(this.parentDevice.id, fanCoilV1.type, 1);
      this.projectService.changePropertyValueForDemo(this.parentDevice.id, fanCoilV2.type, 0);
      this.projectService.changePropertyValueForDemo(this.parentDevice.id, fanCoilV3.type, 0);
    }else if (Property.isHvacAuto(property)) {
      this.projectService.changePropertyValueForDemo(this.parentDevice.id, hvacOff.type, 0);
      this.projectService.changePropertyValueForDemo(this.parentDevice.id, hvacLow.type, 0);
      this.projectService.changePropertyValueForDemo(this.parentDevice.id, hvacAuto.type, 1);
      this.projectService.changePropertyValueForDemo(this.parentDevice.id, hvacStatus.type, 1);
      this.projectService.changePropertyValueForDemo(this.parentDevice.id, fanCoilV1.type, 0);
      this.projectService.changePropertyValueForDemo(this.parentDevice.id, fanCoilV2.type, 1);
      this.projectService.changePropertyValueForDemo(this.parentDevice.id, fanCoilV3.type, 0);
    }
    this.loadingService.removeFromLoading(this.parentDevice.id + property.id.toString());
  }

  blindsDemoModeOnChangeSetup(property: Property) {
    const secondBlindProperty = this.parentDevice.equipmentProperties.find(element => {
      if (element.type !== property.type) {
        return true;
      }
    });
    property.value = 1;
    secondBlindProperty.value = 0;
    setTimeout( () => {
      property.value = 0;
      secondBlindProperty.value = 0;
      this.loadingService.removeFromLoading(this.parentDevice.id + property.id.toString());
    }, 2000);
  }

  createNewAction(equipmentProperty: Property) {
    const action: Action = new Action();
    action.id = `tempActionId1`; // = this.automationThenId + '|' + this.selectedAction.id; aa0001|ca0001",
    action.name = `tempActionName1`;
    action.command = 'EquipmentActions.Activate';
    action.description = 'Action for activating equipment property';
    if (equipmentProperty.type >= 6500 && equipmentProperty.type <= 6549) {
      action.parameters = [`${this.parentDevice.id}/${equipmentProperty.type}`, 0];
    } else if (equipmentProperty.type >= 6550 && equipmentProperty.type <= 6599) {
      action.parameters = [`${this.parentDevice.id}/${equipmentProperty.type}`, 2];
    } else {
      action.parameters = [`${this.parentDevice.id}/${equipmentProperty.type}`, 1];
    }
    action.builtIn = false;
    return action;
  }

  handleActionChange(property: Property) {
    const action = this.createNewAction(property);
    this.actionChanged$.next(action);
  }

  getActionPropertyParameter1(property: Property) {
    return `${this.parentDevice.id}/${property.type}`;
  }

  getConditionPropertyParameter1(equipmentProperty: Property) {
    return `ValRef:${this.parentDevice.id}/${equipmentProperty.type}`;
  }



  specialPropClicked(property: Property) {
    if (property.security.requirePinToActivate && !this.pinService.pinIsSet()) {
      this.openSpecialPinModal(property);
    } else {
      this.handleLiveChange(property);
    }
  }


  async openSpecialPinModal(property: Property) {
    const modal = await this.modalController.create({
      component: ModalPinComponent,
      cssClass: 'pin-modal-container',
      backdropDismiss: true,
      showBackdrop: true
    });
    modal.onDidDismiss()
    .then((resp) => {
      if (resp?.data?.choice) {
        this.pinService.setPin(resp?.data?.pin).subscribe( response => {
          if (response === true) {
            this.handleLiveChange(property);
          } else {
            this.activeProperty = this.propertyArray.find( prop => {
              if (+prop.value === 1) {
                return true;
              }
            });
          }
        });
      } else {
        this.activeProperty = this.propertyArray.find( prop => {
          if (+prop.value === 1) {
            return true;
          }
        });
      }
  });
    return await modal.present();
  }



  propertyClicked() {
    if (this.activeProperty.security.requirePinToActivate && !this.pinService.pinIsSet()) {
      this.openPinModal();
      }
     else {
      this.openEquipmentBinaryMutexLiveModal();
    }
  }

  async openPinModal() {
    const modal = await this.modalController.create({
      component: ModalPinComponent,
      cssClass: 'pin-modal-container',
      backdropDismiss: true,
      showBackdrop: true
    });
    modal.onDidDismiss()
    .then((resp) => {
      if (resp?.data?.choice) {
        this.pinService.setPin(resp?.data?.pin).subscribe( response => {
          if (response === true) {
            this.openEquipmentBinaryMutexLiveModal();
          }
        });
      }
  });
    return await modal.present();
  }

  async openEquipmentBinaryMutexLiveModal() {
    const modal = await this.modalController.create({
      component: EquipmentBinaryMutexLiveComponent,
      cssClass: 'equipment-property-modal',
      backdropDismiss: true,
      showBackdrop: true,
      componentProps: {
        title: this.title,
        propertyArray: this.propertyArray,
        parentDevice: this.parentDevice,
      }
    });
    return await modal.present();
  }


  ngOnDestroy(): void {
    if (this.projectSubscription) {
      this.projectSubscription.unsubscribe();
    }
    if (this.loadingSubscription) {
      this.loadingSubscription.unsubscribe();
    }
    if (this.primaryPropertySubscription) {
      this.primaryPropertySubscription.unsubscribe();
    }
  }
}
