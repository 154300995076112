import { AfterContentChecked, ChangeDetectorRef, Component, ComponentFactoryResolver, Input, OnDestroy, OnInit, ViewChild, ViewContainerRef } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Platform } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { EquipmentPropertyComponentToLoad } from 'src/app/core/models/equipment-property-component-to-load.model';
import { Device } from 'src/app/core/models/project/devices/device.model';
import { Project } from 'src/app/core/models/project/project.model';
import { Property } from 'src/app/core/models/project/property.model';
import { Room } from 'src/app/core/models/project/room.model';
import { DemoService } from 'src/app/core/services/demo.service';
import { ApiProjectService } from 'src/app/modules/project/services/http/api-project.service';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { ApiRoomsService } from 'src/app/modules/rooms/services/http/api-rooms.service';
import { RoomViewService } from 'src/app/modules/rooms/services/room-view.service';
import { EquipmentComponentGenService } from '../../services/equipment-component-gen.service';
import { EquipmentPropertyTypesService } from '../../services/equipment-property-types.service';
import { PinService } from '../../services/pin.service';
import { EquipmentBinaryMutexComponent } from '../equipment/equipment-binary-mutex/equipment-binary-mutex.component';
import { GenComTranslatePipe } from '../../pipes/gen-com-translate.pipe';
import { DateTimeFromToComponent } from 'src/app/modules/logs/components/date-time-from-to/date-time-from-to.component';
import { Graphs } from 'src/app/core/models/graph.model';

@Component({
  selector: 'app-user-setup-device',
  templateUrl: './user-setup-device.component.html',
  styleUrls: ['./user-setup-device.component.scss']
})
export class UserSetupDeviceComponent implements OnInit, OnDestroy, AfterContentChecked {
  @ViewChild('dateTimeFromTo') dateTimeFromTo: DateTimeFromToComponent;

  @ViewChild('equipmentPropertyContainer', { read: ViewContainerRef }) equipmentPropertyContainer;
  // hvac specific containers
  @ViewChild('roomEquipmentPropertyContainer', { read: ViewContainerRef }) roomEquipmentPropertyContainer;
  @ViewChild('bathroomEquipmentPropertyContainer', { read: ViewContainerRef }) bathroomEquipmentPropertyContainer;
  @ViewChild('roomTempPropertyContainer', { read: ViewContainerRef }) roomTempPropertyContainer;
  @ViewChild('bathroomTempPropertyContainer', { read: ViewContainerRef }) bathroomTempPropertyContainer;
  @ViewChild('hvacControlPropertyContainer', { read: ViewContainerRef }) hvacControlPropertyContainer;

  demo: boolean;
  deviceId: string;
  room: Room;
  activeRoomId: string;
  deviceInSetup: Device;
  project: Project;
  bathroomName = 'Bathroom';
  bathroomPropertyExists = false;
  roomHvacPropertyExists = false;

  projectSubscription: Subscription;
  equipmentSubscription: Subscription;
  demoSubscription: Subscription;
  componentsSubscription: Subscription;
  equipmentProperties: Property[];

  hvacLocation: 'room' | 'bathroom' = 'room';
  deviceIsHvac = false;

  showGraph = false;
  graphCreated = false;
  graphLoading = false;
  multi: any[];
  graphTempRoomSetName: string;
  graphTempRoomCurrentName: string;

  // options
  legend = true;
  showLabels = true;
  animations = true;
  xAxisLabel = 'Time';
  yAxisLabel = 'Values';

  colorScheme = {
    domain: ['#6BA0C4', '#03143A']
  };

  defaultTime: Date[] = [];

  backButtonSubscription: Subscription;

  componentsArray: Array<any> = [];

  hideCustomTime = true;
  body: Graphs = new Graphs;

  constructor(
    private equipmentPropertyTypesService: EquipmentPropertyTypesService,
    private equipmentComponentGen: EquipmentComponentGenService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private changeDetectorRef: ChangeDetectorRef,
    private projectService: ProjectService,
    private apiProjectService: ApiProjectService,
    private route: ActivatedRoute,
    private pinService: PinService,
    private platform: Platform,
    private demoService: DemoService,
    private roomViewService: RoomViewService,
    private router: Router,
    private apiRoomsService: ApiRoomsService,
    private pipe: GenComTranslatePipe ) {
      // when routing from analytics / temperature the router sends state hvacview 'bathroom' if clicked on bathroom prop
      if (this.router?.getCurrentNavigation()?.extras?.state?.hvacView === 'bathroom') {
        this.hvacLocation = 'bathroom';
      }
    }

  ngOnInit(): void {
    this.backButtonSubscription = this.platform.backButton.subscribeWithPriority(10, () => {
      this.goBack();
    });
    this.deviceId = this.route.snapshot.paramMap.get('deviceId');
    if (this.deviceId.split('-')[0] === 'HV' ) {
      this.deviceIsHvac = true;
    }
    this.activeRoomId = this.route.snapshot.paramMap.get('id');
    this.getProject().then( () => {
      this.deviceInSetup = this.projectService.findDeviceById(this.deviceId);
      this.room = this.project.listOfRooms.find( room => room.id === this.activeRoomId);
      this.equipmentProperties = this.initEquipmentPropertyArray(this.deviceInSetup?.equipmentProperties);
      this.loadEquipmentPropertyComponent(this.equipmentProperties);
      if (this.bathroomPropertyExists === true && this.roomHvacPropertyExists === false) {
        this.hvacLocation = 'bathroom';
      }
      this.updateEquipmentProperties();
      this.changeDetectorRef.detectChanges(); // trigers change detection to render #equipmentPropertyContainer

      if (this.deviceIsHvac && !this.demoService.isDemo()) {
        this.getTodayAndWeekBefore();
        this.deviceInSetup.equipmentProperties.find( prop => {
          if (Property.isRoomTemperatureCurrent(prop)) {
            this.graphTempRoomCurrentName = prop.name.replace(/\s/g, '');
            return true;
          }
        });
        this.deviceInSetup.equipmentProperties.find( prop => {
          if (Property.isRoomTemperatureSet(prop)) {
            this.graphTempRoomSetName = prop.name.replace(/\s/g, '');
            return true;
          }
        });
      }
    });

    this.demoSubscription = this.demoService.getDemo$().subscribe((demoStatus: boolean) => {
      this.demo = demoStatus;
    });

    this.componentsSubscription = this.projectService.componentChangeCheck.subscribe( value => {
      this.updateGeneratedComponentByMqtt(value.found);
    });

    // translate
    this.xAxisLabel = this.pipe.transform(this.xAxisLabel);
    this.projectService.projectChanged.next(this.project);
  }

  ngAfterContentChecked() {
    this.changeDetectorRef.detectChanges();
  }


  getProject() {
    const promise = new Promise<void>( resolve => {
      if (this.project === undefined) {
        this.project = this.projectService.getProject();
        this.projectSubscription = this.projectService.projectChanged.subscribe(() => {
          setTimeout( () => {
            this.project = this.projectService.getProject();
            if (this.deviceInSetup) {
              this.deviceInSetup.equipmentProperties.forEach( prop => {
                this.updateGeneratedComponentByMqtt(prop);
              })
            }
          }, 1500)
          resolve();
        });
      } else {
        resolve();
      }
    });
    return promise;
  }

  updateEquipmentProperties() {
    this.equipmentSubscription = this.projectService.projectChanged.subscribe( () => {
      this.deviceInSetup = this.projectService.findDeviceById(this.deviceId);
      this.equipmentProperties = this.initEquipmentPropertyArray(this.deviceInSetup.equipmentProperties);
    });
  }

  updateGeneratedComponentByMqtt(found: Property) {
    this.componentsArray.find( element => {
      if (element.equipmentProperty) {
        if (element.equipmentProperty.id === found.id && element.equipmentProperty.inComm.address === found.inComm.address) {
          element.equipmentProperty.value = found.value;
          return true;
        }
      } else if (element.propertyArray) {
        element.propertyArray.forEach( prop => {
          if (prop.id === found.id && prop.inComm.address === found.inComm.address) {
            prop.value = found.value;
            return true;
          }
        });
        this.projectService.mutexComponentPrimaryPropChange.next(true);
      }
    });
  }

  initEquipmentPropertyArray(deviceEquipProperties: Property[]) {
    let tempBlinds: Property;
    let tempHvacMode: Property;
    const equipmentProperties = deviceEquipProperties?.filter( prop => {
      if (this.equipmentPropertyTypesService.getEquipmentPropertyType(prop.type)) { // returns true if prop of that type is in admin-settings.json
        if (Object.keys(this.equipmentComponentGen.getTextualRepresentationOfValue(prop)).length !== 0) {
          prop.data = this.equipmentComponentGen.getTextualRepresentationOfValue(prop);
        }
        if (Property.isBlindsMutex(prop)) {
          if (!tempBlinds) {
            tempBlinds = prop;
          }
          if (+prop.value === 1) {
            tempBlinds = prop;
        }
          return false;
        }
        if (Property.isHvacMutex(prop)) {
          if (!tempHvacMode) {
            tempHvacMode = prop;
          }
          if (+prop.value === 1) {
            tempHvacMode = prop;
        }
          return false;
        }
        return true;
      }
    });
    if (tempBlinds) {
      equipmentProperties.push(tempBlinds);
    }
    if (tempHvacMode) {
      equipmentProperties.push(tempHvacMode);
    }
    return equipmentProperties;
  }

  equipmentPropertyFilterForStatus(device: Device) {
    let tempFloorConvectorSpeed: Property;
    let tempFanSpeed: Property;
    let tempHvacMode: Property;
    let tempBlinds: Property;
    let equipmentProperties = device?.equipmentProperties.filter( prop => {
      if (this.equipmentPropertyTypesService.getEquipmentPropertyTypeForStatus(prop.type)) {
        if (Object.keys(this.equipmentComponentGen.getTextualRepresentationOfValue(prop)).length !== 0) {
          prop.data = this.equipmentComponentGen.getTextualRepresentationOfValue(prop);
        }
        if (Property.isFloorConvectorSpeedMutex(prop)) {
          if (!tempFloorConvectorSpeed) {
            tempFloorConvectorSpeed = prop;
          }
          if (+prop.value === 1) {
            tempFloorConvectorSpeed = prop;
        }
          return false;
        }
        if (Property.isFanSpeedMutex(prop)) {
            if (!tempFanSpeed) {
              tempFanSpeed = prop;
            }
            if (+prop.value === 1) {
            tempFanSpeed = prop;
          }
            return false;
        }
        if (Property.isHvacMutex(prop)) {
          if (!tempHvacMode) {
            tempHvacMode = prop;
          }
          if (+prop.value === 1) {
            tempHvacMode = prop;
        }
          return false;
      }
        if (Property.isBlindsMutex(prop)) {
          if (!tempBlinds) {
            tempBlinds = prop;
          }
          if (+prop.value === 1) {
            tempBlinds = prop;
        }
          return false;
      }
        return true;
      }
    });
    if (tempHvacMode) {
      equipmentProperties.push(tempHvacMode);
    }
    if (tempFanSpeed) {
      equipmentProperties.unshift(tempFanSpeed);
    }
    if (tempBlinds) {
      equipmentProperties.push(tempBlinds);
    }
    if (tempFloorConvectorSpeed) {
      equipmentProperties.push(tempFloorConvectorSpeed);
    }
    // filter prop that is already shown
    equipmentProperties = equipmentProperties?.filter( element => {
      return !this.equipmentProperties.some( prop => {
        if (element.id === prop.id) {
          return true;
        }
      });
    });
    // filter prop for hvac
    if (this.deviceIsHvac) {
      equipmentProperties = equipmentProperties?.filter( element => {
        if (element.data.location === this.hvacLocation) {
          return true;
        }
      });
    }
    // aditional filter for demo mode
    if (this.demoService.isDemo()) {
      equipmentProperties = equipmentProperties?.filter( element => {
        if (!Property.isHvacOff(element) && !Property.isHvacLow(element) && !Property.isHvacAuto(element)) {
          return true;
        }
      });
    }
    return equipmentProperties;
  }


  loadEquipmentPropertyComponent(equipmentProperties: Property[]) {
    equipmentProperties.forEach( equipProp => {
      if (Property.isBlindsMutex(equipProp)){
        const viewContainerRef: ViewContainerRef = this.equipmentPropertyContainer;
        const mutexComponentFactory = this.componentFactoryResolver.resolveComponentFactory(EquipmentBinaryMutexComponent);
        const mutexComponentRef = viewContainerRef.createComponent(mutexComponentFactory);

        const generatedComponentInstance = (mutexComponentRef.instance as EquipmentBinaryMutexComponent);

        if (Property.isBlindsMutex(equipProp)){
          const blindsPropertyArray = this.deviceInSetup.equipmentProperties.filter((prop) => {
            return Property.isBlindsMutex(prop);
           });
          generatedComponentInstance.propertyArray = blindsPropertyArray;
          generatedComponentInstance.title = 'Blinds';
          generatedComponentInstance.title = this.pipe.transform(generatedComponentInstance.title);
        }
        generatedComponentInstance.parentDevice = this.deviceInSetup;
        generatedComponentInstance.mode = 'live';
        this.componentsArray.push(mutexComponentRef.instance);
      } else if (Property.isRoomTemperatureSet(equipProp)) {
        const componentToLoad: EquipmentPropertyComponentToLoad = this.equipmentComponentGen.getComponent(equipProp, this.deviceInSetup);
        const viewContainerRef: ViewContainerRef = this.roomTempPropertyContainer;
        const {component, parentDevice, equipmentProperty, equipmentPropertyType, data } = componentToLoad;
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
        const componentRef = viewContainerRef.createComponent(componentFactory);
        const generatedComponentInstance = (componentRef.instance as EquipmentPropertyComponentToLoad);
        generatedComponentInstance.parentDevice = parentDevice;
        generatedComponentInstance.equipmentProperty = equipmentProperty;
        generatedComponentInstance.equipmentPropertyType = equipmentPropertyType;
        generatedComponentInstance.data = data;
        generatedComponentInstance.mode = 'live';
        this.roomHvacPropertyExists = true;
        this.componentsArray.push(componentRef.instance);
      } else if (Property.isBathroomTemperatureSet(equipProp)) {
        const componentToLoad: EquipmentPropertyComponentToLoad = this.equipmentComponentGen.getComponent(equipProp, this.deviceInSetup);
        const viewContainerRef: ViewContainerRef = this.bathroomTempPropertyContainer;
        const {component, parentDevice, equipmentProperty, equipmentPropertyType, data } = componentToLoad;
        const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
        const componentRef = viewContainerRef.createComponent(componentFactory);
        const generatedComponentInstance = (componentRef.instance as EquipmentPropertyComponentToLoad);
        generatedComponentInstance.parentDevice = parentDevice;
        generatedComponentInstance.equipmentProperty = equipmentProperty;
        generatedComponentInstance.equipmentPropertyType = equipmentPropertyType;
        generatedComponentInstance.data = data;
        generatedComponentInstance.mode = 'live';
        this.bathroomPropertyExists = true;
        if (this.room.bathroomTitle !== null && this.room.bathroomTitle !== '' && this.room.bathroomTitle !== undefined) {
          this.bathroomName = this.room.bathroomTitle;
        }
        this.componentsArray.push(componentRef.instance);
      } else {
        if (equipProp.data.location === 'room') {
          if (Property.isHvacMutex(equipProp)) {
            const viewContainerRef: ViewContainerRef = this.hvacControlPropertyContainer;
            const mutexComponentFactory = this.componentFactoryResolver.resolveComponentFactory(EquipmentBinaryMutexComponent);
            const mutexComponentRef = viewContainerRef.createComponent(mutexComponentFactory);

            const generatedComponentInstance = (mutexComponentRef.instance as EquipmentBinaryMutexComponent);
            const hvacControlPropertyArray = this.deviceInSetup.equipmentProperties.filter((prop) => {
              return Property.isHvacMutex(prop);
              });

            generatedComponentInstance.propertyArray = hvacControlPropertyArray;
            generatedComponentInstance.title = 'Power';
            generatedComponentInstance.title = this.pipe.transform(generatedComponentInstance.title);
            generatedComponentInstance.parentDevice = this.deviceInSetup;
            generatedComponentInstance.mode = 'live';
            this.roomHvacPropertyExists = true;
            this.componentsArray.push(mutexComponentRef.instance);
          } else {
            const componentToLoad: EquipmentPropertyComponentToLoad = this.equipmentComponentGen.getComponent(equipProp, this.deviceInSetup);
            const viewContainerRef: ViewContainerRef = this.roomEquipmentPropertyContainer;
            const {component, parentDevice, equipmentProperty, equipmentPropertyType, data } = componentToLoad;
            const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
            const componentRef = viewContainerRef.createComponent(componentFactory);
            const generatedComponentInstance = (componentRef.instance as EquipmentPropertyComponentToLoad);
            generatedComponentInstance.parentDevice = parentDevice;
            generatedComponentInstance.equipmentProperty = equipmentProperty;
            generatedComponentInstance.equipmentPropertyType = equipmentPropertyType;
            generatedComponentInstance.data = data;
            generatedComponentInstance.mode = 'live';
            this.componentsArray.push(componentRef.instance);
          }
        } else if (equipProp.data.location === 'bathroom') {
          const componentToLoad: EquipmentPropertyComponentToLoad = this.equipmentComponentGen.getComponent(equipProp, this.deviceInSetup);
          const viewContainerRef: ViewContainerRef = this.bathroomEquipmentPropertyContainer;
          const {component, parentDevice, equipmentProperty, equipmentPropertyType, data } = componentToLoad;
          const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
          const componentRef = viewContainerRef.createComponent(componentFactory);
          const generatedComponentInstance = (componentRef.instance as EquipmentPropertyComponentToLoad);
          generatedComponentInstance.parentDevice = parentDevice;
          generatedComponentInstance.equipmentProperty = equipmentProperty;
          generatedComponentInstance.equipmentPropertyType = equipmentPropertyType;
          generatedComponentInstance.data = data;
          generatedComponentInstance.mode = 'live';
          this.bathroomPropertyExists = true;
          this.componentsArray.push(componentRef.instance);
        } else {
          const componentToLoad: EquipmentPropertyComponentToLoad = this.equipmentComponentGen.getComponent(equipProp, this.deviceInSetup);
          const viewContainerRef: ViewContainerRef = this.equipmentPropertyContainer;
          const {component, parentDevice, equipmentProperty, equipmentPropertyType, data } = componentToLoad;
          const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
          const componentRef = viewContainerRef.createComponent(componentFactory);
          const generatedComponentInstance = (componentRef.instance as EquipmentPropertyComponentToLoad);
          generatedComponentInstance.parentDevice = parentDevice;
          generatedComponentInstance.equipmentProperty = equipmentProperty;
          generatedComponentInstance.equipmentPropertyType = equipmentPropertyType;
          generatedComponentInstance.data = data;
          generatedComponentInstance.mode = 'live';
          this.componentsArray.push(componentRef.instance);
        }
      }
    });
  }

  getTextualValue(prop: Property) {
    if (prop.data?.nameForFalse || prop.data?.nameForTrue) {
      if (+prop.value === 0) {
        return prop.data.nameForFalse;
      } else if (+prop.value === 1) {
        return prop.data.nameForTrue;
      }
    } else {
      return prop.value + prop.data?.unitOfMeasurement;
    }
  }

  getIcon(prop: Property) {
    if (prop.data.iconTrue && prop.data.iconFalse) {
      if (+prop.value === 1) {
        return prop.data.iconTrue;
      } else {
        return prop.data.iconFalse;
      }
    } else {
      return prop.data.icon;
    }
  }

  hvacLocationChanged(location: 'room' | 'bathroom') {
    this.hvacLocation = location;
  }

  getTodayAndWeekBefore() {
    const today = new Date();
    const weekBefore = new Date(Date.now() - 7 * 24 * 60 * 60 * 1000);
    this.defaultTime[0] = weekBefore;
    this.defaultTime[1] = today;
  }

   createGraph() {
    const tempCurrent = this.deviceInSetup.equipmentProperties.find(prop => Property.isRoomTemperatureCurrent(prop))
    const tempSet = this.deviceInSetup.equipmentProperties.find(prop => Property.isRoomTemperatureSet(prop))
    this.body = {
      roomIds: [this.room.id],
      properties: [tempCurrent.type.toString(), tempSet.type.toString()],
      dateTimeFrom: this.defaultTime[0].toISOString(),
      dateTimeTo: this.defaultTime[1].toISOString()
    };
    this.graphCreated = false;
    this.graphLoading = true;
    this.apiProjectService.getGraph(this.body).subscribe( response => {
      if (response.length > 0) {
        this.multi = this.createDataFromResponse(response);
        this.graphCreated = true;
        setTimeout( () =>  {
          const element = document.getElementById('graphTarget');
          element.scrollIntoView({ behavior: 'smooth'});
        }, 200);
      } else {
        this.graphCreated = false;
      }
      this.graphLoading = false;
    });
  }

  createDataFromResponse(resp: any) {
    let results = [];
    this.body.properties.forEach( prop => {
      results.push({name: this.getPropertyName(prop), series: []})
    })

    resp.forEach( data => {
      const target = results.find( element => {
       if (element.name === this.getPropertyName(data.propertyType)) { return true;}
      })
      target.series.push({name: new Date(data.timestamp), value: data.value})
    });

    return results;
  }

  getPropertyName(propType: string) {
    const target = this.deviceInSetup.equipmentProperties.find( (prop: Property) => {
      if (+propType == prop.type) { return true}
    })

    if (propType) {
      if (target) {
        return target.name;
      } else {
        return 'Unknown property';
      }
    } else {
      return null;
    }
  }

  setDateTimeFromTo(selectedDateTime: Date[]) {
    if (!(selectedDateTime[0] === undefined || selectedDateTime[0] === null) && !(selectedDateTime[1] === undefined || selectedDateTime[1] === null)) {

      this.body.dateTimeFrom = selectedDateTime[0].toISOString()
      this.body.dateTimeTo = selectedDateTime[1].toISOString()
      this.graphLoading = true;
      this.graphCreated = false;
      this.apiProjectService.getGraph(this.body).subscribe( response => {
        if (response.length > 0) {
          this.multi = this.createDataFromResponse(response);
          this.graphCreated = true;
        } else {
          this.graphCreated = false;
        }
        this.graphLoading = false;
      });
    }
  }

  graphShowClicked() {
    if (!this.graphCreated) {
      this.createGraph();
    }
    if (this.showGraph) {
      this.showGraph = false;
    } else {
      this.showGraph = true;
      setTimeout( () =>  {
        const element = document.getElementById('graphTarget');
        element.scrollIntoView({ behavior: 'smooth'});
      }, 200);
    }
  }

  filterDeviceWithoutAdminProp(devices: Device[]) {
    devices = devices.filter( device => {
      let found = false;
      device.equipmentProperties.forEach( prop => {
        if (this.equipmentPropertyTypesService.getEquipmentPropertyType(prop.type) || this.equipmentPropertyTypesService.getEquipmentPropertyTypeForStatus(prop.type)) {
          found = true;
        }
      });
      return found;
    });
    return devices;
  }

  deviceHasOnlyStatusProps(device: Device): boolean {
    if (device.equipmentProperties.length === 1
      && this.equipmentPropertyTypesService.getEquipmentPropertyTypeForStatus(device.equipmentProperties[0].type)){
        return true;
    } else {
      return false;
    }
  }

  getAllDevicesInRoom() {
    let allDevicesInRoom = [];
    allDevicesInRoom.push(...this.projectService.getLightsForRoom(this.activeRoomId));
    allDevicesInRoom.push(...this.projectService.getHvacForRoom(this.activeRoomId));
    allDevicesInRoom.push(...this.projectService.getBlindsForRoom(this.activeRoomId));
    allDevicesInRoom.push(...this.projectService.getGeneralEquipmentForRoom(this.activeRoomId));
    allDevicesInRoom.push(...this.projectService.getSafetyEquipmentForRoom(this.activeRoomId));
    allDevicesInRoom.push(...this.projectService.getSecurityEquipmentForRoom(this.activeRoomId));

    allDevicesInRoom = this.filterDeviceWithoutAdminProp(allDevicesInRoom);
    return allDevicesInRoom;
  }

  goBack() {
    this.roomViewService.getDeviceRouteOrigin().pipe(take(1)).subscribe((deviceRouteOrigin) => {
      if (deviceRouteOrigin === 'rooms') {
        const devices = this.getAllDevicesInRoom();
        if (devices.length === 1 && !this.deviceHasOnlyStatusProps(devices[0])) {
          this.apiRoomsService.setRoomId(this.room.id);
          this.router.navigate([`/rooms`]);
        } else {
          this.router.navigate([`/rooms/${this.room.id}`]);
        }
      } else if (deviceRouteOrigin === 'home'){
        this.router.navigate([`/home`]);
      } else if (deviceRouteOrigin === 'activeDevices'){
        this.router.navigate([`/active-devices`]);
      } else if (deviceRouteOrigin === 'temperatures'){
        this.router.navigate([`/analytics/temperatures`]);
      } else {
        this.router.navigate([`/rooms`]);
      }
    });
  }

  showLast24hours() {
    this.dateTimeFromTo.showLast24hours();
  }

  showLast7days() {
    this.dateTimeFromTo.showLast7days();
  }

  showLast30days() {
    this.dateTimeFromTo.showLast30days();
  }

  showCustom() {
    if (this.hideCustomTime === true) {
      this.hideCustomTime = false;
    } else {
      this.hideCustomTime = true;
      this.dateTimeFromTo.showLast7days();
    }

  }

  propIsFanSpeedMutex(prop: Property) {
    return Property.isFanSpeedMutex(prop);
  }

  ngOnDestroy(): void {
    if (this.projectSubscription) {
      this.projectSubscription.unsubscribe();
    }
    if (this.componentsSubscription) {
      this.componentsSubscription.unsubscribe();
    }
    if (this.equipmentSubscription) {
      this.equipmentSubscription.unsubscribe();
    }
    if (this.backButtonSubscription) {
      this.backButtonSubscription.unsubscribe();
    }
    if (this.demoSubscription) {
      this.demoSubscription.unsubscribe();
    }
    this.pinService.removePin();
  }
}
