import { trigger, transition, style, animate, query, animateChild, state } from '@angular/animations';

export const enterAnimation = trigger(
  'enterAnimation', [
    transition(':enter', [
      style({transform: 'translateY(-10%)', opacity: 0}),
      animate('300ms', style({transform: 'translateY(0)', opacity: 1}))
    ]),
    transition(':leave', [
      style({transform: 'translateY(0)', opacity: 1}),
      animate('300ms', style({transform: 'translateY(-10%)', opacity: 0}))
    ])
  ]
);


export const insertRemoveTrigger = trigger(
  'insertRemoveTrigger', [
    transition(':enter', [
      style({ opacity: 0 }),
      animate('0.5s', style({ opacity: 1 }))
    ]),
    transition(':leave', [
      animate('1s', style({ opacity: 0 }))
    ])
  ]);


export const slideFromBottom = trigger('slideFromBottom', [
  transition(':enter', [
    style({transform: 'translateY(100%)'}),
    animate('200ms ease-in', style({transform: 'translateY(0%)'}))
  ]),
  transition(':leave', [
    style({transform: 'translateY(0%)'}),
    animate('200ms ease-out', style({transform: 'translateY(100%)'}))
  ])
]);


export const backgroundFade = [
  trigger('backgroundFade', [
    transition(':enter', [
      query('@*', animateChild()),

    ]),
    transition(':leave', [
      query('@*', animateChild()),
      style({background: 'rgba(0,0,0,0.5)'}),
      animate('100ms ease-in', style({backgroundColor: 'transparent'}))
    ])
  ]),
];


