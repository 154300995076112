import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';
import { Floor } from 'src/app/core/models/project/floor.model';
import { UserSettingsService } from 'src/app/core/services/user-settings.service';

@Injectable({
  providedIn: 'root'
})
export class RoomViewService {

  // remembers where did the user navigate from when coming to device page.
  private deviceRouteOrigin$ = new BehaviorSubject<'allDevices' | 'rooms' | 'activeDevices' | 'home' | 'temperatures'>('allDevices');
  private roomViewType$ = new BehaviorSubject<'allDevices' | 'rooms'>(null);
  private selectedFloor$ = new BehaviorSubject<Floor>(null);

  constructor(private userSettingsService: UserSettingsService) {
    const roomViewTypeSetting = this.userSettingsService.getRoomViewTypeSetting();
    this.setRoomViewType(roomViewTypeSetting);
   }

  getSelectedFloor() {
    return this.selectedFloor$.asObservable();
  }

  setSelectedFloor(floor: Floor) {
    this.selectedFloor$.next(floor);
  }

  getRoomViewType() {
    return this.roomViewType$.asObservable();
  }

  setRoomViewType(newViewType: 'allDevices' | 'rooms') {
    this.userSettingsService.setRoomViewTypeSetting(newViewType);
    this.roomViewType$.next(newViewType);
  }

  switchRoomViewType() {
    this.roomViewType$.getValue() === 'allDevices' ? this.setRoomViewType('rooms') : this.setRoomViewType('allDevices');
  }

  getDeviceRouteOrigin() {
    return this.deviceRouteOrigin$.asObservable();
  }

  setDeviceRouteOrigin(deviceRouteOrigin: 'allDevices' | 'rooms' | 'activeDevices' | 'home' | 'temperatures') {
    this.deviceRouteOrigin$.next(deviceRouteOrigin);
  }
}
