import { NgModule, APP_INITIALIZER } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import { FormsModule } from '@angular/forms';
import { HttpClientModule, HttpClient  } from '@angular/common/http';
import { MqttModule, IMqttServiceOptions } from 'ngx-mqtt';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { SharedModule } from './shared/shared.module';
import { HomeComponent } from './pages/home/home.component';
import { LoginComponent } from './pages/login/login.component';
import { httpInterceptorProviders } from './core/interceptors';
import { ENVIRONMENT_MQTT_SERVICE_OPTIONS } from 'src/environments/environment';
import { ConfigLoaderService } from './core/app-load/config-loader.service';
import { VersionCheckService } from './core/app-load/version-check.service';
import { ActiveDevicesComponent } from './shared/components/active-devices/active-devices.component';
import { IonicModule } from '@ionic/angular';
import { LandingComponent } from './pages/landing/landing.component';
import { File } from '@ionic-native/file/ngx';
import { NetworkInterface } from '@ionic-native/network-interface/ngx';
import { Network } from '@ionic-native/network/ngx';

import { TranslateModule, TranslateLoader } from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
// import { CardReaderService } from 'src/app/shared/services/card-reader.service';
import { NetworkErrorComponent } from './pages/network-error/network-error.component'

export const MQTT_SERVICE_OPTIONS: IMqttServiceOptions = ENVIRONMENT_MQTT_SERVICE_OPTIONS;

export function createTranslateLoader(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  declarations: [
    AppComponent,
    HomeComponent,
    LoginComponent,
    ActiveDevicesComponent,
    LandingComponent,
    NetworkErrorComponent
  ],
  imports: [
    BrowserModule,
    BrowserAnimationsModule,
    AppRoutingModule,
    HttpClientModule,
    FormsModule,
    SharedModule,
    MqttModule.forRoot(MQTT_SERVICE_OPTIONS),
    IonicModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: (createTranslateLoader),
        deps: [HttpClient]
      }
    })
  ],
  providers: [
    File,
    NetworkInterface,
    Network,
    {
      provide: APP_INITIALIZER,
      useFactory: (configService: ConfigLoaderService) => () => configService.configStartup(),
      // all operations under loadConfiguration() must be promise based. Convert all subscriptions using lastValueFrom();
      deps: [ConfigLoaderService],
      multi: true
    },
    {
      provide: APP_INITIALIZER,
      useFactory: (versionCheckService: VersionCheckService) => () => versionCheckService.initVersionCheck(),
      deps: [VersionCheckService],
      multi: true
    },
    //  {
    //   provide: APP_INITIALIZER,
    //   useFactory: (cardReaderService: CardReaderService) => () => cardReaderService.initialize(),
    //   deps: [CardReaderService],
    //   multi: true
    // },
    httpInterceptorProviders
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
