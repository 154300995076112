<div class="flex-column flex-center">
  <div class="label">
    {{ title }}:
  </div>
  <div>
    <div
    *ngFor="let property of propertyArray; let i = index"
    class="flex-row single-property "
    >
      <input
        type="radio"
        class="option-input-radio radio"
        [name]="parentDevice.name"
        [value]="'1'"
        [checked]="property.value == 1"
        (change)="handleLiveChange(property)"
      />
    <div class="margin-top5">{{ property.name }}</div>
  </div>
</div>
</div>
