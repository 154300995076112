import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { API_BASE_URL } from 'src/environments/environment';
import { USE_DALI } from 'src/environments/environment';
import { DaliProject } from 'src/app/core/models/project/dali-project.model';
import { tap } from 'rxjs/operators';
import { DaliProjectService } from '../dali-project.service';
import { LoadingService } from 'src/app/shared/services/loading.service';
import { DemoService } from 'src/app/core/services/demo.service';
import { AsyncSubject } from 'rxjs/internal/AsyncSubject';
// import { Light } from 'src/app/core/models/project/devices/light.model';


@Injectable({
  providedIn: 'root'
})
export class ApiDaliProjectService {

  daliProjectReady$ =  new AsyncSubject<boolean>();

  constructor(
    private http: HttpClient,
    private daliProjectService: DaliProjectService,
    private loadingService: LoadingService,
    private demoService: DemoService
    ) { }


  isDaliProjectLoaded() {
    return this.daliProjectReady$.asObservable();
    }


  getProject() {
    if (!USE_DALI) {
      this.daliProjectReady$.next(true);
      this.daliProjectReady$.complete();
    }
    if (this.demoService.isDemo()) {
      return this.demoService.getDemoDaliProject().pipe(
          tap( demoProject => {
              if (this.daliProjectService.getProject() === undefined) {
                  this.daliProjectService.setProject(demoProject);
              } else {
                  this.daliProjectService.setProject(this.daliProjectService.getProject());
              }
              this.daliProjectReady$.next(true);
              this.daliProjectReady$.complete();
          })
      );
} else if (USE_DALI && !this.demoService.isDemo()) {
        return this.http.get<DaliProject>(API_BASE_URL + '/dali/project').pipe(
            tap((daliProject: DaliProject) => {
              this.daliProjectService.setProject(daliProject);
              this.daliProjectReady$.next(true);
              this.daliProjectReady$.complete();
            })
        );
      }
    }

  setDaliResolverTrue() {
    this.daliProjectReady$.next(true);
    this.daliProjectReady$.complete();
  }

  setProject(body: DaliProject) {
    if (USE_DALI) {
      return this.http.put(API_BASE_URL + '/dali/project', body).pipe(
        tap(() => {
                this.daliProjectService.setProject(body);
            })
      );
    }
  }

  activateDaliLight(daliLightId: string, value: number) {
    this.loadingService.addToLoading(daliLightId);
    const body = {};
    return this.http.post(`${API_BASE_URL}/dali/light/${daliLightId}?value=${value}`, body);
  }

  activateRgbLight(daliRgbLightId: string, color: string,  valueW: number) {
    this.loadingService.addToLoading(daliRgbLightId);
    const col = `%23${color.substring(1)}`;
    const body = {};
    return this.http.post(`${API_BASE_URL}/dali/rgb/${daliRgbLightId}?value=${col}|${valueW}`, body);
  }

  activateDaliLightsGroup(daliLightGroupId: string, value: number) {
    const body = {};
    return this.http.post(`${API_BASE_URL}/dali/group/${daliLightGroupId}?value=${value}`, body);
  }


  getBaseDaliMqttSubscription() {
    if (USE_DALI) {
      return this.http.get(`${API_BASE_URL}/dali/mqtt/basetopic`, {responseType: 'text'});
    }
  }

  createDaliBlindsFromScenes(blindsUpSceneId: string, blindsDownSceneId: string) {
    const body = [blindsUpSceneId, blindsDownSceneId];
    return this.http.post(`${API_BASE_URL}/dali/scenes/toblinds`, body);
  }

  deleteDaliBlinds(blindId: string) {
    return this.http.delete(`${API_BASE_URL}/dali/blinds/${blindId}`);
  }

  activateDaliBlinds(daliBlindsSceneId: string, value: number) {
    const params = new HttpParams().set('value', value.toString());
    const body = {};
    return this.http.post(`${API_BASE_URL}/dali/blinds/${daliBlindsSceneId}`, body,  { params });
  }


}
