import { Pipe, PipeTransform } from '@angular/core';


@Pipe({
    name: 'textSizeFilterWhitespacePipe'
})
export class TextSizeFilterWhitespacePipe implements PipeTransform {
    transform(value: string, limit = 30, ellipsis = '...') {
        const returnValue: string = value.substr(0, limit) + ellipsis;
        const shortString: string = value.slice(0, 15);
        if (shortString.indexOf(' ') >= 0) {
            return value.length > limit ? returnValue : value;
        } else {
            const newReturnValue = returnValue.slice(0, 15) + ' ' + returnValue.slice(15);
            return value.length > limit ? newReturnValue : value;
        }

    }
}

