import { Action } from '../automation/action.model';
import { AutomationThen } from '../automation/automation-then.model';

export class Mode {
  _id?: string;
  name: string;
  isMutex: boolean;
  mutexGroup: number;
  actions: AutomationThen;
  deactivateActions: AutomationThen;
  editable: boolean;
  isActive: boolean;
  apartmentId: string;




  public static isHeating(mode: Mode): boolean {
    return mode.actions.actions.some( (action: Action) => {
      return action.command === 'ModeActions.HeatingCooling' && Number(action.parameters[0]) === 0;
     });
  }

  public static isCooling(mode: Mode): boolean {
    return mode.actions.actions.some( (action: Action) => {
      return action.command === 'ModeActions.HeatingCooling' && Number(action.parameters[0]) === 1;
     });
  }

  public static isArm(mode: Mode): boolean {
    return mode.actions.actions.some( (action: Action) => {
      return action.command === 'ModeActions.ArmDisarm';
     });
  }





}
