import { Component, EventEmitter, OnInit, Output } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-modal-pin',
  templateUrl: './modal-pin.component.html',
  styleUrls: ['./modal-pin.component.scss']
})
export class ModalPinComponent implements OnInit {

  pin: string;

  constructor(private modalController: ModalController){}

  ngOnInit(): void {
  }

  addNumber(value: string) {
    if (this.pin === undefined) {
      this.pin = '';
    }
    this.pin = this.pin + value;
  }

  removeLastNumber() {
    if (this.pin !== undefined) {
      this.pin = this.pin.slice(0, -1);
    }
  }

  decisionClick(selectedChoice: boolean) {
    this.modalController.dismiss({choice: selectedChoice, pin: this.pin});
  }

}
