import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { CONFIG_PROVIDER_URL } from 'src/environments/environment';
import { Configuration } from 'src/app/core/models/configuration.model';

export interface ConfigProviderOutput {
  _id: string;
  type: string;
  locationId: string;
  configuration: Configuration;
}

@Injectable({
  providedIn: 'root'
})
export class ApiConfigProviderService {

  constructor(private http: HttpClient) {}

  getConfig(configId: string) {
    const params = new HttpParams().set('id', configId);
    return this.http.get<ConfigProviderOutput>(`${CONFIG_PROVIDER_URL}/api/configprovider`, {params});
  }

}
