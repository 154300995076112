import { HttpClient, HttpParams } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { DaliScene } from 'src/app/core/models/scenes/dali-scene.model';
import { API_BASE_URL } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class ApiDaliScenesService {

  constructor(private http: HttpClient) { }

  activateDaliScene(daliSceneId: string) {
    const params = new HttpParams().set('value', '1');
    return this.http.post(`${API_BASE_URL}/dali/scene/${daliSceneId}`, {}, { params });
  }

  deactivateDaliScene(daliSceneId: string) {
    const params = new HttpParams().set('value', '0');
    return this.http.post(`${API_BASE_URL}/dali/scene/${daliSceneId}`, {}, { params });
  }

  getDaliScene(daliSceneId: string): Observable<DaliScene> {
    const params = new HttpParams().set('id', daliSceneId);
    return this.http.get<DaliScene>(`${API_BASE_URL}/dali/scenes/byId`, { params });
  }

 updateDaliScene(body) {
  // const params = new HttpParams().set('id', body.id);
  return this.http.put<DaliScene>(`${API_BASE_URL}/dali/scenes/byid?id=${body.id}`, body);
 }
}
