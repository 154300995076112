import { Injectable } from '@angular/core';
import { HttpInterceptor, HttpRequest, HttpHandler } from '@angular/common/http';

import { AuthenticationService } from '../authentication/authentication.service';

@Injectable()
export class AuthInterceptor implements HttpInterceptor {

    constructor(private authService: AuthenticationService) {}

    intercept(request: HttpRequest<any>, next: HttpHandler) {
        const token = this.authService.getAccessToken();
        if (request.url.includes('9986')) {
            // currently we are checking if it is card reader port
            // request for card reader can't include auth header
            return next.handle(request);
        }
        const authRequest = request.clone({ setHeaders: { Authorization: 'Bearer ' + token } });
        return next.handle(authRequest);
    }
}
