  <ng-container *ngIf="liveView; else other">
    <ng-container
    *ngFor="let room of listOfRooms | aptRoomFilter: (apartmentId$ | async)">
    <ng-container *ngIf="roomHasDevicesToShow(room)">
      <div
        class="prop-btn width-full flex-column min-height58"
        (click)="onRoomClicked(room)"
        [ngClass]="{'room-offline': room | checkRoomConnection : commErrorRooms}"
      >
        <div
          class="prop-btn-item-text-center align-self-center"
          [ngClass]="{ 'padding-bottom0': showStatusIcons }"
        >
          {{ room.name }}
        </div>
        <div
          *ngIf="showStatusIcons"
          class="flex-row space-between width-full margin-bottom5"
        >
          <div
            class="flex-row no-wrap margin-left10 align-self-center min-height32"
          >
            <div
              *ngIf="isSecurityAlarmActiveInRoom(room)"
              class="i-security-equipment-alarm-md align-self-center"
            ></div>
            <div
              *ngIf="isSafetyAlarmActiveInRoom(room)"
              class="i-safety-equipment-alarm-md align-self-center"
            ></div>
            <div
              *ngIf="isDoorOpenInRoom(room)"
              class="i-door-open-md-bl align-self-center"
            ></div>
            <div
              *ngIf="isWindowOpenInRoom(room)"
              class="i-window-open-md-bl align-self-center"
            ></div>
            <div
              *ngIf="isHvacActiveInRoom(room)"
              class="i-fan-md-bl align-self-center"
            ></div>
            <div
              *ngIf="isLightActiveInRoom(room)"
              class="i-lightbulb-black-small align-self-center"
            ></div>
            <div
              *ngIf="room | checkRoomConnection : commErrorRooms"
              class="i-triangle-error-md-bl align-self-center"
            ></div>
          </div>
          <ng-container *ngIf="getCurrentTemp(room) as currentTemp">
            <div
              *ngIf="currentTemp !== '?'"
              class="align-self-center black flex-end margin-right10"
            >
              {{ currentTemp }}ºC
            </div>
          </ng-container>
        </div>
      </div>
    </ng-container>
  </ng-container>
  </ng-container>

  <ng-template #other>
    <div class="content-container margin-top10">
      <div class="flex-row-center content-header margin-10">
          <h2>{{'Rooms to use' | translate}}</h2>
          <div
          class="circle-btn i-checkmark active"
          (click)="confirmed()">
        </div>
      </div>
      <div class="flex-row-space-evenly margin-10">
        <div class="flex-row-space-evenly width100 margin-bottom15">
          <ion-button class="width45" (click)="selectAllRooms()" size="small">{{'Select all' | translate}}</ion-button>
          <ion-button class="width45" (click)="clearAllRooms()" size="small">{{'Clear' | translate}}</ion-button>
        </div>
        <div
          *ngFor="let room of listOfRooms | aptRoomFilter: (apartmentId$ | async)"
          [ngClass]="roomIsSelected(room) ? 'room-container-selected' : 'room-container-not-selected'"
          (click)="addOrRemoveRoom(room)"
          class="flex-center">
          {{room.name}}
        </div>
      </div>
     <!--  <div class="flex-row-center">
        <ion-button (click)="confirmed()">{{'Close' | translate}}</ion-button>
      </div> -->
  </div>
  </ng-template>
