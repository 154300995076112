import { Pipe, PipeTransform } from '@angular/core';
import { Scene } from 'src/app/core/models/scenes/scene.model';
import { User } from 'src/app/core/models/user/user.model';

@Pipe({
  name: 'publicScenesFilter'
})
export class PublicScenesFilterPipe implements PipeTransform {
  transform(scenes: any[], user: User): Scene[] {

    if (!scenes) {
      return [];
    }
    if (!user) {
      return scenes;
    }

    return scenes.filter((scene) => {
      const isDaliScene = scene?.id?.split('-')[0] === 'DS';
      if (isDaliScene) {
        return true;
      }
      const sceneNumber: number = Number(scene?._id?.substring(1));
      return (scene.public || (user._id === scene.createdBy || User.isAdmin(user) || User.isOwner(user)))
      && sceneNumber < 1000; // all scenes with sceneid > 999 are built in scenes and should not be visible to user;
    });
  }

}

