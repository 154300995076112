<div class="modal-background-layer">
    <div class="modal-container">
        <div class="modal-header">
            <div [ngSwitch]="type">
                <div *ngSwitchCase="'userdelete'">
                    <h2 >You will delete user:
                      <br>
                      <span class="text-tooltip">
                        <span>{{content.firstName + ' ' + content.lastName | textSizeFilterPipe : 18}}</span>
                        <span class="bottom" *ngIf="content.firstName.length+ content.lastName.length > 18">{{content.firstName + ' ' + content.lastName}}</span>
                      </span>
                      <br>
                    </h2>
                </div>
                <div *ngSwitchCase="'userlistcantdelete'">
                    <h2 >Can't delete user:
                      <br>
                      <span class="text-tooltip">
                        <span>{{content.firstName + ' ' + content.lastName | textSizeFilterPipe : 18}}</span>
                        <span class="bottom" *ngIf="content.firstName.length+ content.lastName.length > 18">{{content.firstName + ' ' + content.lastName}}</span>
                      </span>
                      <br>
                    </h2>
                </div>
                <div *ngSwitchCase="'door'" class="text-tooltip">
                    <h2 >You will delete door:<br> {{content.name | textSizeFilterPipe : 22}}</h2>
                    <span class="bottom" *ngIf="content.name.length > 22">{{content.name}}</span>
                </div>
                <div *ngSwitchCase="'doorgroup'" class="text-tooltip">
                  <h2 >You will delete door group:<br> {{content.name | textSizeFilterPipe : 22}}</h2>
                  <span class="bottom" *ngIf="content.name.length > 22">{{content.name}}</span>
                </div>
                <div *ngSwitchCase="'room'" class="text-tooltip">
                  <h2 >You will delete room: <br> {{content.name | textSizeFilterPipe : 22}}</h2>
                  <span class="bottom" *ngIf="content.name.length > 22">{{content.name}}</span>
                </div>
                <div *ngSwitchCase="'automation'" class="text-tooltip">
                  <h2 >You will delete automation: <br> {{content.name | textSizeFilterPipe : 22}}</h2>
                  <span class="bottom" *ngIf="content.name.length > 22">{{content.name}}</span>
                </div>
                <div *ngSwitchCase="'scenedelete'" class="text-tooltip">
                  <h2 >You will delete scene: <br> {{content.name | textSizeFilterPipe : 22}}</h2>
                  <span class="bottom" *ngIf="content.name.length > 22">{{content.name}}</span>
                </div>
                <div *ngSwitchCase="'scenegroupdelete'" class="text-tooltip">
                  <h2 >You will delete scene group: <br> {{content.name | textSizeFilterPipe : 22}}</h2>
                  <span class="bottom" *ngIf="content.name.length > 22">{{content.name}}</span>
                </div>
                <div *ngSwitchCase="'modedelete'" class="text-tooltip">
                  <h2 >You will delete mode: <br> {{content.name | textSizeFilterPipe : 22}}</h2>
                  <span class="bottom" *ngIf="content.name.length > 22">{{content.name}}</span>
                </div>
                <div *ngSwitchCase="'modegroupdelete'" class="text-tooltip">
                  <h2 >You will delete mode group: <br> {{content.name | textSizeFilterPipe : 22}}</h2>
                  <span class="bottom" *ngIf="content.name.length > 22">{{content.name}}</span>
                </div>
                <div *ngSwitchCase="'role'" class="text-tooltip">
                  <h2 >You will delete role: <br> {{content.name | textSizeFilterPipe : 22}}</h2>
                  <span class="bottom" *ngIf="content.name.length > 22">{{content.name}}</span>
                </div>
                <div *ngSwitchCase="'card'">
                  <h2 >You will delete card</h2>
                </div>
                <div *ngSwitchCase="'alarmsubscription'" class="text-tooltip">
                  <h2 class="text-align-center">You will delete alarm subscription</h2>
                </div>
                <div *ngSwitchCase="'doorunlock'" class="text-tooltip">
                    <h2 >You will unlock door:<br> {{content.name | textSizeFilterPipe : 22}}.</h2>
                    <span class="bottom" *ngIf="content.name.length > 22">{{content.name}}</span>
                </div>
                <div *ngSwitchCase="'cardholder'">
                    <h2 >Put card on card reader</h2>
                </div>
                <div *ngSwitchCase="'haveassigned'">
                    <h2 >Can't delete. User has assigned permission or roles, revoke or transfer!</h2>
                </div>
                <div *ngSwitchCase="'revokepermissions'">
                    <h2 >You will revoke permissions that <br>
                      <span class="text-tooltip">
                        <span>{{content.firstName + ' ' + content.lastName | textSizeFilterPipe : 22}}</span>
                        <span class="bottom" *ngIf="content.firstName.length+ content.lastName.length > 22">{{content.firstName + ' ' + content.lastName}}</span>
                    </span>
                    <br> assigned!</h2>
                </div>
                <div *ngSwitchCase="'revokeroles'">
                    <h2 >You will revoke roles that
                    <br>
                    <span class="text-tooltip">
                      <span>{{content.firstName + ' ' + content.lastName | textSizeFilterPipe : 22}}</span>
                      <span class="bottom" *ngIf="content.firstName.length+ content.lastName.length > 22">{{content.firstName + ' ' + content.lastName}}</span>
                    </span>
                    <br>
                    assigned!</h2>
                </div>
                <div *ngSwitchCase="'discardchanges'">
                  <h2 >Discarding changes</h2>
                </div>

                <div *ngSwitchDefault>
                    <h2 >You will delete a {{type}}.</h2>
                </div>
            </div>
            <h3 *ngIf="type !== 'cardholder' && type !== 'noassigned' && type !== 'haveassigned' && type !== 'userlistcantdelete'">Are you sure?</h3>
            <h3 *ngIf="type === 'userlistcantdelete'">Go to details and resolve assigned permissions/roles!</h3>
        </div>
        <div class="modal-button-box">
            <button *ngIf="type !== 'haveassigned' && type !== 'userlistcantdelete'" (click)="decisionClick(false)">Cancel</button>
            <button *ngIf="type === 'haveassigned' || type === 'userlistcantdelete'" (click)="decisionClick(false)">Ok</button>
            <button *ngIf="type === 'userdelete' || type === 'door' || type === 'rule' || type === 'role'
              || type === 'room' || type === 'doorgroup' || type === 'card'|| type === 'alarmsubscription'
              || type === 'scenedelete' || type==='scenegroupdelete' || type === 'modedelete'
              || type==='modegroupdelete' || type==='automation' || type==='preset'"  (click)="decisionClick(true)">Delete</button>
            <button *ngIf="type === 'doorunlock'" (click)="decisionClick(true)">Unlock</button>
            <button *ngIf="type === 'discardchanges'" (click)="decisionClick(true)">Ok</button>
            <button *ngIf="type === 'revokepermissions' || type === 'revokeroles'" (click)="decisionClick(true)">Revoke</button>
        </div>
    </div>
</div>
