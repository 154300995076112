import { Pipe, PipeTransform } from '@angular/core';
import { Scene } from 'src/app/core/models/scenes/scene.model';

@Pipe({
  name: 'filterDaliBlinds'
})
export class FilterDaliBlindsFromScene implements PipeTransform {

  transform(scenes: Scene[]): Scene[] {
    if (!scenes) {
      return [];
      }
      scenes = scenes.filter((scene: Scene) => {
        if (scene.actions.actions.length === 1 && scene.actions.actions[0].command === "DaliActions.ActivateBlinds") {
          return false;
        } else {
          return true;
        }
      });
      return scenes
  }
}
