import { Injectable } from '@angular/core';
import { Subject, Subscription } from 'rxjs';

import { ApiService } from 'src/app/core/http/api.service';
import { AlarmAlert } from 'src/app/core/models/alarms/alarm-alert.model';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API_BASE_URL } from 'src/environments/environment';
import { MqttProjectService } from 'src/app/core/app-load/mqtt-project.service';

@Injectable({
    providedIn: 'root'
})
export class AlarmAlertsService {

    alarms: AlarmAlert[] = [];
    alarmsChanged = new Subject<AlarmAlert[]>();
    alarmAdded = new Subject<AlarmAlert>();


    constructor() {}

    getAlarms(): AlarmAlert[] {
        return this.alarms;
    }

    setAlarms(alarms: AlarmAlert[]) {
        this.alarms = alarms;
        this.alarmsChanged.next(this.alarms);
    }

    addAlarm(alarm: AlarmAlert) {
        this.alarms.push(alarm);
        this.alarmAdded.next(alarm);
        this.alarmsChanged.next(this.alarms);
    }

    updateAlarm(i: number, alarm: AlarmAlert) {
        this.alarms[i] = alarm;
        this.alarmsChanged.next(this.alarms);
    }

    confirmAlarm(id: number) {
        this.alarms = this.alarms.filter( element => element.alarmLogId !== id);
        this.alarmsChanged.next(this.alarms);
    }


}
