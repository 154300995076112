import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { PropertyType } from 'src/app/core/models/project/property-type.model';
import { take } from 'rxjs/operators';

@Injectable({
  providedIn: 'root'
})
export class EquipmentPropertyTypesService {

  private readonly EQUIPMENT_PROPERTY_TYPES_URL: string = 'assets/controller-property-types.json';
  private readonly ADMIN_EQUIPMENT_PROPERTY_TYPES_URL: string = 'assets/admin-settings.json';
  private readonly STATUS_EQUIPMENT_PROPERTY_TYPES_URL: string = 'assets/statuses.json';
  private equipmentPropertyTypes: PropertyType[];
  private equipmentPropertyTypesForAdmin: PropertyType[];
  private equipmentPropertyTypesForStatus: PropertyType[];

  constructor(private http: HttpClient) {
    this.loadEquipmentPropertyTypes();
    this.loadEquipmentPropertyTypesForAdmin();
    this.loadEquipmentPropertyTypesForStatus();
   }

   loadEquipmentPropertyTypes() {
    this.http.get<PropertyType[]>(this.EQUIPMENT_PROPERTY_TYPES_URL)
    .subscribe({
      next: (equipTypesJson: any) => {
      this.equipmentPropertyTypes = equipTypesJson.Definitions;
      },
      error: (error) => {
        if (error === 'Not Found') {
        } else {
          console.log('Device types config error');
        }
      }});
  }

  loadEquipmentPropertyTypesForAdmin() {
    this.http.get<PropertyType[]>(this.ADMIN_EQUIPMENT_PROPERTY_TYPES_URL)
    .subscribe({
      next: (equipTypesJson: any) => {
      this.equipmentPropertyTypesForAdmin = equipTypesJson.Definitions;
      },
      error: (error) => {
        if (error === 'Not Found') {
        } else {
          console.log('Device types config error');
        }
      }});
  }

  loadEquipmentPropertyTypesForStatus() {
    this.http.get<PropertyType[]>(this.STATUS_EQUIPMENT_PROPERTY_TYPES_URL)
    .subscribe({
      next: (equipTypesJson: any) => {
        this.equipmentPropertyTypesForStatus = equipTypesJson.Definitions;
      },
      error: error => {
        if (error === 'Not Found') {
        } else {
          console.log('Device types config error');
        }
      }});
  }


  getEquipmentPropertyType(typeNumber: number): PropertyType {
    return this.equipmentPropertyTypes?.find((propertyType: PropertyType) => {
      return (typeNumber >= propertyType.CodeFrom) && (typeNumber <= propertyType.CodeTo);
    });
  }

  getEquipmentPropertyTypeForAdmin(typeNumber: number): PropertyType {
    return this.equipmentPropertyTypesForAdmin?.find((propertyType: PropertyType) => {
      return (typeNumber >= propertyType.CodeFrom) && (typeNumber <= propertyType.CodeTo);
    });
  }

  getEquipmentPropertyTypeForStatus(typeNumber: number): PropertyType {
    return this.equipmentPropertyTypesForStatus?.find((propertyType: PropertyType) => {
      return (typeNumber >= propertyType.CodeFrom) && (typeNumber <= propertyType.CodeTo);
    });
  }

}
