import { IMqttServiceOptions } from 'ngx-mqtt';

export class Configuration {
  API_BASE_URL: string;
  API_BASE_LOCAL_URL: string;
  API_BASE_OVPN_URL: string;
  OVPN_URL: string;
  LOCAL_URL: string;
  VERSION_CHECK_URL: string;
  MQTT_SETTINGS: IMqttServiceOptions;
  MQTT_LOCAL_SETTINGS: IMqttServiceOptions;
  MQTT_OVPN_SETTINGS: IMqttServiceOptions;
  USE_NETWORK_SWITCHER: boolean;
  NETWORK_SWITCHER_PING_INTERVAL: number;
  NETWORK_SWITCHER_PING_TIMEOUT: number; // must be shorter than NETWORK_SWITCHER_PING_INTERVAL
  USE_DALI: boolean;
  SLEEP_TIMEOUT: number;
  SHOW_SCENE_STATUS: boolean;
  USE_DEVICE_VISIBLE_TO_USER: boolean;
  DEFAULT_PIN_DURATION_IN_DAYS: number;
  USE_ACCESS_PIN: boolean;
  USE_CARD_READER: boolean;
  OBJECT_NAME?: string

  private static networkSwitcherPingTimeoutIsValid(config: Configuration): boolean {
    return config.NETWORK_SWITCHER_PING_TIMEOUT !== null
    && config.NETWORK_SWITCHER_PING_TIMEOUT !== undefined
    && config.NETWORK_SWITCHER_PING_TIMEOUT > 0
    && config.NETWORK_SWITCHER_PING_TIMEOUT < config.NETWORK_SWITCHER_PING_INTERVAL;
  }

  private static getNetworkSwitcherPingTimeoutDefault(): number {
    return 1000; // milisec
  }

  public static getNetworkSwitcherPingTimeout(config: Configuration): number {
    if (this.networkSwitcherPingTimeoutIsValid(config)) {
      return config.NETWORK_SWITCHER_PING_TIMEOUT;
    } else {
      return this.getNetworkSwitcherPingTimeoutDefault();
    }
  }

}
