import { Component, OnInit, OnDestroy } from '@angular/core';

import { User } from '../../../core/models/user/user.model';
import { UserService } from 'src/app/core/services/user.service';
import { AuthenticationService } from 'src/app/core/authentication/authentication.service';
import { DemoService } from 'src/app/core/services/demo.service';
import { Subscription } from 'rxjs';
import { AlertController, MenuController, Platform } from '@ionic/angular';
import { MqttProjectService } from 'src/app/core/app-load/mqtt-project.service';
import { RoomViewService } from 'src/app/modules/rooms/services/room-view.service';
import { AlarmAlertsService } from 'src/app/modules/alarms/services/alarm-alerts.service';
import { Project } from 'src/app/core/models/project/project.model';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { ObjectConfigurationsService } from 'src/app/core/app-load/object-configurations.service';
import { ConfigLoaderService } from 'src/app/core/app-load/config-loader.service';
import { GenComTranslatePipe } from '../../pipes/gen-com-translate.pipe';


@Component({
  selector: 'app-sidebar',
  templateUrl: './sidebar.component.html',
  styleUrls: ['./sidebar.component.scss']
})
export class SidebarComponent implements OnInit, OnDestroy {
  project: Project;
  user: User;
  demo: boolean;
  userSubscription: Subscription;
  demoSubscription: Subscription;
  projectSubscription: Subscription;


  constructor(private authService: AuthenticationService,
              private userService: UserService,
              private demoService: DemoService,
              private menuController: MenuController,
              private mqttProjectService: MqttProjectService,
              private roomViewService: RoomViewService,
              private projectService: ProjectService,
              private objectConfigurationsService: ObjectConfigurationsService,
              private configLoaderService: ConfigLoaderService,
              private platform: Platform,
              private alertController: AlertController,
              private translatePipe: GenComTranslatePipe
              ) { }

  ngOnInit() {
    this.user = Object.assign(new User(), this.userService.getUser());
    this.userSubscription = this.userService.userChanged.subscribe(() => {
      this.user = Object.assign(new User(), this.userService.getUser());
    });
    this.demoSubscription = this.demoService.getDemo$().subscribe((demoStatus: boolean) => {
      this.demo = demoStatus;
    });
    this.project = this.projectService.getProject();
    this.projectSubscription = this.projectService.projectChanged.subscribe(() => {
      this.project = this.projectService.getProject();
    });

  }

  userIsGuest(user: User): boolean {
    return User.isGuest(user);
  }

  userIsAdmin(user: User): boolean {
    return User.isAdmin(user);
  }

  userIsOwner(user: User): boolean {
    return User.isOwner(user);
  }

  onRouteToRooms() {
    this.onClickLink();
    this.roomViewService.setSelectedFloor(null);
  }


  onClickLink() {
    this.menuController.close();
  }


  async logoutPrompt() {
    const alert = await this.alertController.create({
      header: this.translatePipe.transform('Confirm logout'),
      buttons: [ {
        text: this.translatePipe.transform('Cancel'),
        role: 'cancel',
      },
        {
          text: this.translatePipe.transform('Logout'),
          handler: () => {
            this.logout()
          }
        }
      ],
    });
    await alert.present();
  }

  async logout() {
    const config = this.configLoaderService.getConfig();
    this.userService.unsetUser();
    this.authService.logout();
    try {
      this.mqttProjectService.disconnect()
    } catch (err) {
      // console.log(err);
    }
    this.mqttProjectService.unsubscribeFromMqtt();
    this.mqttProjectService.unsubscribeFromMqttAlarms();
    if (this.demoService.isDemo()){
      this.demoService.setDemo(false);
    }
    if (!this.platform.is('desktop')) {
      await this.objectConfigurationsService.deleteTokenFromArray(config._id);
    }

  }

  ngOnDestroy() {
    if (this.userSubscription) {
      this.userSubscription.unsubscribe();
    }
    if (this.demoSubscription) {
      this.demoSubscription.unsubscribe();
    }
    if (this.projectSubscription) {
      this.projectSubscription.unsubscribe();
    }
  }


}
