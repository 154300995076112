import { Subject } from 'rxjs';
import { Condition } from 'src/app/core/models/automation/condition.model';
import { Action } from './automation/action.model';
import { Property } from './project/property.model';
import { PropertyType } from './project/property-type.model';
import { Device } from './project/devices/device.model';
import { NotFoundComponent } from 'src/app/shared/components/equipment/not-found/not-found.component';


export type ComponentMode = 'condition' | 'action' | 'live' | 'settings';

export class EquipmentPropertyComponentToLoad {
  actionChanged$: Subject<Action>;
  conditionChanged$: Subject<Condition>;
  actionInput: Action;
  conditionInput: Condition;
  component: any;
  equipmentProperty: Property;
  equipmentPropertyType: PropertyType;
  parentDevice: Device;
  mode: ComponentMode;
  data;

  constructor(
     component: any,
     equipmentProperty: Property,
     equipmentPropertyType: PropertyType,
     parentDevice: Device,
     data
   ) {
     if (component) {

      this.component = component;
      } else {
        this.component = NotFoundComponent;
      }
     this.equipmentProperty = equipmentProperty;
     this.equipmentPropertyType = equipmentPropertyType;
     this.parentDevice = parentDevice;
     this.data = data;
   }
}
