import { Injectable } from '@angular/core';
import { BehaviorSubject} from 'rxjs';
import { map } from 'rxjs/operators';
import { Device } from 'src/app/core/models/project/devices/device.model';
import { Property } from 'src/app/core/models/project/property.model';
import { EquipmentPropertyTypesService } from 'src/app/shared/services/equipment-property-types.service';
import { ProjectService } from '../project/services/project.service';

export class AdminSettingsPreset {
  _id?: string;
  name: string;
  presets: string[][];
}

@Injectable({
  providedIn: 'root'
})
export class AdminSettingsService {

  private presets$ = new BehaviorSubject<AdminSettingsPreset[] | undefined>(undefined);
  private editedPreset$ = new BehaviorSubject<AdminSettingsPreset | undefined>(undefined);
  private editedPresetId$ = new BehaviorSubject<string>('');

  constructor(
    private projectService: ProjectService,
    private equipmentPropertyTypesService: EquipmentPropertyTypesService
    ) {}


    setPresets(presets: AdminSettingsPreset[]) {
      this.presets$.next(presets);
    }

    getPresets() {
      return this.presets$.asObservable();
    }


   initNewAdminSettings() {
    const project = this.projectService.getProject();
    const adminDevices: Device[] = [];
    adminDevices.push(...this.filterIfEquipmentPropertyIsAvailable(project.listOfLights));
    adminDevices.push(...this.filterIfEquipmentPropertyIsAvailable(project.listOfBlinds));
    adminDevices.push(...this.filterIfEquipmentPropertyIsAvailable(project.listOfHvacs));
    adminDevices.push(...this.filterIfEquipmentPropertyIsAvailable(project.listOfSecurityEquipment));
    adminDevices.push(...this.filterIfEquipmentPropertyIsAvailable(project.listOfSafetyEquipment));
    adminDevices.push(...this.filterIfEquipmentPropertyIsAvailable(project.listOfGeneralEquipment));

    const adminSettings: string[][] = [];
    adminDevices.forEach((device: Device) => {
      device.equipmentProperties.forEach((prop: Property) => {
        if (this.equipmentPropertyTypesService.getEquipmentPropertyTypeForAdmin(prop.type)) {
          adminSettings.push([`${device.id}/${prop.type}`, prop.value.toString()]);
        }
      });
    });
    this.setEditedPreset({name: ``, presets: adminSettings});
    this.setEditedPresetId('new');
  }



  setEditedPresetId(presetId: string) {
    this.editedPresetId$.next(presetId);
  }

  getEditedPresetId() {
    return this.editedPresetId$.asObservable();
  }


  updatePresetName(updatedName: string) {
    const adminSettings = this.editedPreset$.getValue();
    const newAdminSettings: AdminSettingsPreset = adminSettings;
    newAdminSettings.name = updatedName;
    this.editedPreset$.next(newAdminSettings);
  }

  toggleProperty(propDeviceAndType: string) {
    const adminSettings = this.editedPreset$.getValue();
    const settingsIndex = adminSettings.presets.findIndex((set) => {
      return set[0] === propDeviceAndType;
    });
    const newAdminSettings: AdminSettingsPreset = adminSettings;
    if (adminSettings.presets[settingsIndex][1] === undefined) {
      newAdminSettings.presets[settingsIndex][1] = '1';
    } else if (adminSettings.presets[settingsIndex][1].toString() === '1' ) {
      newAdminSettings.presets[settingsIndex][1] = '0';
    } else if (adminSettings.presets[settingsIndex][1].toString() === '0' ){
      newAdminSettings.presets[settingsIndex][1] = '1';
    }
    this.editedPreset$.next(newAdminSettings);
  }


  incrementPropValue(propDeviceAndType: string) {
    const adminSettings = this.editedPreset$.getValue();
    const settingsIndex = adminSettings.presets.findIndex((set) => {
      return set[0] === propDeviceAndType;
    });
    const newAdminSettings: AdminSettingsPreset = adminSettings;
    if (adminSettings.presets[settingsIndex][1] === undefined) {
      newAdminSettings.presets[settingsIndex][1] = '0';
    } else if (adminSettings.presets[settingsIndex][1] === '50' ){
      newAdminSettings.presets[settingsIndex][1] = '50';
    } else {
      newAdminSettings.presets[settingsIndex][1] = (Number(newAdminSettings.presets[settingsIndex][1]) + 1).toString();
    }
    this.editedPreset$.next(newAdminSettings);
  }


  decrementPropValue(propDeviceAndType: string) {
    const adminSettings = this.editedPreset$.getValue();
    const settingsIndex = adminSettings.presets.findIndex((set) => {
      return set[0] === propDeviceAndType;
    });
    const newAdminSettings: AdminSettingsPreset = adminSettings;
    if (adminSettings.presets[settingsIndex][1] === undefined) {
      newAdminSettings.presets[settingsIndex][1] = '0';
    } else if (adminSettings.presets[settingsIndex][1] === '0' ){
      newAdminSettings.presets[settingsIndex][1] = '0';
    } else {
      newAdminSettings.presets[settingsIndex][1] = (Number(newAdminSettings.presets[settingsIndex][1]) - 1).toString();
    }
    this.editedPreset$.next(newAdminSettings);
  }

  inputPropValue(propDeviceAndType: string, input) {
    const adminSettings = this.editedPreset$.getValue();
    const settingsIndex = adminSettings.presets.findIndex((set) => {
      return set[0] === propDeviceAndType;
    });
    const newAdminSettings: AdminSettingsPreset = adminSettings;
    newAdminSettings.presets[settingsIndex][1] = (input.toString());
    this.editedPreset$.next(newAdminSettings);
  }

  setEditedPreset(adminSettings: AdminSettingsPreset) {
    this.editedPreset$.next(adminSettings);
  }

  getEditedPreset() {
    return this.editedPreset$.asObservable();
  }

  getEditedPresetValue() {
    return this.editedPreset$.getValue();
  }

  getSetting(propDeviceAndType: string) {
    return this.editedPreset$.pipe(map((resp: AdminSettingsPreset) => {
      if (resp) {
      const setting = resp.presets.find((set) => {
        return set[0] === propDeviceAndType;
      });

      if (setting) {
        return setting[1];
      } else {
        // if setting is not determined in presets, a new preset gets generated on current value
        const deviceId = propDeviceAndType.split('/')[0];
        const equipPropType = Number(propDeviceAndType.split('/')[1]);
        const device = this.projectService.findDeviceById(deviceId);
        const equipProp = device.equipmentProperties.find((prop) => {
          return prop.type === equipPropType;
        });
        const newEditedPreset: AdminSettingsPreset = JSON.parse(JSON.stringify(resp));
        newEditedPreset.presets.push([propDeviceAndType, equipProp.value.toString()]);
        this.setEditedPreset(newEditedPreset);

        return equipProp.value;
      }

  }
 }));
  }

  filterIfEquipmentPropertyIsAvailable(array) {
    const propArray = array.filter(element => {
      let found = false;
      element.equipmentProperties.filter(prop => {
        if (this.equipmentPropertyTypesService.getEquipmentPropertyTypeForAdmin(prop.type)) {
          found = true;
        }
      });
      if (found) { return true; }
    });
    return propArray;
  }
}
