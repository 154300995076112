import { Pipe, PipeTransform } from '@angular/core';
import { Mode } from 'src/app/core/models/modes/mode.model';

@Pipe({
  name: 'modeAptFilter'
})
export class ModeAptFilterPipe implements PipeTransform {
  transform(modes: Mode[], apartmentId: string): Mode[] {
    if (!modes) {
      return [];
      }
    if (!apartmentId) {
      return modes;
      }
    return modes.filter((mode: Mode) => {
      return mode.apartmentId === apartmentId;
    } );
  }
}
