import { Component, OnInit, OnDestroy, Input, Output, EventEmitter } from '@angular/core';
import { Subscription, Subject } from 'rxjs';

@Component({
  selector: 'app-paginator',
  templateUrl: './paginator.component.html',
  styleUrls: ['./paginator.component.scss']
})
export class PaginatorComponent implements OnInit, OnDestroy {

  pageNumberSubject$ = new Subject<number>();
  pageNumberSubscription: Subscription;
  @Output() targetPage: EventEmitter<number> = new EventEmitter<number>();
  @Input() paginationData = {
    TotalCount: 0,
    PageSize: 0,
    CurrentPage: 0,
    TotalPages: 0,
    HasNext: false,
    HasPrevious: false
  };

  constructor() {
  }

  ngOnInit(): void {

    this.pageNumberSubscription = this.pageNumberSubject$.subscribe(page  => {
          this.targetPage.emit(page);
        });
    }

  onNavigate(targetPage: number){
    this.pageNumberSubject$.next(targetPage);
  }

  ngOnDestroy() {
    if (this.pageNumberSubscription) {
      this.pageNumberSubscription.unsubscribe();
    }
  }
}
