<div *ngIf="mode === 'live' && !data.showSpecialHTML">
  <div [ngClass]="data?.icon" class="equip-prop-container" (click)="propertyClicked()">
  <div>
    {{equipmentProperty.name}}
  </div>
  <div class="blue-text">
    {{equipmentProperty?.value}}
  </div>
</div>
</div>
<div *ngIf="mode === 'live' && data.showSpecialHTML" class="margin-top20 margin-bottom20">
  <ion-item lines="none" class="ion-no-padding">
    <ion-range
      #rangeValue
      min="{{options.floor}}"
      max="{{options.ceil}}"
      step="1"
      value="{{equipmentProperty.value}}"
      snaps
      [color]="getSliderColor()"
      (mousedown)="sliderClicked()"
      (touchstart)="sliderClicked()"
      (ionChange)="onInputChanged(rangeValue.value, 'rangeValue')">
      <ion-icon slot="start" size="large" [style.color]="'#C4C4C4'" name="remove-circle" (click)="minusClicked(targetNumber)" class="clickable margin-right20"></ion-icon>
      <ion-icon slot="end" size="large" [style.color]="'#C4C4C4'" name="add-circle" (click)="plusClicked(targetNumber)" class="clickable margin-left20"></ion-icon>
    </ion-range>
  </ion-item>
  <div class="num-label" [ngClass]="heatingActive ? 'red-text': ''">{{equipmentProperty.value}} {{data.unitOfMeasurement}}</div>
</div>
<div *ngIf="mode === 'action'" class="flex-column">
  <!-- <label class="label margin-bottom20">{{equipmentProperty.name}}</label> -->
  <div class="flex-row flex-center">
    <button
      class="btn-circle i-minus-rounded-wh margin-left10"
      (click)="minusClicked()"
    ></button>
    <input
      #actionInputRef
      [min]="options.floor"
      [max]="options.ceil"
      class="input width-50px"
      type="number"
      [value]="targetNumber"
      (input)="onInputChanged($event.target.value, 'actionInputRef')"
    />
    <div class="label-3">{{data.unitOfMeasurement}}</div>
    <button
      class="btn-circle i-plus-rounded-wh margin-right10"
      (click)="plusClicked()"
    ></button>
  </div>
</div>

<div *ngIf="mode === 'condition'" class="flex-column">
    <!-- <div class="label">{{ equipmentProperty?.name }} {{ 'is_1' | translate }} </div> -->
  <div class="flex-row">
    <select
      class="input margin-bottom20"
      style="margin-bottom: 20px;"
      name="operator" [(ngModel)]="condition.type"
      (ngModelChange)="onChangeConditionType()">
      <option value="100" disabled selected>{{ 'select_relation' | translate }}</option>
      <option
        *ngFor="let op of relationalOperators"
        [ngValue]="op.key"
        [selected]="condition.type === op.key"
      >
        {{ op.value | genComTranslate }}
      </option>
    </select>
    <div *ngIf="condition.type !== 100" class="label-2">
       {{ getComparatorString(condition.type) }}
     </div>
  </div>

    <div class="flex-row width-full">
      <input
        [min]="options.floor"
        [max]="options.ceil"
        *ngIf="condition.type !== 100"
        class="input width-50px"
        type="number"
        (input)="onInputChanged($event.target.value, 'conditionInputRef1')"
        [(ngModel)]="condition.parameter2"
      />
      <div *ngIf="condition.type >= 5 && condition.type <= 8" class="label-to">{{ 'to' | translate }}</div>
      <input
        [min]="options.floor"
        [max]="options.ceil"
        *ngIf="condition.type >= 5 && condition.type <= 8"
        class="input width-50px"
        type="number"
        [(ngModel)]="condition.parameter3"
        (input)="onInputChanged($event.target.value, 'conditionInputRef2')"
      />
      <div *ngIf="condition.type !== 100" class="label-2">{{data?.unitOfMeasurement}}</div>
    </div>
</div>


<div *ngIf="mode === 'settings'" class="equip-prop-container" [ngClass]="data?.icon" (click)="openEquipmentNumberSettingsModal()">
  <div>
    {{equipmentProperty.name}}
  </div>

  <div class="flex-row status-row">
  <div >
    {{ 'current' | translate }}: {{equipmentProperty?.value}}
  </div>

  <div class="blue-text width-125 text-align-right">
    {{ 'is_set' | translate }}: {{settingValue$ | async}}
  </div>
</div>
</div>
