import { Injectable } from '@angular/core';
import { HttpClient, HttpParams } from '@angular/common/http';
import { API_BASE_URL } from 'src/environments/environment';
import { Mode } from '../../../../core/models/modes/mode.model';
import { map, tap } from 'rxjs/operators';
import { ModesService } from '../modes.service';
import { DemoService } from 'src/app/core/services/demo.service';


@Injectable({
    providedIn: 'root'
})
export class ApiModesService {

    constructor(private modesService: ModesService,
                private http: HttpClient,
                private demoService: DemoService
                ) { }

    addMode(body: Mode) {
        return this.http.post(API_BASE_URL + '/modes', body).pipe(
            map(mode => {
                this.modesService.addMode(body);
                return mode;
            })
        );
      }

      getModes(){
        if (this.demoService.isDemo()) {
            return this.demoService.getDemoModes().pipe(
                tap( modes => {
                    this.modesService.setModes(modes);
                })
            )
        }else {
            return this.http.get<Mode[]>(API_BASE_URL + '/modes').pipe(
                map( modes => {
                    this.modesService.setModes(modes);
                    return modes;
                })
            );
        }
      }

      getMode(modeId: string) {
          const params = new HttpParams().set('id', modeId);
          return this.http.get<Mode>(API_BASE_URL + '/modes/byId', {params}).pipe(
              tap( (mode: Mode) => {
                if (this.modesService.getModes() === undefined) {
                  this.getModes().subscribe(() => {
                  this.modesService.updateMode(mode);
                  }
              ); } else {
                this.modesService.updateMode(mode);
              }
            }));
      }

      updateMode(body: Mode){
        return this.http.put<Mode>(API_BASE_URL + '/modes', body).pipe(
            tap( mode => {
                this.modesService.updateMode(body);
            })
        );
      }

      deleteMode(modeId: string) {
        const params = new HttpParams().set('id', modeId);
        return this.http.delete<Mode>(API_BASE_URL + '/modes', {params}).pipe(
            map( mode => {
                this.modesService.deleteMode(modeId);
                return mode;
            })
        );
      }

      activateMode(modeId: string){
          const params = new HttpParams().set('id', modeId);
          return this.http.get<Mode>(API_BASE_URL + '/modes/activate', {params}).pipe(
              map( mode => {
                  this.modesService.activateOrDeactivateMode(modeId);
                  return mode;
              })
          );
      }


      deactivateMode(modeId: string){
          const params = new HttpParams().set('id', modeId);
          return this.http.get<Mode>(API_BASE_URL + '/modes/deactivate', {params}).pipe(
              map( mode => {
                  this.modesService.activateOrDeactivateMode(modeId);
                  return mode;
              })
          );
      }


    //   activateModeGroup(modeId: string){
    //     const params = new HttpParams().set('id', modeId);
    //     return this.http.get<Mode>(API_BASE_URL + '/modes/group/activate', {params}).pipe(
    //         map( mode => {
    //             return mode;
    //         })
    //     );
    //   }

    //   getModeGroups(){
    //     return this.http.get<ModeGroup[]>(API_BASE_URL + '/modes/group').pipe(
    //         map( modeGroup => {
    //             return modeGroup;
    //         })
    //     );
    //   }

    //   getModeGroup(modeGroupId: string){
    //     const params = new HttpParams().set('id', modeGroupId);
    //     return this.http.get<ModeGroup>(API_BASE_URL + '/modes/group/byId', {params}).pipe(
    //         map( modeGroup => {
    //             return modeGroup;
    //         })
    //     );
    //   }

    //   addModeGroup(body: ModeGroup) {
    //     return this.http.post(API_BASE_URL + '/modes/group', body).pipe(
    //         map( modeGroup => {
    //             return modeGroup;
    //         })
    //     );
    //   }

    //   deleteModeGroup(modeGroupId: string) {
    //     const params = new HttpParams().set('id', modeGroupId);
    //     return this.http.delete<ModeGroup>(API_BASE_URL + '/modes/group', {params}).pipe(
    //         map( modeGroup => {
    //             return modeGroup;
    //         })
    //     );
    //   }

    //   updateModeGroup(body: ModeGroup){
    //     return this.http.put<ModeGroup>(API_BASE_URL + '/modes/group', body).pipe(
    //         map ( modeGroup => {
    //             return modeGroup;
    //         })
    //     );
    //   }

}
