import { Injectable } from '@angular/core';
import { Subject } from 'rxjs';


@Injectable({
  providedIn: 'root'
})
export class OfflineControllersService  {

  commErrorRooms: Array<any> = [];
  commChanged = new Subject<boolean>();

  constructor() { }


  setOfflineControllers(offlineControllers: Array<any>) {
    this.commErrorRooms = offlineControllers;
    this.commChanged.next(true);
  }

  getOfflineControllers() {
    return this.commErrorRooms.slice()
  }
}
