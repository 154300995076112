<div *ngIf="mode === 'action'" class="flex-column">
  <div class="flex-column">
    <div class="label margin-bottom20">{{ equipmentProperty.name }}</div>
  </div>
  <ion-radio-group [value]="action.parameters[1]">
    <!-- <ion-item lines="none">
            <ion-label>{{ 'Off' | genComTranslate }}</ion-label>
            <ion-radio slot="start" class="color-radio margin-right40" value="0" (click)="handleActionChange($event)"></ion-radio>
          </ion-item>

          <ion-item lines="none">
            <ion-label>{{ 'On' | genComTranslate }}</ion-label>
            <ion-radio slot="start" class="color-radio margin-right40" value="1" (click)="handleActionChange($event)"></ion-radio>
          </ion-item> -->
    <ion-item *ngFor="let option of outputModeOptions" lines="none">
      <ion-label>{{ option?.name | genComTranslate }}</ion-label>
      <ion-radio
        slot="start"
        class="color-radio margin-right40"
        value="{{ option.value }}"
        (click)="handleActionChange($event)"
      ></ion-radio>
    </ion-item>
  </ion-radio-group>
</div>

<div *ngIf="mode === 'condition'" class="flex-column">
  <div class="flex-column" >
     <div class="label margin-bottom20">{{ equipmentProperty.name }}</div>
   </div>
     <ion-radio-group [value]="condition.parameter2">
      <!--  <ion-item lines="none">
         <ion-label>{{ data.nameForTrue | genComTranslate}}</ion-label>
         <ion-radio slot="start" class="color-radio margin-right40" value="1" (click)="handleConditionChange($event)"></ion-radio>
       </ion-item>

       <ion-item lines="none">
         <ion-label>{{ data.nameForFalse | genComTranslate }}</ion-label>
         <ion-radio slot="start" class="color-radio margin-right40" value="0" (click)="handleConditionChange($event)"></ion-radio>
       </ion-item> -->
       <ion-item *ngFor="let option of outputModeOptions" lines="none">
        <ion-label>{{ option?.name | genComTranslate }}</ion-label>
        <ion-radio
          slot="start"
          class="color-radio margin-right40"
          value="{{ option.value }}"
          (click)="handleConditionChange($event)"
        ></ion-radio>
      </ion-item>
     </ion-radio-group>
</div>

