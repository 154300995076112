import { Component, Input, OnInit } from '@angular/core';
import { Device } from 'src/app/core/models/project/devices/device.model';
import { Property } from 'src/app/core/models/project/property.model';
import { ApiProjectService } from 'src/app/modules/project/services/http/api-project.service';

@Component({
  selector: 'app-equipment-binary-mutex-live',
  templateUrl: './equipment-binary-mutex-live.component.html',
  styleUrls: ['./equipment-binary-mutex-live.component.scss']
})
export class EquipmentBinaryMutexLiveComponent implements OnInit {
  @Input() title: string;
  @Input() parentDevice: Device;
  @Input() propertyArray: Property[];


  constructor(private apiProjectService: ApiProjectService) { }

  ngOnInit(): void {
  }

  handleLiveChange(property: Property) {
    if (property.type >= 6500 && property.type <= 6549 ) {
      this.apiProjectService.changeProperty(this.parentDevice.id, property.type, '0').subscribe();
    }
    else if (property.type >= 6550 && property.type <= 6599 ) {
      this.apiProjectService.changeProperty(this.parentDevice.id, property.type, '2').subscribe();
    } else {
      this.apiProjectService.changeProperty(this.parentDevice.id, property.type, '1').subscribe();
    }
  }

}
