import { Pipe, PipeTransform } from '@angular/core';
import { User } from 'src/app/core/models/user/user.model';

@Pipe({
  name: 'userRoleFilter'
})
export class UserRoleFilterPipe implements PipeTransform {

  transform(users: User[], signedInUser: User): User[] {
    if (!users) {
      return [];
    }
    if (User.isAdmin(signedInUser)) {
      return users;
    }
    if (User.isOwner(signedInUser)) {
      return users.filter(u => {
        return User.isGuest(u) || User.isOwner(u);
      });
    }
  }
}
