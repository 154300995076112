export class LightsGroup {
    id: string;
    name: string;
    listOfLight: Array<string>;
    listOfLightIds: string[];
    value: number;
    isRelay: boolean;
    position: number;

}
