import { Pipe, PipeTransform } from '@angular/core';
import { Scene } from 'src/app/core/models/scenes/scene.model';

@Pipe({
  name: 'sceneMutexSort'
})
export class SceneMutexSortPipe implements PipeTransform {
  allScenes: Scene[];

  transform(scenesList: Scene[]): Scene[] {
    if (!scenesList || scenesList.length === 0) {
      return [];
    }
    const scenes: Scene [] = JSON.parse(JSON.stringify(scenesList));

    this.allScenes = scenes;
    return scenes.sort( this.compareMutexGroups );
  }

  compareMutexGroups = ( a: Scene, b: Scene ) => {
    if (!a.isMutex && b.isMutex) {
      return -1;
    }

    if (a.isMutex && !b.isMutex) {
      return 1;
    }

    if ((a.isMutex && b.isMutex) && (this.countScenesInSameGroup(a) <= 1 &&  this.countScenesInSameGroup(b) > 1)) {
      return -1;
    }

    if ((a.isMutex && b.isMutex) && (this.countScenesInSameGroup(a) > 1 &&  this.countScenesInSameGroup(b) <= 1 )) {
      return 1;
    }

    if ((a.isMutex && b.isMutex) && (a.mutexGroup < b.mutexGroup)) {
      return -1;
    }

    if ((a.isMutex && b.isMutex) && (a.mutexGroup > b.mutexGroup )) {
      return 1;
    }

    return 0;
  }


  countScenesInSameGroup = (scene: Scene) => {
    const scenesInMutexGroup = this.allScenes.filter((sc: Scene) => {
      return sc.isMutex && scene.isMutex && (scene.mutexGroup === sc.mutexGroup);
    });

    return scenesInMutexGroup.length;

  }

}
