import { Injectable } from '@angular/core';

import { Mode } from '../../../core/models/modes/mode.model';
import { Subject } from 'rxjs';
import { Action } from 'src/app/core/models/automation/action.model';


@Injectable({
    providedIn: 'root'
})
export class ModesService {

    private modes: Array<Mode>;
    modesChanged = new Subject<Mode[]>();

    constructor() {}

    setModes(modes: Mode[]) {
        this.modes = modes?.slice();
        this.modesChanged.next(this.modes);
    }

    getModes() {
        return this.modes?.slice();
    }

    getMode(modeId: string) {
        const mode = this.modes.find( element => modeId === element._id);
        return mode;
    }

    addMode(mode: Mode) {
        this.modes.push(mode);
        this.modesChanged.next(this.modes);
    }

    updateMode(mode: Mode) {
        const index = this.modes.findIndex( element => element._id === mode._id);
        this.modes.splice(index, 1, mode);
        this.modesChanged.next(this.modes);
    }

    deleteMode(id: string) {
        this.modes = this.modes.filter( value => value._id !== id);
        this.modesChanged.next(this.modes);
    }

    activateOrDeactivateMode(id: string) {
        const index = this.modes.findIndex( element => element._id === id);
        if (!this.modes[index].isActive) {
            this.modes[index].isActive = true;
        } else {
            this.modes[index].isActive = false;
        }
        this.modesChanged.next(this.modes);
    }

    getSomeoneInHouseModeForSIHScene(sceneId: string) {
      const modeForSIHScene = this.modes.find((mode: Mode) => {
        return mode.actions.actions.some((activateAction: Action) => {
          return activateAction.command === 'ModeActions.SomeoneInHouse'  && activateAction.parameters[1] === sceneId;
        });
      });
      return modeForSIHScene;
    }
}
