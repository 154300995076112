import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, map } from 'rxjs/operators';
import { API_BASE_URL } from 'src/environments/environment';



@Injectable({
  providedIn: 'root'
})
export class PinService {

  pin: string;

  constructor(private http: HttpClient) { }

  pinIsSet() {
      if (this.pin !== undefined) {
          return true;
      } else {
          return false;
      }
  }

  getPin() {
      return this.pin;
  }

  removePin() {
      this.pin = undefined;
  }

  setPin(pin: string) {
    const body = `${pin}`;
    const headersVariable: HttpHeaders = new HttpHeaders({'Content-Type': 'application/json'});
    return this.http.post<any>(API_BASE_URL + '/users/pin', `"${body}"`, {headers: headersVariable}).pipe(
        map( response => {
            this.pin = pin;
            return response;
        }),
        catchError(err => {
            this.pin = undefined;
            return err;
        })
    );
  }

}
