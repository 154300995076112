<div *ngIf="index !== undefined" class="modal-header-v2 flex-row">
    <span style="font-size: 20px;">{{content.name}} ({{content.id}})</span>
    <a
      (click)="showDevices = !showDevices"
      class="margin-left10 margin-top10 to-right"
      [ngClass]="{ rotate: !showDevices, 'rotate-back': showDevices }">
      <i class="i-arrow-up"></i>
    </a>
</div>

<div *ngIf="type === 'listOfBuildings' && showDevices" class="modal-content-v3">
    <div class="margin-top5 margin-bottom5"><strong>Name: </strong> <input [(ngModel)]="name" type="text"></div>
    <div class="flex-row margin-top5 margin-bottom5">
        <strong>List Of Apartments: </strong>
        <div class="margin-left2" *ngFor="let apartment of content.listOfApartments; let i = index">
            {{apartment}}{{addComma(i, content.listOfApartments.length)}}</div>
    </div>
    <div class="flex-row" *ngIf="content.listOfBuildingGroups !== null">
        <strong>Member of groups: </strong>
        <div class="margin-left2" *ngFor="let group of content.listOfBuildingGroups; let i = index">
            {{getGroupName(group, 'listOfBuildingGroups')}}{{addComma(i, content.listOfBuildingGroups.length)}}</div>
    </div>
    <div *ngIf="content.listOfBuildingGroups === null">Building is not in any group.</div>
    <!-- <hr class="margin-top10"> -->
    <div class="flex-row">
      <div *ngIf="index !== undefined" class="modal-button-box">
        <!-- <button (click)="decisionClick(false)">Cancel</button> -->
        <button (click)="decisionClick(true); showDevices = !showDevices">Save</button>
      </div>
      <a
      (click)="showDevices = !showDevices"
      class="margin-left10 margin-top25 to-right"
      [ngClass]="{ rotate: !showDevices, 'rotate-back': showDevices }">
      <i class="i-arrow-up"></i>
      </a>
    <hr>
    </div>
</div>

<div *ngIf="type === 'listOfApartments' && showDevices" class="modal-content-v3">
    <div class="margin-top5 margin-bottom5"><strong>Name: </strong> <input [(ngModel)]="name" type="text"></div>
    <div class="margin-top5 margin-bottom5"><strong>Building ID: </strong> {{content.buildingId}}</div>
    <div class="flex-row">
        <strong>List Of Rooms: </strong>
        <div class="margin-left2" *ngFor="let room of content.listOfRooms; let i = index">
            {{room}}{{addComma(i, content.listOfRooms.length)}}</div>
    </div>
    <div class="flex-row margin-top5 margin-bottom5" *ngIf="content.listOfApartmentGroups !== null">
        <strong>Member of groups: </strong>
        <div class="margin-left2 margin-top5 margin-bottom5" *ngFor="let group of content.listOfApartmentGroups; let i = index">
            {{getGroupName(group, 'listOfApartmentGroups')}}{{addComma(i, content.listOfApartmentGroups.length)}}</div>
    </div>
    <div *ngIf="content.listOfApartmentGroups === null">Apartment is not in any group.</div>
    <!-- <hr class="margin-top10"> -->
    <div class="flex-row">
      <div *ngIf="index !== undefined" class="modal-button-box">
        <!-- <button (click)="decisionClick(false)">Cancel</button> -->
        <button (click)="decisionClick(true); showDevices = !showDevices">Save</button>
      </div>
      <a
      (click)="showDevices = !showDevices"
      class="margin-left10 margin-top25 to-right"
      [ngClass]="{ rotate: !showDevices, 'rotate-back': showDevices }">
      <i class="i-arrow-up"></i>
      </a>
    <hr>
    </div>
</div>



<div *ngIf="type === 'listOfRooms' && showDevices" class="modal-content-v2">
  <div class="margin-top5 margin-bottom5"><strong>Name: </strong> <input [(ngModel)]="name" type="text"></div>
  <div class="margin-top5 margin-bottom5"><strong>Apartment ID: </strong> {{content.apartmentId}}</div>
  <div class="margin-top5 margin-bottom5"><strong>Room Title: </strong> <input [(ngModel)]="content.roomTitle" type="text"></div>
  <div class="margin-top5 margin-bottom5"><strong>Bathroom Title: </strong> <input [(ngModel)]="content.bathroomTitle" type="text"></div>
  <div class="flex-row margin-top5 margin-bottom5" *ngIf="content.listOfRoomGroups !== null">
      <strong>Member of groups: </strong>
      <div class="margin-left2" *ngFor="let group of content.listOfRoomGroups; let i = index">
          {{getGroupName(group, 'listOfRoomGroups')}}{{addComma(i, content.listOfRoomGroups.length)}}</div>
  </div>
  <div *ngIf="content.listOfRoomGroups === null">Room is not in any group.</div>
  <div class="flex-row">
    <a
    (click)="showDevices = !showDevices"
    class="margin-left10 to-right"
    [ngClass]="{ rotate: !showDevices, 'rotate-back': showDevices }">
    <i class="i-arrow-up"></i>
    </a>
  <hr>
  </div>
</div>



<div *ngIf="type === 'listOfBlinds' && showDevices" class="modal-content-v2">
    <div class="margin-top5 margin-bottom5"><strong>Name: </strong> <input [(ngModel)]="name" type="text"></div>
    <div class="flex-row margin-top5 margin-bottom5">
        <strong>List Of Location IDs: </strong>
        <div class="margin-left2" *ngFor="let id of content.listOfLocationIds; let i = index">
            {{id}}{{addComma(i, content.listOfLocationIds.length)}}</div>
    </div>
    <div class="flex-row margin-top5 margin-bottom5" *ngIf="content.listOfBlindsGroups !== null">
        <strong>Member of groups: </strong>
        <div class="margin-left2" *ngFor="let group of content.listOfBlindsGroups; let i = index">
            {{getGroupName(group, 'listOfBlindsGroups')}}{{addComma(i, content.listOfBlindsGroups.length)}}</div>
    </div>
    <div *ngIf="content.listOfBlindsGroups === null">Blind is not in any group.</div>
   <!--  <div><strong>Visible to user: </strong> {{content.visibleToUser}}</div> -->
    <input
              class="option-input-sm margin-bottom2"
              type="checkbox"
              [(ngModel)]="content.visibleToUser">
            <b>Visible to user</b>
            <br>
            <input
              class="option-input-sm margin-top5 margin-bottom2"
              type="checkbox"
              [(ngModel)]="content.isCondition">
            <b>Is condition</b>
            <br>
            <input
              class="option-input-sm margin-top5 margin-bottom2"
              type="checkbox"
            [(ngModel)]="content.isActuator">
            <b>Is actuator</b>
    <div class="margin-top10 margin-bottom10">
        <strong>Equipment Properties:</strong>
        <hr>
    </div>
    <div style="margin-left: 60px; margin-bottom: 20px;" *ngFor="let prop of content.equipmentProperties;let i = index">
        <b>Name</b>: <input type="text" [(ngModel)]="prop.name">
        <!-- <button class="show-more" (click)="addIdToShowArray(prop.id)">Show
            more</button> -->
        <div style="margin-left: 60px; margin-top: 10px;" *ngIf="!showThisProp(prop.id)">
            <b>ID</b>: {{prop.id}},
            <b>Available In User Scene</b>: {{prop.availableInUserScene}},
            <b>Security</b>: {{prop.security}},
            <b>Type</b>: {{prop.type}},
            <b>Value</b>: {{prop.value}},
            <b>InComm</b>: <u>Adress</u> - {{prop.inComm.address}}, <u>Communication type</u> -
            {{prop.inComm.communicationType}}
            <b>OutComm</b>: <u>Adress</u> - {{prop.outComm.address}}, <u>Communication type</u> -
            {{prop.outComm.communicationType}}
            <br>
            <input
              class="option-input-sm"
              type="checkbox"
              [(ngModel)]="prop.isConditionProperty">
            <b>Is condition property</b>
            <br>
            <input
              class="option-input-sm"
              type="checkbox"
            [(ngModel)]="prop.isActuatorProperty">
            <b>Is actuator property</b>
            <br>
            <input
              class="option-input-sm"
              type="checkbox"
            [(ngModel)]="prop.security.userCanActivate">
            <b>User can activate</b>
            <br>
            <input
              class="option-input-sm"
              type="checkbox"
            [(ngModel)]="prop.security.userCanActivateOverInternet">
            <b>User can activate over internet</b>
            <br>
            <input
              class="option-input-sm"
              type="checkbox"
            [(ngModel)]="prop.security.requirePinToActivate">
            <b>Require pin to activate</b>
            <br>
            <input
              class="option-input-sm"
              type="checkbox"
            [(ngModel)]="prop.security.requirePinToActivateOnlyOverInternet">
            <b>Require pin to activate only over internet</b>
        </div>
    </div>
    <div class="flex-row">
      <div *ngIf="index !== undefined" class="modal-button-box">
        <!-- <button (click)="decisionClick(false)">Cancel</button> -->
        <button (click)="decisionClick(true); showDevices = !showDevices">Save</button>
      </div>
      <a
      (click)="showDevices = !showDevices"
      class="margin-left10 margin-top25 to-right"
      [ngClass]="{ rotate: !showDevices, 'rotate-back': showDevices }">
      <i class="i-arrow-up"></i>
      </a>
    <hr>
    </div>
</div>

<div *ngIf="type === 'listOfGeneralEquipment' && showDevices" class="modal-content-v2">
    <div class="margin-top5 margin-bottom5"><strong>Name: </strong> <input [(ngModel)]="name" type="text"></div>
    <div class="flex-row margin-top5 margin-bottom5">
        <strong>List Of Location IDs: </strong>
        <div class="margin-left2" *ngFor="let id of content.listOfLocationIds; let i = index">
            {{id}}{{addComma(i, content.listOfLocationIds.length)}}</div>
    </div>
   <!--  <div><strong>Visible to user: </strong> {{content.visibleToUser}}</div> -->
    <div class="flex-row margin-top5 margin-bottom5" *ngIf="content.listOfGeneralEquipmentGroups !== null">
        <strong>Member of groups: </strong>
        <div class="margin-left2" *ngFor="let group of content.listOfGeneralEquipmentGroups; let i = index">
            {{getGroupName(group, 'listOfGeneralEquipmentGroup')}}{{addComma(i, content.listOfGeneralEquipmentGroups.length)}}</div>
    </div>
    <div *ngIf="content.listOfGeneralEquipmentGroups === null">Equipment is not in any group.</div>
    <input
              class="option-input-sm"
              type="checkbox"
              [(ngModel)]="content.visibleToUser">
            <b>Visible to user</b>
            <br>
    <input
              class="option-input-sm"
              type="checkbox"
              [(ngModel)]="content.isCondition">
            <b>Is condition</b>
            <br>
            <input
              class="option-input-sm"
              type="checkbox"
            [(ngModel)]="content.isActuator">
            <b>Is actuator</b>
    <div class="margin-top10 margin-bottom10">
        <strong>Equipment Properties:</strong>
        <hr>
    </div>
    <div style="margin-left: 60px; margin-bottom: 20px;" *ngFor="let prop of content.equipmentProperties">
        <b>Name</b>: <input type="text" [(ngModel)]="prop.name">
         <!-- <button class="show-more" (click)="addIdToShowArray(prop.id)">Show
            more</button> -->
        <div style="margin-left: 60px; margin-top: 10px;" *ngIf="!showThisProp(prop.id)">
            <b>ID</b>: {{prop.id}},
            <b>Available In User Scene</b>: {{prop.availableInUserScene}},
            <b>Security</b>: {{prop.security}},
            <b>Type</b>: {{prop.type}},
            <b>Value</b>: {{prop.value}},
            <b>InComm</b>: <u>Adress</u> - {{prop.inComm.address}}, <u>Communication type</u> -
            {{prop.inComm.communicationType}}
            <b>OutComm</b>: <u>Adress</u> - {{prop.outComm.address}}, <u>Communication type</u> -
            {{prop.outComm.communicationType}}
            <br>
            <input
              class="option-input-sm"
              type="checkbox"
              [(ngModel)]="prop.isConditionProperty">
            <b>Is condition property</b>
            <br>
            <input
              class="option-input-sm"
              type="checkbox"
            [(ngModel)]="prop.isActuatorProperty">
            <b>Is actuator property</b>
            <br>
            <input
              class="option-input-sm"
              type="checkbox"
            [(ngModel)]="prop.security.userCanActivate">
            <b>User can activate</b>
            <br>
            <input
              class="option-input-sm"
              type="checkbox"
            [(ngModel)]="prop.security.userCanActivateOverInternet">
            <b>User can activate over internet</b>
            <br>
            <input
              class="option-input-sm"
              type="checkbox"
            [(ngModel)]="prop.security.requirePinToActivate">
            <b>Require pin to activate</b>
            <br>
            <input
              class="option-input-sm"
              type="checkbox"
            [(ngModel)]="prop.security.requirePinToActivateOnlyOverInternet">
            <b>Require pin to activate only over internet</b>
        </div>
    </div>
    <div class="flex-row">
      <div *ngIf="index !== undefined" class="modal-button-box">
        <!-- <button (click)="decisionClick(false)">Cancel</button> -->
        <button (click)="decisionClick(true); showDevices = !showDevices">Save</button>
      </div>
      <a
      (click)="showDevices = !showDevices"
      class="margin-left10 margin-top25 to-right"
      [ngClass]="{ rotate: !showDevices, 'rotate-back': showDevices }">
      <i class="i-arrow-up"></i>
      </a>
    <hr>
    </div>
</div>




<div *ngIf="type === 'listOfHvacs' && showDevices" class="modal-content-v2">
    <div class="margin-top5 margin-bottom5"><strong>Name: </strong> <input [(ngModel)]="name" type="text"></div>
    <div class="flex-row margin-top5 margin-bottom5">
        <strong>List Of Location IDs: </strong>
        <div class="margin-left2" *ngFor="let id of content.listOfLocationIds; let i = index">
            {{id}}{{addComma(i, content.listOfLocationIds.length)}}</div>
    </div>
    <!-- <div><strong>Visible to user: </strong> {{content.visibleToUser}}</div> -->
    <div class="flex-row" *ngIf="content.listOfHvacGroups !== null">
        <strong>Member of groups: </strong>
        <div class="margin-left2 margin-top5 margin-bottom5" *ngFor="let group of content.listOfHvacGroups; let i = index">
            {{getGroupName(group, 'listOfHvacGroups')}}{{addComma(i, content.listOfHvacGroups.length)}}</div>
    </div>
    <div *ngIf="content.listOfHvacGroups === null">Hvac is not in any group.</div>
    <input
              class="option-input-sm"
              type="checkbox"
              [(ngModel)]="content.visibleToUser">
            <b>Visible to user</b>
            <br>
    <input
              class="option-input-sm"
              type="checkbox"
              [(ngModel)]="content.isCondition">
            <b>Is condition</b>
            <br>
            <input
              class="option-input-sm"
              type="checkbox"
            [(ngModel)]="content.isActuator">
            <b>Is actuator</b>
            <div class="margin-top5"><strong>Room max: </strong> <input [(ngModel)]="content.roomMax" type="number"></div>
            <div class="margin-top5"><strong>Room min: </strong> <input [(ngModel)]="content.roomMin" type="number"></div>
            <div class="margin-top5"><strong>Bathroom max: </strong> <input [(ngModel)]="content.bathroomMax" type="number"></div>
            <div class="margin-top5"><strong>Bathroom min: </strong> <input [(ngModel)]="content.bathroomMin" type="number"></div>
    <div class="margin-top10 margin-bottom10">
        <strong class="margin-top10 margin-bottom10">Equipment Properties:</strong>
        <hr>
    </div>
    <div style="margin-left: 60px; margin-bottom: 20px;" *ngFor="let prop of content.equipmentProperties">
        <b>Name</b>: <input type="text" [(ngModel)]="prop.name">
        <!-- <button class="show-more" (click)="addIdToShowArray(prop.id)">Show
            more</button> -->
        <div style="margin-left: 60px; margin-top: 10px;" *ngIf="!showThisProp(prop.id)">
            <b>ID</b>: {{prop.id}},
            <b>Available In User Scene</b>: {{prop.availableInUserScene}},
            <b>Security</b>: {{prop.security}},
            <b>Type</b>: {{prop.type}},
            <b>Value</b>: {{prop.value}},
            <b>InComm</b>: <u>Adress</u> - {{prop.inComm.address}}, <u>Communication type</u> -
            {{prop.inComm.communicationType}}
            <b>OutComm</b>: <u>Adress</u> - {{prop.outComm.address}}, <u>Communication type</u> -
            {{prop.outComm.communicationType}}
            <br>
            <input
              class="option-input-sm"
              type="checkbox"
              [(ngModel)]="prop.isConditionProperty">
            <b>Is condition property</b>
            <br>
            <input
              class="option-input-sm"
              type="checkbox"
            [(ngModel)]="prop.isActuatorProperty">
            <b>Is actuator property</b>
            <br>
            <input
              class="option-input-sm"
              type="checkbox"
            [(ngModel)]="prop.security.userCanActivate">
            <b>User can activate</b>
            <br>
            <input
              class="option-input-sm"
              type="checkbox"
            [(ngModel)]="prop.security.userCanActivateOverInternet">
            <b>User can activate over internet</b>
            <br>
            <input
              class="option-input-sm"
              type="checkbox"
            [(ngModel)]="prop.security.requirePinToActivate">
            <b>Require pin to activate</b>
            <br>
            <input
              class="option-input-sm"
              type="checkbox"
            [(ngModel)]="prop.security.requirePinToActivateOnlyOverInternet">
            <b>Require pin to activate only over internet</b>
        </div>
    </div>
    <div class="flex-row">
      <div *ngIf="index !== undefined" class="modal-button-box">
        <!-- <button (click)="decisionClick(false)">Cancel</button> -->
        <button (click)="decisionClick(true); showDevices = !showDevices">Save</button>
      </div>
      <a
      (click)="showDevices = !showDevices"
      class="margin-left10 margin-top25 to-right"
      [ngClass]="{ rotate: !showDevices, 'rotate-back': showDevices }">
      <i class="i-arrow-up"></i>
      </a>
    <hr>
    </div>
</div>

<div *ngIf="type === 'listOfLights' && showDevices" class="modal-content-v2">
    <div class="margin-top5 margin-bottom5"><strong>Name: </strong> <input [(ngModel)]="name" type="text"></div>
    <div class="flex-row margin-top5 margin-bottom5">
        <strong>List Of Location IDs: </strong>
        <div class="margin-left2" *ngFor="let id of content.listOfLocationIds; let i = index">
            {{id}}{{addComma(i, content.listOfLocationIds.length)}}</div>
    </div>
    <div class="flex-row" *ngIf="content.listOfLightGroups !== null">
        <strong>Member of groups: </strong>
        <div class="margin-left2 margin-top5 margin-bottom5" *ngFor="let group of content.listOfLightGroups; let i = index">
            {{getGroupName(group, 'listOfLightGroups')}}{{addComma(i, content.listOfLightGroups.length)}}</div>
    </div>
    <div *ngIf="content.listOfLightGroups === null">Light is not in any group.</div>
    <input
      class="option-input-sm"
      type="checkbox"
      [(ngModel)]="content.visibleToUser">
    <b>Visible to user</b>
  <br>
    <input
              class="option-input-sm"
              type="checkbox"
              [(ngModel)]="content.isCondition">
            <b>Is condition</b>
            <br>
            <input
              class="option-input-sm"
              type="checkbox"
            [(ngModel)]="content.isActuator">
            <b>Is actuator</b>
    <div class="margin-top10 margin-bottom10">
        <h3 class="margin-top10 margin-bottom10">Equipment Properties</h3>
        <hr>
    </div>
    <div style="margin-left: 60px; margin-bottom: 20px;" *ngFor="let prop of content.equipmentProperties">
        <b>Name</b>: <input type="text" [(ngModel)]="prop.name"> <button class="show-more" (click)="addIdToShowArray(prop.id)">Show
            more</button>
        <div style="margin-left: 60px; margin-top: 10px;" *ngIf="showThisProp(prop.id)">
            <b>ID</b>: {{prop.id}},
            <b>Available In User Scene</b>: {{prop.availableInUserScene}},
            <b>Security</b>: {{prop.security}},
            <b>Type</b>: {{prop.type}},
            <b>Value</b>: {{prop.value}},
            <b>InComm</b>: <u>Adress</u> - {{prop.inComm.address}}, <u>Communication type</u> -
            {{prop.inComm.communicationType}}
            <b>OutComm</b>: <u>Adress</u> - {{prop.outComm.address}}, <u>Communication type</u> -
            {{prop.outComm.communicationType}}
            <br>
            <input
              class="option-input-sm"
              type="checkbox"
              [(ngModel)]="prop.isConditionProperty">
            <b>Is condition property</b>
            <br>
            <input
              class="option-input-sm"
              type="checkbox"
            [(ngModel)]="prop.isActuatorProperty">
            <b>Is actuator property</b>
            <br>
            <input
              class="option-input-sm"
              type="checkbox"
            [(ngModel)]="prop.security.userCanActivate">
            <b>User can activate</b>
            <br>
            <input
              class="option-input-sm"
              type="checkbox"
            [(ngModel)]="prop.security.userCanActivateOverInternet">
            <b>User can activate over internet</b>
            <br>
            <input
              class="option-input-sm"
              type="checkbox"
            [(ngModel)]="prop.security.requirePinToActivate">
            <b>Require pin to activate</b>
            <br>
            <input
              class="option-input-sm"
              type="checkbox"
            [(ngModel)]="prop.security.requirePinToActivateOnlyOverInternet">
            <b>Require pin to activate only over internet</b>
        </div>
    </div>
    <div class="flex-row">
      <a
      (click)="showDevices = !showDevices"
      class="margin-left10 margin-top25 to-right"
      [ngClass]="{ rotate: !showDevices, 'rotate-back': showDevices }">
      <i class="i-arrow-up"></i>
      </a>
    <hr>
    </div>
</div>

<div *ngIf="type === 'listOfSafetyEquipment'" class="modal-content-v2">
    <div class="margin-top5 margin-bottom5"><strong>Name: </strong> <input [(ngModel)]="name" type="text"></div>
    <div class="flex-row margin-top5 margin-bottom5">
        <strong>List Of Location IDs: </strong>
        <div class="margin-left2" *ngFor="let id of content.listOfLocationIds; let i = index">
            {{id}}{{addComma(i, content.listOfLocationIds.length)}}</div>
    </div>
    <input
              class="option-input-sm"
              type="checkbox"
              [(ngModel)]="content.visibleToUser">
            <b>Visible to user</b>
            <br>
    <input
              class="option-input-sm"
              type="checkbox"
              [(ngModel)]="content.isCondition">
            <b>Is condition</b>
            <br>
            <input
              class="option-input-sm"
              type="checkbox"
            [(ngModel)]="content.isActuator">
            <b>Is actuator</b>
    <div class="margin-top10 margin-bottom10">
        <strong class="margin-top10 margin-bottom10">Equipment Properties:</strong>
        <hr>
    </div>
    <div style="margin-left: 60px; margin-bottom: 20px;" *ngFor="let prop of content.equipmentProperties">
        <b>Name</b>: <input type="text" [(ngModel)]="prop.name">
        <div style="margin-left: 60px; margin-top: 10px;" *ngIf="showThisProp(prop.id)">
            <b>ID</b>: {{prop.id}},
            <b>Available In User Scene</b>: {{prop.availableInUserScene}},
            <b>Security</b>: {{prop.security}},
            <b>Type</b>: {{prop.type}},
            <b>Value</b>: {{prop.value}},
            <b>InComm</b>: <u>Adress</u> - {{prop.inComm.address}}, <u>Communication type</u> -
            {{prop.inComm.communicationType}}
            <b>OutComm</b>: <u>Adress</u> - {{prop.outComm.address}}, <u>Communication type</u> -
            {{prop.outComm.communicationType}}
            <br>
            <input
              class="option-input-sm"
              type="checkbox"
              [(ngModel)]="prop.isConditionProperty">
            <b>Is condition property</b>
            <br>
            <input
              class="option-input-sm"
              type="checkbox"
            [(ngModel)]="prop.isActuatorProperty">
            <b>Is actuator property</b>
            <br>
            <input
              class="option-input-sm"
              type="checkbox"
            [(ngModel)]="prop.security.userCanActivate">
            <b>User can activate</b>
            <br>
            <input
              class="option-input-sm"
              type="checkbox"
            [(ngModel)]="prop.security.userCanActivateOverInternet">
            <b>User can activate over internet</b>
            <br>
            <input
              class="option-input-sm"
              type="checkbox"
            [(ngModel)]="prop.security.requirePinToActivate">
            <b>Require pin to activate</b>
            <br>
            <input
              class="option-input-sm"
              type="checkbox"
            [(ngModel)]="prop.security.requirePinToActivateOnlyOverInternet">
            <b>Require pin to activate only over internet</b>
        </div>
    </div>
</div>

<div *ngIf="type === 'listOfSecurityEquipment'" class="modal-content">
    <div><strong>Name: </strong> <input [(ngModel)]="name" type="text"></div>
    <div><strong>ID: </strong> {{content.id}}</div>
    <div class="flex-row">
        <strong>List Of Location IDs: </strong>
        <div class="margin-left2" *ngFor="let id of content.listOfLocationIds; let i = index">
            {{id}}{{addComma(i, content.listOfLocationIds.length)}}</div>
    </div>
   <!--  <div><strong>Visible to user: </strong> {{content.visibleToUser}}</div> -->
   <input
              class="option-input-sm"
              type="checkbox"
              [(ngModel)]="content.visibleToUser">
            <b>Visible to user</b>
            <br>
    <input
              class="option-input-sm"
              type="checkbox"
              [(ngModel)]="content.isCondition">
            <b>Is condition</b>
            <br>
            <input
              class="option-input-sm"
              type="checkbox"
            [(ngModel)]="content.isActuator">
            <b>Is actuator</b>
    <div class="margin-top10 margin-bottom10 center">
        <h3>Equipment Properties</h3>
        <hr>
    </div>
    <div *ngFor="let prop of content.equipmentProperties">
        <b>Name</b>: <input type="text" [(ngModel)]="prop.name"> <button class="show-more" (click)="addIdToShowArray(prop.id)">Show
            more</button>
        <div *ngIf="showThisProp(prop.id)">
            <b>ID</b>: {{prop.id}},
            <b>Available In User Scene</b>: {{prop.availableInUserScene}},
            <b>Security</b>: {{prop.security}},
            <b>Type</b>: {{prop.type}},
            <b>Value</b>: {{prop.value}},
            <b>InComm</b>: <u>Adress</u> - {{prop.inComm.address}}, <u>Communication type</u> -
            {{prop.inComm.communicationType}}
            <b>OutComm</b>: <u>Adress</u> - {{prop.outComm.address}}, <u>Communication type</u> -
            {{prop.outComm.communicationType}}
            <br>
            <input
              class="option-input-sm"
              type="checkbox"
              [(ngModel)]="prop.isConditionProperty">
            <b>Is condition property</b>
            <br>
            <input
              class="option-input-sm"
              type="checkbox"
            [(ngModel)]="prop.isActuatorProperty">
            <b>Is actuator property</b>
            <br>
            <input
              class="option-input-sm"
              type="checkbox"
            [(ngModel)]="prop.security.userCanActivate">
            <b>User can activate</b>
            <br>
            <input
              class="option-input-sm"
              type="checkbox"
            [(ngModel)]="prop.security.userCanActivateOverInternet">
            <b>User can activate over internet</b>
            <br>
            <input
              class="option-input-sm"
              type="checkbox"
            [(ngModel)]="prop.security.requirePinToActivate">
            <b>Require pin to activate</b>
            <br>
            <input
              class="option-input-sm"
              type="checkbox"
            [(ngModel)]="prop.security.requirePinToActivateOnlyOverInternet">
            <b>Require pin to activate only over internet</b>
        </div>
    </div>
</div>

<!-- GROUPS SECTION -->
<div *ngIf="index === undefined && type !== 'listOfFloors'"  style="margin: 10px; text-align: left; border-bottom: solid 1px;">
    <h3>Create new group</h3>
</div>

<div *ngIf="type === 'listOfBuildingGroups'" class="modal-content">
  <div><strong>Name: </strong>
    <input [(ngModel)]="groupName" type="text">
    <button class="show-more" [ngClass]="[!groupName? 'button-disabled no-click': '']" (click)="createNewGroup()">Add group</button></div>
  <div class="margin-top10 margin-bottom10">
    <h3>Groups</h3>
    <hr>
  </div>
  <div *ngFor="let group of project.listOfBuildingGroups">
    <b>Name</b>: <input type="text" [(ngModel)]="group.name">
    <b>  ID:</b> {{group.id}}
    <button class="show-more" (click)="addIdToShowArray(group.id);">Show more</button>
    <button class="delete" (click)="deleteGroup(group.id)">Delete group</button>
    <div class="flex-column" style="border-bottom: 1px solid; margin-bottom: 10px" *ngIf="showThisProp(group.id)">
    <div class="flex-row">
      <div style="margin: 20px">
        <b>Not in group ({{group.name}}): </b>
        <div style="margin-top: 10px">
          <div *ngIf="group.listOfBuildings.length === project.listOfBuildings.length">No elements to add.</div>
          <div *ngFor="let element of project.listOfBuildings" class="clickable" (click)="addToGroup(group, element.id, 'listOfBuildings')">
            <div style="border: 1px solid; padding: 2px; margin: 3px; font-weight: 600;" *ngIf="!group.listOfBuildings.includes(element.id)">{{element.name}} ({{element.id}}) <i class="i-arrow-right"></i> </div>
          </div>
        </div>
      </div>
      <div style="margin: 20px">
        <b>In group ({{group.name}}): </b>
        <div style="margin-top: 10px">
          <div *ngIf="group.listOfBuildings.length === 0" class="">No elements in group.</div>
            <div *ngFor="let id of group.listOfBuildings;let i = index" class="clickable" (click)="removeFromGroup(group, id, 'listOfBuildings')">
            <div style="border: 1px solid; padding: 2px; margin: 3px; font-weight: 600;">{{elementName(id, 'listOfBuildings')}} ({{id}}) <i class="i-delete-element"></i></div>
          </div>
        </div>
      </div>
      </div>
      <div class="flex-column-center" style="margin-bottom: 5px;">
        <button class="modal-button-box" (click)="updateGroup(group); addIdToShowArray(group.id);">Save group</button>
      </div>
    </div>
    </div>
  </div>

  <div *ngIf="type === 'listOfApartmentGroups'" class="modal-content">
    <div><strong>Name: </strong>
      <input [(ngModel)]="groupName" type="text">
      <button class="show-more" [ngClass]="[!groupName? 'button-disabled no-click': '']" (click)="createNewGroup()">Add group</button></div>
    <div class="margin-top10 margin-bottom10">
      <h3>Groups</h3>
      <hr>
    </div>
    <div *ngFor="let group of project.listOfApartmentGroups">
      <b>Name</b>: <input type="text" [(ngModel)]="group.name">
      <b>  ID:</b> {{group.id}}
      <button class="show-more" (click)="addIdToShowArray(group.id);">Show more</button>
      <button class="delete" (click)="deleteGroup(group.id)">Delete group</button>
      <div class="flex-column" style="border-bottom: 1px solid; margin-bottom: 10px" *ngIf="showThisProp(group.id)">
      <div class="flex-row">
        <div style="margin: 20px">
          <b>Not in group ({{group.name}}): </b>
          <div style="margin-top: 10px">
            <div *ngIf="group.listOfApartments.length === project.listOfApartments.length">No elements to add.</div>
            <div *ngFor="let element of project.listOfApartments" class="clickable" (click)="addToGroup(group, element.id, 'listOfApartments')">
              <div style="border: 1px solid; padding: 2px; margin: 3px; font-weight: 600;" *ngIf="!group.listOfApartments.includes(element.id)">{{element.name}} ({{element.id}}) <i class="i-arrow-right"></i> </div>
            </div>
          </div>
        </div>
        <div style="margin: 20px">
          <b>In group ({{group.name}}): </b>
          <div style="margin-top: 10px">
            <div *ngIf="group.listOfApartments.length === 0" class="">No elements in group.</div>
              <div *ngFor="let id of group.listOfApartments;let i = index" class="clickable" (click)="removeFromGroup(group, id, 'listOfApartments')">
              <div style="border: 1px solid; padding: 2px; margin: 3px; font-weight: 600;">{{elementName(id, 'listOfApartments')}} ({{id}}) <i class="i-delete-element"></i></div>
            </div>
          </div>
        </div>
        </div>
        <div class="flex-column-center" style="margin-bottom: 5px;">
          <button class="modal-button-box" (click)="updateGroup(group); addIdToShowArray(group.id);">Save group</button>
        </div>
      </div>
      </div>
    </div>

<div *ngIf="type === 'listOfRoomGroups'" class="modal-content">
  <div><strong>Name: </strong>
    <input [(ngModel)]="groupName" type="text">
    <button class="show-more" [ngClass]="[!groupName? 'button-disabled no-click': '']" (click)="createNewGroup()">Add group</button></div>
  <div class="margin-top10 margin-bottom10">
    <h3>Groups</h3>
    <hr>
  </div>
  <div *ngFor="let group of project.listOfRoomGroups">
    <b>Name</b>: <input type="text" [(ngModel)]="group.name">
    <b>  ID:</b> {{group.id}}
    <button class="show-more" (click)="addIdToShowArray(group.id);">Show more</button>
    <button class="delete" (click)="deleteGroup(group.id)">Delete group</button>
    <div class="flex-column" style="border-bottom: 1px solid; margin-bottom: 10px" *ngIf="showThisProp(group.id)">
    <div class="flex-row">
      <div style="margin: 20px">
        <b>Not in group ({{group.name}}): </b>
        <div style="margin-top: 10px">
          <div *ngIf="group.listOfRooms.length === project.listOfRooms.length">No elements to add.</div>
          <div *ngFor="let element of project.listOfRooms" class="clickable" (click)="addToGroup(group, element.id, 'listOfRooms')">
            <div style="border: 1px solid; padding: 2px; margin: 3px; font-weight: 600;" *ngIf="!group.listOfRooms.includes(element.id)">{{element.name}} ({{element.id}}) <i class="i-arrow-right"></i> </div>
          </div>
        </div>
      </div>
      <div style="margin: 20px">
        <b>In group ({{group.name}}): </b>
        <div style="margin-top: 10px">
          <div *ngIf="group.listOfRooms.length === 0" class="">No elements in group.</div>
            <div *ngFor="let id of group.listOfRooms;let i = index" class="clickable" (click)="removeFromGroup(group, id, 'listOfRooms')">
            <div style="border: 1px solid; padding: 2px; margin: 3px; font-weight: 600;">{{elementName(id, 'listOfRooms')}} ({{id}}) <i class="i-delete-element"></i></div>
          </div>
        </div>
      </div>
      </div>
      <div class="flex-column-center" style="margin-bottom: 5px;">
        <button class="modal-button-box" (click)="updateGroup(group); addIdToShowArray(group.id);">Save group</button>
      </div>
    </div>
    </div>
  </div>

  <div *ngIf="type === 'listOfBlindsGroups'" class="modal-content">
    <div><strong>Name: </strong>
      <input [(ngModel)]="groupName" type="text">
      <button class="show-more" [ngClass]="[!groupName? 'button-disabled no-click': '']" (click)="createNewGroup()">Add group</button></div>
    <div class="margin-top10 margin-bottom10">
      <h3>Groups</h3>
      <hr>
    </div>
    <div *ngFor="let group of project.listOfBlindsGroups">
      <b>Name</b>: <input type="text" [(ngModel)]="group.name">
      <b>  ID:</b> {{group.id}}
      <button class="show-more" (click)="addIdToShowArray(group.id);">Show more</button>
      <button class="delete" (click)="deleteGroup(group.id)">Delete group</button>
      <div class="flex-column" style="border-bottom: 1px solid; margin-bottom: 10px" *ngIf="showThisProp(group.id)">
      <div class="flex-row">
        <div style="margin: 20px">
          <b>Not in group ({{group.name}}): </b>
          <div style="margin-top: 10px">
            <div *ngIf="group.listOfBlinds.length === project.listOfBlinds.length">No elements to add.</div>
            <div *ngFor="let element of project.listOfBlinds" class="clickable" (click)="addToGroup(group, element.id, 'listOfBlinds')">
              <div style="border: 1px solid; padding: 2px; margin: 3px; font-weight: 600;" *ngIf="!group.listOfBlinds.includes(element.id)">{{element.name}} ({{element.id}}) <i class="i-arrow-right"></i> </div>
            </div>
          </div>
        </div>
        <div style="margin: 20px">
          <b>In group ({{group.name}}): </b>
          <div style="margin-top: 10px">
            <div *ngIf="group.listOfBlinds.length === 0" class="">No elements in group.</div>
              <div *ngFor="let id of group.listOfBlinds;let i = index" class="clickable" (click)="removeFromGroup(group, id, 'listOfBlinds')">
              <div style="border: 1px solid; padding: 2px; margin: 3px; font-weight: 600;">{{elementName(id, 'listOfBlinds')}} ({{id}}) <i class="i-delete-element"></i></div>
            </div>
          </div>
        </div>
        </div>
        <div class="flex-column-center" style="margin-bottom: 5px;">
          <button class="modal-button-box" (click)="updateGroup(group); addIdToShowArray(group.id);">Save group</button>
        </div>
      </div>
      </div>
    </div>

<div *ngIf="type === 'listOfGeneralEquipmentGroup'" class="modal-content">
  <div><strong>Name: </strong>
    <input [(ngModel)]="groupName" type="text">
    <button class="show-more" [ngClass]="[!groupName? 'button-disabled no-click': '']" (click)="createNewGroup()">Add group</button></div>
  <div class="margin-top10 margin-bottom10">
    <h3>Groups</h3>
    <hr>
  </div>
  <div *ngFor="let group of project.listOfGeneralEquipmentGroup">
    <b>Name</b>: <input type="text" [(ngModel)]="group.name">
    <b>  ID:</b> {{group.id}}
    <button class="show-more" (click)="addIdToShowArray(group.id);">Show more</button>
    <button class="delete" (click)="deleteGroup(group.id)">Delete group</button>
    <div class="flex-column" style="border-bottom: 1px solid; margin-bottom: 10px" *ngIf="showThisProp(group.id)">
    <div class="flex-row">
      <div style="margin: 20px">
        <b>Not in group ({{group.name}}): </b>
        <div style="margin-top: 10px">
          <div *ngIf="group.listOfGeneralEquipment.length === project.listOfGeneralEquipment.length">No elements to add.</div>
          <div *ngFor="let element of project.listOfGeneralEquipment" class="clickable" (click)="addToGroup(group, element.id, 'listOfGeneralEquipment')">
            <div style="border: 1px solid; padding: 2px; margin: 3px; font-weight: 600;" *ngIf="!group.listOfGeneralEquipment.includes(element.id)">{{element.name}} ({{element.id}}) <i class="i-arrow-right"></i> </div>
          </div>
        </div>
      </div>
      <div style="margin: 20px">
        <b>In group ({{group.name}}): </b>
        <div style="margin-top: 10px">
          <div *ngIf="group.listOfGeneralEquipment.length === 0" class="">No elements in group.</div>
            <div *ngFor="let id of group.listOfGeneralEquipment;let i = index" class="clickable" (click)="removeFromGroup(group, id, 'listOfGeneralEquipment')">
            <div style="border: 1px solid; padding: 2px; margin: 3px; font-weight: 600;">{{elementName(id, 'listOfGeneralEquipment')}} ({{id}}) <i class="i-delete-element"></i></div>
          </div>
        </div>
      </div>
      </div>
      <div class="flex-column-center" style="margin-bottom: 5px;">
        <button class="modal-button-box" (click)="updateGroup(group); addIdToShowArray(group.id);">Save group</button>
      </div>
    </div>
    </div>
  </div>

<div *ngIf="type === 'listOfHvacGroups'" class="modal-content">
  <div><strong>Name: </strong>
    <input [(ngModel)]="groupName" type="text">
    <button class="show-more" [ngClass]="[!groupName? 'button-disabled no-click': '']" (click)="createNewGroup()">Add group</button></div>
  <div class="margin-top10 margin-bottom10">
    <h3>Groups</h3>
    <hr>
  </div>
  <div *ngFor="let group of project.listOfHvacGroups">
    <b>Name</b>: <input type="text" [(ngModel)]="group.name">
    <b>  ID:</b> {{group.id}}
    <button class="show-more" (click)="addIdToShowArray(group.id);">Show more</button>
    <button class="delete" (click)="deleteGroup(group.id)">Delete group</button>
    <div class="flex-column" style="border-bottom: 1px solid; margin-bottom: 10px" *ngIf="showThisProp(group.id)">
    <div class="flex-row">
      <div style="margin: 20px">
        <b>Not in group ({{group.name}}): </b>
        <div style="margin-top: 10px">
          <div *ngIf="group.listOfHvac.length === project.listOfHvacs.length">No elements to add.</div>
          <div *ngFor="let element of project.listOfHvacs" class="clickable" (click)="addToGroup(group, element.id, 'listOfHvac')">
            <div style="border: 1px solid; padding: 2px; margin: 3px; font-weight: 600;" *ngIf="!group.listOfHvac.includes(element.id)">{{element.name}} ({{element.id}}) <i class="i-arrow-right"></i> </div>
          </div>
        </div>
      </div>
      <div style="margin: 20px">
        <b>In group ({{group.name}}): </b>
        <div style="margin-top: 10px">
          <div *ngIf="group.listOfHvac.length === 0" class="">No elements in group.</div>
            <div *ngFor="let id of group.listOfHvac;let i = index" class="clickable" (click)="removeFromGroup(group, id, 'listOfHvac')">
            <div style="border: 1px solid; padding: 2px; margin: 3px; font-weight: 600;">{{elementName(id, 'listOfHvacs')}} ({{id}}) <i class="i-delete-element"></i></div>
          </div>
        </div>
      </div>
      </div>
      <div class="flex-column-center" style="margin-bottom: 5px;">
        <button class="modal-button-box" (click)="updateGroup(group); addIdToShowArray(group.id);">Save group</button>
      </div>
    </div>
    </div>
  </div>

<div *ngIf="type === 'listOfLightGroups'" class="modal-content">
  <div><strong>Name: </strong>
    <input [(ngModel)]="groupName" type="text">
    <button class="show-more" [ngClass]="[!groupName? 'button-disabled no-click': '']" (click)="createNewGroup()">Add group</button></div>
  <div class="margin-top10 margin-bottom10">
    <h3>Groups</h3>
    <hr>
  </div>
  <div *ngFor="let group of project.listOfLightGroups">
    <b>Name</b>: <input type="text" [(ngModel)]="group.name">
    <b>  ID:</b> {{group.id}}
    <button class="show-more" (click)="addIdToShowArray(group.id);">Show more</button>
    <button class="delete" (click)="deleteGroup(group.id)">Delete group</button>
    <div class="flex-column" style="border-bottom: 1px solid; margin-bottom: 10px" *ngIf="showThisProp(group.id)">
    <div class="flex-row">
      <div style="margin: 20px">
        <b>Not in group ({{group.name}}): </b>
        <div style="margin-top: 10px">
          <div *ngIf="group.listOfLight.length === project.listOfLights.length">No elements to add.</div>
          <div *ngFor="let element of project.listOfLights" class="clickable" (click)="addToGroup(group, element.id, 'listOfLight')">
            <div style="border: 1px solid; padding: 2px; margin: 3px; font-weight: 600;" *ngIf="!group.listOfLight.includes(element.id)">{{element.name}} ({{element.id}}) <i class="i-arrow-right"></i> </div>
          </div>
        </div>
      </div>
      <div style="margin: 20px">
        <b>In group ({{group.name}}): </b>
        <div style="margin-top: 10px">
          <div *ngIf="group.listOfLight.length === 0" class="">No elements in group.</div>
            <div *ngFor="let id of group.listOfLight;let i = index" class="clickable" (click)="removeFromGroup(group, id, 'listOfLight')">
            <div style="border: 1px solid; padding: 2px; margin: 3px; font-weight: 600;">{{elementName(id, 'listOfLights')}} ({{id}}) <i class="i-delete-element"></i></div>
          </div>
        </div>
      </div>
      </div>
      <div class="flex-column-center" style="margin-bottom: 5px;">
        <button class="modal-button-box" (click)="updateGroup(group); addIdToShowArray(group.id);">Save group</button>
      </div>
    </div>
    </div>
  </div>

<!-- FLOORS SETTINGS -->
<div *ngIf="index === undefined && type === 'listOfFloors'" style="margin: 10px; text-align: left; border-bottom: solid 1px;">
    <h3>Create new floor</h3>
</div>
<div *ngIf="type === 'listOfFloors'" class="modal-content">
  <div class="flex-row">
    <div class="flex-column">
        <div class="flex-row">
            <strong>Name: </strong> <input [(ngModel)]="floorName" type="text" class="margin-left5">
        </div>
        <div class="flex-row margin-top5">
            <strong>Apartment: </strong>
            <select class="select margin-left5" [(ngModel)]="floorForApartment">
                <option [ngValue]="apartment.id" *ngFor="let apartment of project.listOfApartments">{{apartment.name}}</option>
              </select>
        </div>
    </div>
    <button class="show-more" style="margin-bottom: 20;" [ngClass]="[!floorName? 'button-disabled no-click': '']" (click)="createNewFloor()">Add Floor</button>
  </div>
    <div class="margin-top10 margin-bottom10">
        <h3>Floors</h3>
        <hr>
    </div>
    <div *ngFor="let floor of project?.listOfFloors">
        <b>Name</b>: <input type="text" [(ngModel)]="floor.name">
        <button class="show-more" (click)="addIdToShowArray(floor.id)">Show more</button>
        <button class="delete" (click)="deleteFloor(floor.id)">Delete floor</button>
        <div class="flex-column" style="border-bottom: 1px solid; margin-bottom: 10px" *ngIf="showThisProp(floor.id)">
          <div style="margin-top: 10px;">
            <b>In apartment: </b> {{ floor.apartmentId }}
        </div>
          <div class="flex-row">
            <div style="margin: 20px">
              <b>Not on floor ({{floor.name}})</b>
              <div style="margin-top: 10px">
                <div *ngIf="floor.listOfRooms.length === getRoomsForFloor(floor).length">No rooms to add.</div>
                <div *ngFor="let room of getRoomsForFloor(floor)" class="clickable" (click)="addTofloor(floor, room.id)">
                  <div style="border: 1px solid; padding: 2px; margin: 3px; font-weight: 600;" *ngIf="!floor.listOfRooms.includes(room.id)">{{room.name}} ({{room.id}}) <i class="i-arrow-right"></i> </div>
                </div>
              </div>
            </div>
            <div style="margin: 20px">
              <b>On floor ({{floor.name}})</b>
              <div style="margin-top: 10px">
                <div *ngIf="floor.listOfRooms.length === 0" class="">No rooms on floor.</div>
                  <div *ngFor="let id of floor.listOfRooms;let i = index" class="clickable" (click)="removeFromFloor(floor, id)">
                  <div style="border: 1px solid; padding: 2px; margin: 3px; font-weight: 600;">{{elementName(id, 'listOfRooms')}} ({{id}}) <i class="i-delete-element"></i></div>
                </div>
              </div>
            </div>
            </div>
            <div class="flex-column-center" style="margin-bottom: 5px;">
              <button class="modal-button-box" (click)="updateFloor(floor); addIdToShowArray(floor.id);">Save floor</button>
            </div>
          </div>
        <!-- <div *ngIf="showThisProp(floor.id)">
            <div>
                <b>In apartment: </b> {{ floor.apartmentId }}
            </div>
            <div>
                <b>Rooms on floor: </b>
                <div *ngIf="floor.listOfRooms.length === 0" class="contents">No rooms on floor.</div>
                <div *ngFor="let id of floor.listOfRooms;let i = index" class="contents clickable element-remove" (click)="removeFromFloor(floor, id)">
                    {{elementName(id, 'listOfRooms')}} {{addComma(i, floor.listOfRooms.length)}}
                </div>
            </div>
            <div>
                <b>Add room to floor: </b>
                <div *ngIf="floor.listOfRooms.length === getRoomsForFloor(floor).length" class="contents">No rooms to add.</div>
                <div *ngFor="let room of getRoomsForFloor(floor)" class="contents clickable element-add" (click)="addTofloor(floor, room.id)">
                    <div *ngIf="!floor.listOfRooms.includes(room.id)" class="contents">{{room.name}} {{addComma2(getRoomsForFloor(floor).length, floor.listOfRooms.length )}}</div>
                </div>
            </div>
            <button class="show-more" [ngClass]="[!floor.name? 'button-disabled no-click': '']" (click)="updateFloor(floor)">Save</button>
        </div> -->
    </div>
</div>
<div *ngIf="index === undefined" class="modal-button-box">
    <button (click)="decisionClickForModal(false)">Close edit</button>
</div>
