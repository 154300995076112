import { ChangeDetectorRef, Component, ComponentFactoryResolver, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild, ViewContainerRef } from '@angular/core';
import { ToastController } from '@ionic/angular';
import { Subscription } from 'rxjs';
import { Condition } from 'src/app/core/models/automation/condition.model';
import { EquipmentPropertyComponentToLoad } from 'src/app/core/models/equipment-property-component-to-load.model';
import { Device } from 'src/app/core/models/project/devices/device.model';
import { Light } from 'src/app/core/models/project/devices/light.model';
import { Floor } from 'src/app/core/models/project/floor.model';
import { Property } from 'src/app/core/models/project/property.model';
import { Room } from 'src/app/core/models/project/room.model';
import { User } from 'src/app/core/models/user/user.model';
import { UserSettingsService } from 'src/app/core/services/user-settings.service';
import { UserService } from 'src/app/core/services/user.service';
import { DaliProjectService } from 'src/app/modules/project/services/dali-project.service';
import { ProjectService } from 'src/app/modules/project/services/project.service';
import { GenComTranslatePipe } from '../../pipes/gen-com-translate.pipe';
import { EquipmentComponentGenService } from '../../services/equipment-component-gen.service';
import { EquipmentPropertyTypesService } from '../../services/equipment-property-types.service';
import { EquipmentBinaryMutexComponent } from '../equipment/equipment-binary-mutex/equipment-binary-mutex.component';

@Component({
  selector: 'app-setup-device-condition',
  templateUrl: './setup-device-condition.component.html',
  styleUrls: ['./setup-device-condition.component.scss']
})
export class SetupDeviceConditionComponent implements OnInit, OnDestroy {
  @ViewChild('equipmentPropertyContainer', { read: ViewContainerRef }) equipmentPropertyContainer;
  @Input() deviceInSetup: any;
  @Input() deviceInSetupCondition: Condition;
  @Output() deviceCondition$: EventEmitter<Condition> = new EventEmitter();
  @Output() propName$?: EventEmitter<String> = new EventEmitter();


  componentToLoadSubscriptions: Subscription = new Subscription();

  step: 'select property' | 'select condition' = 'select property';
  equipmentProperties: Property[];

  condition: Condition;
  deviceIsDali: boolean = false;

  constructor(
    private equipmentPropertyTypesService: EquipmentPropertyTypesService,
    private equipmentComponentGen: EquipmentComponentGenService,
    private componentFactoryResolver: ComponentFactoryResolver,
    private changeDetectorRef: ChangeDetectorRef,
    private userService: UserService,
    private projectService: ProjectService,
    private daliProjectService: DaliProjectService,
    private pipe: GenComTranslatePipe,
    private toastController: ToastController) { }


  ngOnInit(): void {
    this.isDeviceDali(this.deviceInSetup);
    if (this.deviceIsDali) {
      this.step = 'select condition';
      this.propName$.emit(this.deviceInSetup.name)
    } else {
      if (this.deviceInSetupCondition) {
        this.condition = JSON.parse(JSON.stringify(this.deviceInSetupCondition));
        this.step = 'select condition';
        const propToLoad = this.getPropertyForCondition(this.deviceInSetup, this.condition);
        this.selectEquipProp(propToLoad);
      } else {
        this.equipmentProperties = this.initEquipmentPropertyArray(this.deviceInSetup.equipmentProperties);
        if (this.equipmentProperties.length === 1) {
          this.selectEquipProp(this.equipmentProperties[0]);
        } else {
          this.propName$.emit(null)
        }
      }
    }
  }

  isDeviceDali(device: any) {
    if (this.daliProjectService.getDaliLight(device.id)) {
      this.deviceIsDali = true;
    } else if (this.daliProjectService.getDaliRgbLight(device.id)) {
      this.deviceIsDali = true;
    } else if (this.daliProjectService.getDaliLightsGroup(device.id)) {
      this.deviceIsDali = true;
    }
  }

  daliLightIsNormal(daliLight) {
    return Light.isDimmableDaliLight(daliLight) || Light.isNormalDaliLight(daliLight);
  }

  daliLightIsRgb(daliLight) {
    return (daliLight?.id?.split('-')[0] === 'DRGB');
  }

  idDaliGroup(daliLight) {
    return (daliLight?.id?.split('-')[0] === 'DG');
  }


  initEquipmentPropertyArray(deviceEquipProperties: Property[]) {
    let noHvacMutex = true;
    let noBlindsMutex = true;
    const user = this.userService.getUser();
    const equipmentProperties = deviceEquipProperties.filter((prop: Property) => {
      if (this.equipmentPropertyTypesService.getEquipmentPropertyType(prop.type) || this.equipmentPropertyTypesService.getEquipmentPropertyTypeForStatus(prop.type) ) {
      if (Property.isHvacMutex(prop)) {
        if (noHvacMutex) {
          noHvacMutex = false;
          if (User.isAdmin(user)) {
            return true;
          } else { return prop.isConditionProperty; }
        } else {
          return false;
        }
      }
      else if (Property.isBlindsMutex(prop)) {
        if (noBlindsMutex) {
          noBlindsMutex = false;
          if (User.isAdmin(user)) {
            return true;
          } else {
            return prop.isConditionProperty; }
        } else {
          return false;
        }
      }
      else {
        if (User.isAdmin(user)) {
          return true;
        } else {return prop.isConditionProperty; }}
      }
    });
    return equipmentProperties;
  }

  getEquipmentPropertyName(equipmentProperty: Property): string {
    if (Property.isHvacMutex(equipmentProperty)) {
      return 'Hvac control';
    } else if (Property.isBlindsMutex(equipmentProperty)) {
      return 'Blinds';
    } else {
      return equipmentProperty.name;
    }
  }


  getPropertyForCondition(device: Device, condition: Condition): Property {
    const propertyType = condition.parameter1.split(':')[1].split('/')[1];
    const equipmentProperty: Property = device.equipmentProperties.find((prop: Property) => {
      return prop.type === +propertyType;
    });
    return equipmentProperty;
  }

  selectEquipProp(equipmentProperty: Property) {
    this.propName$.emit(this.getEquipmentPropertyName(equipmentProperty))
    this.step = 'select condition';
    this.changeDetectorRef.detectChanges(); // trigers change detection to render #equipmentPropertyContainer
    this.loadEquipmentPropertyComponent(equipmentProperty);
  }

  loadEquipmentPropertyComponent(equipProp: Property) {
    if (Property.isHvacMutex(equipProp) || Property.isBlindsMutex(equipProp)) {
      const viewContainerRef: ViewContainerRef = this.equipmentPropertyContainer;
      viewContainerRef.clear();
      const mutexComponentFactory = this.componentFactoryResolver.resolveComponentFactory(EquipmentBinaryMutexComponent);
      const mutexComponentRef = viewContainerRef.createComponent(mutexComponentFactory);

      const generatedComponentInstance = (mutexComponentRef.instance as EquipmentBinaryMutexComponent);

      if (Property.isHvacMutex(equipProp)) {
        const hvacControlPropertyArray = this.deviceInSetup.equipmentProperties.filter((prop) => {
          return Property.isHvacMutex(prop);
        });

        generatedComponentInstance.propertyArray = hvacControlPropertyArray;
        generatedComponentInstance.title = 'HVAC control';
      } else if (Property.isBlindsMutex(equipProp)) {
        const blindsPropertyArray = this.deviceInSetup.equipmentProperties.filter((prop) => {
          return Property.isBlindsMutex(prop);
        });
        generatedComponentInstance.propertyArray = blindsPropertyArray;
        generatedComponentInstance.title = 'Blinds';
      }
      generatedComponentInstance.parentDevice = this.deviceInSetup;
      generatedComponentInstance.inputCondition = this.condition;
      generatedComponentInstance.mode = 'condition';
      this.componentToLoadSubscriptions.add((mutexComponentRef.instance as EquipmentBinaryMutexComponent).conditionChanged$
        .subscribe((changedCondition: Condition) => {
          this.condition = changedCondition;
        }));

    } else {
      let componentToLoad: EquipmentPropertyComponentToLoad;
      if (this.equipmentPropertyTypesService.getEquipmentPropertyType(equipProp.type)) {
        componentToLoad = this.equipmentComponentGen.getComponent(equipProp, this.deviceInSetup);
      } else if (this.equipmentPropertyTypesService.getEquipmentPropertyTypeForStatus(equipProp.type)) {
        componentToLoad = this.equipmentComponentGen.getComponentForStatus(equipProp, this.deviceInSetup);
      }
      const viewContainerRef: ViewContainerRef = this.equipmentPropertyContainer;
      viewContainerRef.clear();
      const { component, parentDevice, equipmentProperty, equipmentPropertyType, data } = componentToLoad;
      const componentFactory = this.componentFactoryResolver.resolveComponentFactory(component);
      const componentRef = viewContainerRef.createComponent(componentFactory);
      const generatedComponentInstance = (componentRef.instance as EquipmentPropertyComponentToLoad);
      generatedComponentInstance.parentDevice = parentDevice;
      generatedComponentInstance.equipmentProperty = equipmentProperty;
      generatedComponentInstance.equipmentPropertyType = equipmentPropertyType;
      generatedComponentInstance.data = data;
      generatedComponentInstance.mode = 'condition';
      generatedComponentInstance.conditionInput = this.condition;

      this.componentToLoadSubscriptions.add(generatedComponentInstance.conditionChanged$
        .subscribe((changedCondition: Condition) => {
          this.condition = changedCondition;
        }));
    }

  }

  getRoom(device: Device): Room {
    const deviceLocation: string = device.listOfLocationIds[0];
    const splitRoomId = deviceLocation.split('.');
    const roomId = `${splitRoomId[0]}.${splitRoomId[1]}.${splitRoomId[2]}`;
    const room: Room = this.projectService.getRoom(roomId);
    return room;
  }


  getFloor(device: Device): Floor {
    const room: Room = this.getRoom(device);
    const floor: Floor = this.projectService.getFloor(room.floorId);
    return floor;
   }

   conditionChanged(condition: Condition) {
    this.condition = condition;
  }

  onCancel() {
    this.deviceCondition$.emit(null);
  }

  async onApply() {
    if (this.condition && ((this.condition.type >= 0 && this.condition.type <= 4 && this.condition.parameter2 != null) ||
      (this.condition.type >= 5 && this.condition.type <= 8 && this.condition.parameter2 != null && this.condition.parameter3 != null) ||
      (this.condition.type === 9 && this.condition.parameter2 != null))) {
      this.deviceCondition$.emit(this.condition);
      const toast = await this.toastController.create({
        message: this.pipe.transform('Device ') + this.deviceInSetup.name + this.pipe.transform(' added'),
        color: 'primary',
        duration: 2000,
      });
      toast.present();
    }
  }

  ngOnDestroy() {
    if (this.componentToLoadSubscriptions) {
      this.componentToLoadSubscriptions.unsubscribe();
    }
  }



}
